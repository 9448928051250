<template>
  <div v-if="splitStyle === 11" class="rn-splite-style my-lg-5">
    <div class="container" style="margin-top: 5%;">
      <div class="row toppositionclone" style="padding-top: 0%;"
        :class="{ 'bg-descripstion-dark': !userLogin, '': userLogin }">
        <div class="centered-text text-left p-5">
          <h5>Our services</h5>
          <h4 class="title mb-0" style="font-size: 40px">{{ splitData.title }}</h4>
          <h4 class="title" style="font-size: 40px">{{ splitData.title2 }}</h4>
          <div class="hrclone"></div>
          <p class="description LexenExtraLight" style="font-size: 20px">
            {{ splitData.description }}
          </p>
        </div>
        <div class="thumbnail image-left-content">
          <img v-if="receivedData === false" :src="require(`@/assets/images/Newlandindpage/${splitData.image}`)"
            alt="Split Image" />
          <img v-if="receivedData === true" :src="require(`@/assets/images/Newlandindpage/lightmode/${splitData.image1}`)"
            alt="Split Image" />
        </div>
      </div>

      <!-- <div
        class="text-center"
        :class="{ ' d-none': userLogin === false, 'd-block': userLogin === true }"
      >
        <img class="w-75" src="../../../assets/images/Newlandindpage/path/line_path.png" />
      </div> -->
    </div>
  </div>
</template>

<script>
import Button from '../button/Button'
import Counter from '../counterUp/Counter'

export default {
  name: 'Split',
  components: { Counter, Button },
  props: {
    splitStyle: {
      type: Number,
      default: 1
    },
    splitData: {},
    receivedData: {
      type: Boolean
    }
  },
  computed: {
    userLogin() {
      return this.$store.state.userLogin
    }
  }
}
</script>
<style>
.background-split {
  background-image: url(../../../assets/images/Newlandindpage/lightmode/bg-split.png);
  background-size: cover;
  background-position: -0% 0%;
  /* background-repeat: no-repeat; */
  background-position: center;
  z-index: 2;
  width: 100%;
  height: 100%;
}

.hrclone {
  top: 8648px;
  left: 664px;
  height: 0px;
  /* UI Properties */
  border: 1px solid var(--unnamed-color-707070);
  border: 1px solid #3a3a3a;
  opacity: 0.51;
  margin-top: 4%;
  margin-bottom: 4%;
}

.toppositionclone {
  padding-top: 10%;
}
</style>
