<template>
  <div class="about-area about-style-4 rn-section-gap">
    <div class="container">
      <div class="row row--40 align-items-center">
        <div class="col-lg-5">
          <Video :video-data="videoData" column="col-12" />
        </div>
        <div class="col-lg-1"></div>
        <div class="col-lg-6 mt_md--40 mt_sm--40">
          <div class="content">
            <div class="inner">
              <h3 class="title">
                Lorem ipsum Lorem ipsumLorem ipsum
                <strong>ipsum Lorem ipsum</strong>
              </h3>
              <ul class="feature-list">
                <li>
                  <!-- <Icon
                                        name="check"
                                        size="20"
                                        icon-class="icon"
                                    /> -->
                  <img class="protact-logo" src="../../../assets//images/Newlandindpage/Group_27922.svg" alt="" />
                  <div class="title-wrapper">
                    <h4 class="title">Lorem ipsum Lorem ipsum</h4>
                    <p class="text">
                      Lorem ipsum dolor consectetur adipiscing do eiusmod tempor incididunt labore.
                    </p>
                  </div>
                </li>
                <li>
                  <!-- <Icon
                                        name="check"
                                        size="20"
                                        icon-class="icon"
                                    /> -->
                  <img class="protact-logo" src="../../../assets//images/Newlandindpage/Group_27922.svg" alt="" />
                  <div class="title-wrapper">
                    <h4 class="title">Lorem ipsum Lorem ipsum</h4>
                    <p class="text">
                      Lorem ipsum dolor consectetur adipiscing do eiusmod tempor incididunt labore.
                    </p>
                  </div>
                </li>
              </ul>
              <div class="about-btn mt--30">
                <router-link class="btn-default mx-5" to="#">Free trial</router-link>
                <!-- <router-link
                                    class="btn-default btn-border btn-icon"
                                    to="/contact"
                                >
                                    Check in
                                    <Icon
                                        name="arrow-right"
                                        size="20"
                                        icon-class="icon"
                                    />
                                </router-link> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '../../icon/Icon'
import Video from '../video/Video'

export default {
  name: 'AboutFour',
  components: { Video, Icon },
  props: {
    image: {}
  },
  data() {
    return {
      videoData: [
        {
          thumb: this.image,
          src: 'https://www.youtube.com/watch?v=d0tU18Ybcvk'
        }
      ]
    }
  }
}
</script>
<style>
.protact-logo {
  margin-top: -14%;
}

.background-sec-9-lightmode {
  background-image: url(../../../assets//images/Newlandindpage/lightmode/sec-9.png);
  background-size: cover;
  background-position: -0% 0%;
  /* background-repeat: no-repeat; */
  background-position: center;
  z-index: 2;
  width: 100%;
  height: 100%;
}
</style>
