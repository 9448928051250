<template>
  <div v-if="splitStyle === 1" class="rn-splite-style">
    <!-- <img src="../../../assets/images/Newlandindpage/lightmode/bg-split.png" /> -->
    <!-- <div class="split-wrapper">
      <div class="split-inner">
        <h4 class="title mb-0" style="color: #4a9cf8">{{ splitData.title }}</h4>
        <h4 class="title">{{ splitData.title2 }}</h4>
      </div>
      <div class="row no-gutters radius-10 align-items-center">
        <div class="col-lg-12 col-xl-6 col-6">
          <div class="thumbnail image-left-content">
            <img
              v-if="receivedData === false"
              :src="require(`@/assets/images/Newlandindpage/${splitData.image}`)"
              alt="Split Image"
            />
            <img
              v-if="receivedData === true"
              :src="require(`@/assets/images/Newlandindpage/lightmode/${splitData.image1}`)"
              alt="Split Image"
            />
          </div>
        </div>
        <div class="col-lg-12 col-xl-6 col-6">
          <div class="split-inner">
            <p class="description LexenExtraLight">
              {{ splitData.description }}
            </p>

            <div
              class="text-center"
              :class="{ ' d-none': userLogin === false, 'd-block': userLogin === true }"
            >
              <img class="w-75" src="../../../assets/images/Newlandindpage/path/line_path.png" />
            </div>

            <ul class="split-list">
              <li v-for="listItem in splitData.list">
                {{ listItem }}
              </li>
            </ul>
          </div>
        </div>
        <img
          v-if="!userLogin"
          class="w-75 ml--175"
          src="../../../assets/images/Newlandindpage/path/path-header-line.png"
        />
      </div>
    </div> -->
    <div class="container">
      <div class="row">
        <div class="centered-text">
          <h5 v-if="number === 4" style="font-size: 18px">Our services</h5>
          <h4 class="title mb-0" style="color: #86868b; font-size: 40px">{{ splitData.title }}</h4>
          <h4 class="title" style="font-size: 40px">{{ splitData.title2 }}</h4>
        </div>
        <div class="thumbnail image-left-content">
          <img
            v-if="receivedData === false"
            :src="require(`@/assets/images/Newlandindpage/${splitData.image}`)"
            alt="Split Image"
          />
          <img
            v-if="receivedData === true"
            :src="require(`@/assets/images/Newlandindpage/lightmode/${splitData.image1}`)"
            alt="Split Image"
          />
        </div>
        <div class="d-flex text-left">
          <p
            class="description LexenExtraLight p-5"
            style="font-size: 20px"
            :class="{ 'bg-descripstion-dark': !userLogin, 'bg-descripstion-light': userLogin }"
          >
            {{ splitData.description }}
          </p>
        </div>
      </div>

      <!-- <div
        class="text-center"
        :class="{ ' d-none': userLogin === false, 'd-block': userLogin === true }"
      >
        <img class="w-75" src="../../../assets/images/Newlandindpage/path/line_path.png" />
      </div> -->
    </div>
  </div>

  <div
    v-else-if="splitStyle === 2 || splitStyle === 3 || splitStyle === 4"
    :class="[
      `rn-splite-style`,
      { 'background-covering-light': userLogin && (splitStyle === 3 || splitStyle === 4) },
      { 'bg-color-blackest': !userLogin && (splitStyle === 3 || splitStyle === 4) }
    ]"
  >
    <div class="split-wrapper">
      <div class="row no-gutters radius-10 align-items-center">
        <div :class="[`col-lg-12 col-xl-6 col-12`, { 'order-2': splitStyle === 4 }]">
          <div class="thumbnail">
            <img
              v-if="!userLogin"
              :src="require(`@/assets/images/Newlandindpage/${splitData.image}`)"
              alt="Split Image"
            />
            <img
              v-if="userLogin"
              :src="require(`@/assets/images/Newlandindpage/lightmode/${splitData.image1}`)"
              alt="Split Image"
            />
          </div>
        </div>
        <div :class="[`col-lg-12 col-xl-6 col-12`, { 'order-1': splitStyle === 4 }]">
          <div class="split-inner">
            <div class="">
              <h4 class="title d-inline">{{ splitData.title }}</h4>
              <h4 class="title d-inline" style="color: #4a9cf8">{{ splitData.title2 }}</h4>
            </div>
            <p class="description LexenExtraLight">{{ splitData.description }}</p>
            <!-- {{ userLogin }} -->
            <!-- <img v-if="!userLogin" :src="require(`../../../assets/images/Newlandindpage/path/line_path.png`)"
              alt="Split Image" />
            <img v-if="userLogin" :src="require(`../../../assets/images/Newlandindpage/path/line_path.png`)"
              alt="Split Image" /> -->
            <!-- <div class="text-center">
              <img class="w-75" v-if="userLogin === true"
                src="../../../assets/images/Newlandindpage/path/line_path.png" />
            </div> -->

            <!-- <div
              class="text-center"
              :class="{ ' d-none': userLogin === false, 'd-block': userLogin === true }"
            >
              <img class="w-75" src="../../../assets/images/Newlandindpage/path/line_path.png" />
            </div> -->
            <Counter
              :counter-style="5"
              text-align="left"
              :counter-data="splitData.counterData1"
              column="col-lg-6 col-md-6 col-sm-6 col-12"
            />
            <Counter
              :counter-style="5"
              class="text-danger"
              text-align="left"
              :counter-data="splitData.counterData2"
              column="col-lg-6 col-md-6 col-sm-6 col-12"
            />
          </div>
        </div>
      </div>
      <img
        class="w-75 mt-5 ml--175"
        :class="userLogin ? '' : ''"
        src="../../../assets/images/Newlandindpage/path/path-header-line.png"
        alt=""
      />
    </div>
  </div>
</template>

<script>
import Button from '../button/Button'
import Counter from '../counterUp/Counter'

export default {
  name: 'Split',
  components: { Counter, Button },
  props: {
    splitStyle: {
      type: Number,
      default: 1
    },
    splitData: {},
    receivedData: {
      type: Boolean
    },
    number: {
      type: [String, Number],
      default: null
    }
  },
  computed: {
    userLogin() {
      return this.$store.state.userLogin
    }
  }
}
</script>
<style>
.background-split {
  background-image: url(../../../assets/images/Newlandindpage/lightmode/bg-split.png);
  background-size: cover;
  background-position: -0% 0%;
  /* background-repeat: no-repeat; */
  background-position: center;
  z-index: 2;
  width: 100%;
  height: 100%;
}

.background-covering-light {
  background-image: url(../../../assets/images/Newlandindpage/lightmode/Group_6.png);
  background-size: cover;
  background-position: -0% 0%;
  /* background-repeat: no-repeat; */
  background-position: center;
  z-index: 2;
  width: 100%;
  height: 100%;
}

.bg-descripstion-light {
  background-color: #ffffff;
}

.bg-descripstion-dark {
  background-color: #0f0f0f;
}
</style>
