<template>
  <div class="" :class="{ 'header-transparent-with-topbar': data.headerTransparency }">
    <header class="" :class="[`rn-header header-default header-not-transparent header-sticky ${data.headerClass}`]">
      <div class="container position-relative">
        <div class="row align-items-center row--0">
          <template v-if="data.navAlignment === 'right'">
            <div class="col-lg-3 col-md-6 col-4">
              <Logo />
            </div>

            <div class="col-lg-9 col-md-6 col-8 position-static">
              <div class="header-right">
                <nav class="mainmenu-nav d-none d-lg-block">
                  <Nav />
                </nav>
                <a :class="data.buyButtonClass" href="/PlanandPricing"> PACKAGE </a>

                <div class="mobile-menu-bar ml--5 d-block d-lg-none">
                  <div class="hamberger">
                    <button class="hamberger-button" @click.prevent="handleClick">
                      <Icon name="menu" size="21" />
                    </button>
                  </div>
                </div>

                <div v-if="data.showThemeSwitcherButton" id="my_switcher" class="my_switcher">
                  <ul>
                    <li>
                      <a href="javascript: void(0);" @click="handleClick">
                        <img class="light-icon" src="../../../assets/images/icons/sun-01.svg" alt="Sun images" />
                        <img class="dark-icon" src="../../../assets/images/icons/vector.svg" alt="Moon Images" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </template>
          <template v-if="data.navAlignment === 'left'">
            <div class="col-lg-9 col-md-6 col-4 position-static">
              <div class="header-left d-flex">
                <Logo />
                <nav class="mainmenu-nav d-none d-lg-block">
                  <Nav />
                </nav>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 col-8">
              <div class="header-right">
                <div class="header-btn">
                  <a :class="data.buyButtonClass" href="/PlanandPricing"> PACKAGE </a>
                </div>

                <div v-if="data.showThemeSwitcherButton" id="my_switcher" class="my_switcher">
                  <ul>
                    <li>
                      <a href="javascript: void(0);" @click.prevent="handleClick">
                        <img class="light-icon" src="../../../assets/images/icons/sun-01.svg" alt="Sun images" />
                        <img class="dark-icon" src="../../../assets/images/icons/vector.svg" alt="Moon Images" />
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="dropdown">
                  <a class="" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown"
                    aria-expanded="false">
                    <div class="i18n-lang-layout mx-3" v-if="!userLogin">
                      <img src="../../../assets/images/Newlandindpage/Group_27927.svg" alt="" />
                    </div>
                    <div class="i18n-lang-layout mx-3" v-if="userLogin">
                      <img src="../../../assets/images/Newlandindpage/path/Group 28076.png" alt="" />
                    </div>
                  </a>

                  <ul class="dropdown-menu large-dropdown my-4" :class="{
    'background-dropdown-2': userLogin,
    'background-dropdown': !userLogin
  }" aria-labelledby="dropdownMenuLink">
                    <div class="container p-5 text flex-center-item-list">
                      <div class="row">
                        <div class="col-md-4">
                          <li>
                            <a @click="changeLanguage('en')" class="text-light font-header Robotofont-light"
                              :class="{ 'text-light': !userLogin, 'text-black': userLogin }" href="#">{{ $t('Menu.en')
                              }}</a>
                          </li>
                        </div>
                        <div class="col-md-4">
                          <li>
                            <a class="text-light font-header Robotofont-light disabled not-allowed not-allowed bg-font-2"
                              :class="{ 'text-light': !userLogin, 'text-black': userLogin }">{{ $t('Menu.th') }}</a>
                          </li>
                        </div>
                        <div class="col-md-4">
                          <li>
                            <a class="text-light font-header Robotofont-light disabled not-allowed not-allowed bg-font-2"
                              type="button" :class="{ 'text-light': !userLogin, 'text-black': userLogin }" href="#">{{
    $t('Menu.bn') }}</a>
                          </li>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <li>
                            <a class="text-light font-header Robotofont-light disabled not-allowed not-allowed bg-font-2"
                              :class="{ 'text-light': !userLogin, 'text-black': userLogin }" href="#">{{ $t('Menu.cm')
                              }}</a>
                          </li>
                        </div>
                        <div class="col-md-4">
                          <li>
                            <a class="text-light font-header Robotofont-light disabled not-allowed not-allowed bg-font-2"
                              :class="{ 'text-light': !userLogin, 'text-black': userLogin }" href="#">{{ $t('Menu.in')
                              }}</a>
                          </li>
                        </div>
                        <div class="col-md-4">
                          <li>
                            <a class="text-light font-header Robotofont-light disabled not-allowed not-allowed bg-font-2"
                              href="#" :class="{ 'text-light': !userLogin, 'text-black': userLogin }">{{ $t('Menu.la')
                              }}</a>
                          </li>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <li>
                            <a class="text-light font-header Robotofont-light disabled not-allowed not-allowed bg-font-2"
                              href="#" :class="{ 'text-light': !userLogin, 'text-black': userLogin }">{{ $t('Menu.ml')
                              }}</a>
                          </li>
                        </div>
                        <div class="col-md-4">
                          <li>
                            <a class="text-light font-header Robotofont-light disabled not-allowed not-allowed bg-font-2"
                              href="#" :class="{ 'text-light': !userLogin, 'text-black': userLogin }">{{ $t('Menu.mm')
                              }}</a>
                          </li>
                        </div>
                        <div class="col-md-4">
                          <li>
                            <a class="text-light font-header Robotofont-light disabled not-allowed not-allowed bg-font-2"
                              href="#" :class="{ 'text-light': !userLogin, 'text-black': userLogin }">{{ $t('Menu.pp')
                              }}</a>
                          </li>
                        </div>
                      </div>
                    </div>
                  </ul>
                </div>
                <div class="mobile-menu-bar mr--10 d-block d-lg-none not-allowed">
                  <div class="hamberger">
                    <div class="hamberger" :class="{ 'text-bg-light': userLogin, '': !userLogin }">
                      <button class="hamberger-button"
                        @click.prevent="AppFunctions.toggleClass('.popup-mobile-menu', 'active')">
                        <Icon name="menu" size="21" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img class="" v-if="userLogin === false" src="../../../assets/images/Newlandindpage/path/imtest.png"
              alt="" />
            <img class="" v-if="userLogin === true" src="../../../assets/images/Newlandindpage/path/imtest.png"
              alt="" />
          </template>
        </div>
      </div>
    </header>
    <MobileMenu />
    <div>
      <div class="rn-gradient-circle" />
      <div class="rn-gradient-circle theme-pink" />
    </div>
  </div>
</template>

<script>
import Icon from '../../icon/Icon'
import MobileMenu from './MobileMenu'
import AppFunctions from '../../../helpers/AppFunctions'
import Nav from './Nav'
import Logo from '../../elements/logo/Logo'
import { usePiniaStore } from '../../../store/index'
import i18n from '../../../../i18n/i18n'
import { mapMutations } from 'vuex'

export default {
  name: 'Header',
  i18n,
  props: {
    data: {
      default: null,
      test: true,
      dropdownMenuButton: true
    }
  },
  components: { Logo, Nav, MobileMenu, Icon },
  data() {
    return {
      hasNewClass: false,
      AppFunctions
    }
  },
  methods: {
    toggleStickyHeader() {
      const scrolled = document.documentElement.scrollTop
      if (scrolled > 100) {
        AppFunctions.addClass('.header-default', 'sticky')
      } else if (scrolled <= 100) {
        AppFunctions.removeClass('.header-default', 'sticky')
      }
    },
    // sendValueToStore() {
    //   const store = usePiniaStore()
    //   return store.setButtonValue('Value from button')
    // },
    // () {
    //   document.body.classList.toggle('active-light-mode') // เปลี่ยนคลาสให้เข้าหรือออก
    //   this.checkBodyClass() // เรียกเมธอดเพื่อตรวจสอบคลาส
    // },
    // checkBodyClass() {
    //   this.hasNewClass = document.body.classList.contains('active-light-mode')
    // },
    handleClick() {
      this.$store.commit('dataLogin', !this.userLogin) // สลับค่า state ใน Vuex store
      AppFunctions.toggleClass('body', 'active-light-mode')
    },

    testclick() {
      this.$store.commit('dataLogin', !this.userLogin)
    },
    ...mapMutations(['setLocale']),
    changeLanguage(locale) {
      this.setLocale(locale)
      this.$i18n.locale = locale // เปลี่ยน locale ใน i18n
    }
  },

  computed: {
    userLogin() {
      return this.$store.state.userLogin
    }
  },
  created() {
    window.addEventListener('scroll', this.toggleStickyHeader)
  },
  mounted() {
    this.toggleStickyHeader()
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.toggleStickyHeader)
  }
  // toggleLanguage(locale) {
  //   i18n.locale = locale
  // }
}
</script>
<style>
.large-dropdown {
  width: 100vw;
  /* ปรับขนาดตามความต้องการ */
  font-size: 20px;
  /* translate: transform(10%, 100%); ปรับขนาดตามความต้องการ */
}

.background-dropdown {
  background-image: url(../../../assets/images/Newlandindpage/path/bg_topbar_dark.png);
  background-size: cover;
  background-position: -50% 20%;
  width: 100vw;
  height: 100vh;
}

.background-dropdown-2 {
  background-image: url(/img/bg_topbar.375c5271.png);
  background-size: cover;
  background-position: -50% 80%;
  width: 100vw;
  height: 100vh;
}

.flex-center-item-list {
  transform: translate(10%, 100%);
}

.font-header {
  font-size: 26px;
}

.path-112 {
  transform: translate(0%, 60%);
}

.not-allowed {
  cursor: not-allowed;
}

.bg-font-2 {
  opacity: 0.5;
  cursor: not-allowed;
}
</style>
