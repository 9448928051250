<template>
  <div :class="`rn-accordion-style ${accordionStyleClass} accordion`">
    <div class="accordion" :id="id">
      <div class="accordion-item card" v-for="(content, index) in accordionContent">
        <h2 class="accordion-header card-header" :id="`heading${content.id}`">
          <button
            class="accordion-button"
            type="button"
            data-bs-toggle="collapse"
            :data-bs-target="`#collapse${content.id}`"
            :aria-expanded="index === activeCollapse ? 'true' : 'false'"
            :aria-controls="`collapse${content.id}`"
            @click="activeCollapse = index"
          >
            {{ content.title }}
            <i class="collapse-icon" />
          </button>
        </h2>
        <div
          :id="`collapse${content.id}`"
          class="accordion-collapse collapse"
          :class="{ show: index === 0 }"
          :aria-labelledby="`heading${content.id}`"
          :data-bs-parent="`#${id}`"
        >
          <div class="accordion-body card-body">
            {{ content.description }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Accordion',
  props: {
    id: {
      type: String,
      default: 'accordionExample'
    },
    accordionStyleClass: {
      type: String
    },
    accordionContent: {
      type: Array,
      default: function () {
        return [
          {
            id: 'One',
            title: 'Are there tangible benefits to implementing EV roaming?',
            description:
              'Indeed, there are significant advantages, especially in countries with high charger utilization rates. EV roaming enhances your platforms user engagement, which can be leveraged for future marketing initiatives.'
          },
          {
            id: 'Two',
            title: 'How is the choice made between peer-to-peer and hub-based topology?',
            description:
              'The hub-based architecture has demonstrated its effectiveness, particularly when multiple operators are part of the community. This becomes even more advantageous when international integration among various country hubs is achieved.'
          },
          {
            id: 'Three',
            title: 'Will this service help reduce development costs?',
            description:
              'Absolutely, it not only reduces development costs but also accelerates time to market. Managing peer-to-peer connections for all connected operators can be labor-intensive and require extensive maintenance.'
          },
          {
            id: 'Four',
            title: 'Are there any supplementary services available?',
            description:
              'Certainly, we offer a range of valuable services and tools that can be seamlessly integrated with your existing platform. These include real-time database access, customized authorization solutions, data analytics, and more. Please dont hesitate to reach out to our team for further information on these offerings.'
          }
        ]
      }
    }
  },
  data() {
    return {
      activeCollapse: 0
    }
  }
}
</script>
