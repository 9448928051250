var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{class:[
    `brand-list`,
    { 'brand-style-1': _vm.brandStyle === 1 },
    { 'brand-style-2': _vm.brandStyle === 2 },
    { 'brand-style-2': _vm.brandStyle === 3 },
    { 'brand-style-2 variation-2': _vm.brandStyle === 4 }
  ]},[_vm._l((_vm.brandList),function(brand,index){return (_vm.userLogin === false)?_c('li',{key:index},[_c('a',{attrs:{"href":"#"}},[_c('img',{staticClass:"sizeimage",attrs:{"src":brand.image,"alt":"Brand Image"}})])]):_vm._e()}),_vm._l((_vm.brandlistlight),function(item,inex){return (_vm.userLogin === true)?_c('li',{key:inex},[_c('a',{attrs:{"href":"#"}},[_c('img',{staticClass:"sizeimage",attrs:{"src":item.image,"alt":"Brand Image"}})])]):_vm._e()})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }