import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    userLogin: false,
    locale: 'en'
  },
  //end state,
  mutations: {
    dataLogin(state, data) {
      state.userLogin = data
      console.log(state.userLogin)
    },
    setLocale(state, locale) {
      state.locale = locale
    }
  }, //end mutations
  actions: {} //end actions
})
