<template>
  <ul
    :class="[
      `brand-list`,
      { 'brand-style-1': brandStyle === 1 },
      { 'brand-style-2': brandStyle === 2 },
      { 'brand-style-2': brandStyle === 3 },
      { 'brand-style-2 variation-2': brandStyle === 4 }
    ]"
  >
    <li v-if="userLogin === false" v-for="(brand, index) in brandList" :key="index">
      <a href="#"><img class="sizeimage" :src="brand.image" alt="Brand Image" /></a>
    </li>
    <li v-if="userLogin === true" v-for="(item, inex) in brandlistlight" :key="inex">
      <a href="#"><img class="sizeimage" :src="item.image" alt="Brand Image" /></a>
    </li>
    <!-- <p>ข้อมูลที่รับ: {{ receivedData }}</p> -->
  </ul>
</template>

<script>
export default {
  name: 'Brand',
  props: {
    brandList: {},
    brandlistlight: {},
    brandStyle: {
      type: Number,
      default: 1
    }
  },
  computed: {
    userLogin() {
      return this.$store.state.userLogin
    }
  }
}
</script>
