import AppFunctions from '../helpers/AppFunctions'

export default {
  data() {
    return {
      posts: [
        {
          id: '1',
          img: require(`../assets/images/Newlandindpage/blog/2566-09-04 10.06.00.jpg`),
          imgdetail: require(`../assets/images/Newlandindpage/blog/2566-09-04 09.55.58.jpg`),
          // author: 'Irin Pervin',
          authorId: '1',
          view: '70 views',
          publishedAt: 'TATIYA LIMVICHIT / 16 - 17 August 2023',
          title: 'TECHSAUCE GLOBAL SUMMIT 2023 is the biggest and best tech summit of the year  ',
          excerpt:
            'Apart of Business opportunity Techsause become Asia’s leading tech conference and must go desti..',
          body: `
Apart of Business opportunity Techsause become Asia’s leading tech conference and must go destination for tech savvy around the world is “contents”. For 2023, the conference will still be packed with “in-depth” contents from world-class sources, so that participants will be able to keep up with the fast-changing trends and deeply understand how to benefit from the trend through action.

This year Gridwhiz we have exhibit “EV Coming Hub” platform by Pump Charge Platform. The pump Charge are the application and platform to to roming and centralize the many EV charging application to be the one platform for make user convenient to use. They can find location, booking, charging and also payment within one application that very easy to use. Let see at
`,
          categories: ['Development', 'Company', 'Marketing'],
          tags: ['Lorem ipsum']
        }
        // {
        //   id: '2',
        //   img: require(`../assets/images/Newlandindpage/blog/Screenshot 2566-07-26 at 15.06.40@2x.png`),
        //   author: 'Fatima Asrafy',
        //   authorId: '2',
        //   view: '70 views',
        //   publishedAt: '30 Nov 2022',
        //   title: 'Should Fixing Corporate Take 100 Steps.',
        //   excerpt: "We have always known the power of digital we've.",
        //   body: `<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>
        //                    <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
        //                    <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words.</p>
        //                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
        //                    <p>If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.</p>
        //                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>`,
        //   categories: ['UX Design', 'Company'],
        //   tags: ['Minimal', 'Company', 'Development', 'Design']
        // },
        // {
        //   id: '3',
        //   img: require(`../assets/images/Newlandindpage/blog/Screenshot 2566-07-26 at 15.09.36@2x.png`),
        //   author: 'John Dou',
        //   authorId: '3',
        //   view: '70 views',
        //   publishedAt: '12 Oct 2022',
        //   title: 'The Next 100 Things To Immediately Do About.',
        //   excerpt: "We have always known the power of digital we've.",
        //   body: `<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>
        //                    <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
        //                    <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words.</p>
        //                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
        //                    <p>If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.</p>
        //                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>`,
        //   categories: ['Development', 'UX Design', 'Business', 'Company'],
        //   tags: ['App Landing', 'Startup', 'Development', 'Design']
        // },
        // {
        //   id: '4',
        //   img: require(`../assets/images/Newlandindpage/blog/Screenshot 2566-07-26 at 15.11.20@2x.png`),
        //   author: 'Jannin',
        //   authorId: '4',
        //   view: '70 views',
        //   publishedAt: '25 Aug 2022',
        //   title: 'Top 5 Lessons About Corporate To Learn Before.',
        //   excerpt: "We have always known the power of digital we've.",
        //   body: `<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>
        //                    <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
        //                    <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words.</p>
        //                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
        //                    <p>If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.</p>
        //                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>`,
        //   categories: ['Marketing', 'UX Design', 'Business'],
        //   tags: ['App', 'Business', 'Software', 'Design']
        // },
        // {
        //   id: '5',
        //   img: require(`../assets/images/Newlandindpage/blog/Screenshot 2566-07-26 at 15.12.22@2x.png`),
        //   author: 'Jannin',
        //   authorId: '5',
        //   view: '70 views',
        //   publishedAt: '23 Jul 2022',
        //   title: 'Master The Art Of Corporate With These 5 Tips.',
        //   excerpt: "We have always known the power of digital we've.",
        //   body: `<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>
        //                    <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
        //                    <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words.</p>
        //                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
        //                    <p>If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.</p>
        //                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>`,
        //   categories: ['App Development', 'UX Design', 'Application', 'Art'],
        //   tags: ['Company', 'Corporate', 'Development', 'Design']
        // }
        // {
        //   id: '6',
        //   img: require(`../assets/images/Newlandindpage/blog/Screenshot 2566-07-26 at 15.12.48@2x.png`),
        //   author: 'John Dou',
        //   authorId: '6',
        //   view: '70 views',
        //   publishedAt: '30 Jun 2022',
        //   title: 'Corporate Is Your Worst Enemy. 5 Ways To Defeat It.',
        //   excerpt: "We have always known the power of digital we've.",
        //   body: `<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>
        //                    <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
        //                    <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words.</p>
        //                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
        //                    <p>If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.</p>
        //                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>`,
        //   categories: ['Application', 'UX Design', 'Art'],
        //   tags: ['App', 'Landing', 'Design', 'Software']
        // },
        // {
        //   id: '7',
        //   img: require(`@/assets/images/blog/blog-02.jpg`),
        //   author: 'Fatima Asrafy',
        //   authorId: '2',
        //   view: '70 views',
        //   publishedAt: '30 Nov 2022',
        //   title: 'Should Fixing Corporate Take 100 Steps.',
        //   excerpt: "We have always known the power of digital we've.",
        //   body: `<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>
        //                    <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
        //                    <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words.</p>
        //                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
        //                    <p>If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.</p>
        //                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>`,
        //   categories: ['UX Design', 'Company'],
        //   tags: ['Minimal', 'Company', 'Development', 'Design']
        // },
        // {
        //   id: '8',
        //   img: require(`@/assets/images/blog/blog-03.jpg`),
        //   author: 'John Dou',
        //   authorId: '3',
        //   view: '70 views',
        //   publishedAt: '12 Oct 2022',
        //   title: 'The Next 100 Things To Immediately Do About.',
        //   excerpt: "We have always known the power of digital we've.",
        //   body: `<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>
        //                    <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
        //                    <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words.</p>
        //                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
        //                    <p>If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.</p>
        //                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>`,
        //   categories: ['Development', 'UX Design', 'Business', 'Company'],
        //   tags: ['App Landing', 'Startup', 'Development', 'Design']
        // }
      ],
      categories: '',
      tags: '',
      AppFunctions
    }
  },
  methods: {
    blogCategories() {
      let cats = this.posts.map((item) => {
          return item.categories
        }),
        singleCatArray = AppFunctions.flatDeep(cats),
        categories = []

      singleCatArray.forEach((cat) => {
        const obj = {
          title: cat.trim(),
          slug: AppFunctions.slugify(cat),
          count: 1
        }
        const objIndex = AppFunctions.containsObject(obj, categories)
        if (objIndex !== -1) {
          const prevCount = categories[objIndex].count
          categories[objIndex] = {
            title: cat.trim(),
            slug: AppFunctions.slugify(cat),
            count: prevCount + 1
          }
        } else {
          categories.push(obj)
        }
      })

      this.categories = categories
    },
    blogTags() {
      let tags = this.posts.map((item) => {
          return item.tags
        }),
        singleTagArray = AppFunctions.flatDeep(tags),
        tagsData = []

      singleTagArray.forEach((tag) => {
        const obj = {
            title: tag.trim(),
            slug: AppFunctions.slugify(tag)
          },
          objIndex = AppFunctions.containsObject(obj, tagsData)

        if (objIndex !== -1) {
          tagsData[objIndex] = {
            title: tag.trim(),
            slug: AppFunctions.slugify(tag)
          }
        } else {
          tagsData.push(obj)
        }
      })

      this.tags = tagsData
    }
  },
  created() {
    this.blogCategories()
    this.blogTags()
  }
}
