<template>
  <Layout
    header-class="header-left-align header-transparent"
    nav-alignment="left"
    :topbar-style="2"
    buy-button-class="btn-default btn-small"
  >
    <!-- Start Slider area -->
    <div
      class="slider-area slider-style-2 variation-2 fix-hg bg_image"
      :class="{ 'background-about-sec1': userLogin, '': !userLogin }"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-8 col-xl-6">
            <div class="inner text-start">
              <h1 class="LexenExtraBold" data-aos="fade-up" data-aos-delay="100">
                We provide <br />reliable business <br />
                consulting help.
              </h1>
              <h6 class="tag-title LexenExtraBold" data-aos="fade-up" data-aos-delay="150">
                SOLUTION FOR YOUR BUSINESS
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="rwt-timeline-area rn-section-gap"
      :class="{ 'background-about-sec2': userLogin === true, '': userLogin === false }"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <SectionTitle
              class="LexenExtraBold"
              text-align="center"
              subtitle="Working Process"
              title="Our Working Process."
              data-aos="fade-up"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-lg-10 offset-lg-1 mt--50">
            <Timeline :timeline-data="timelineData" :timeline-style="3" />
          </div>
        </div>
        <About :image="require(`@/assets/images/about/about-4.png`)" />
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '../../components/common/Layout'
import Timeline from '../../components/elements/timeline/Timeline'
import SectionTitle from '../../components/elements/sectionTitle/SectionTitle'
import About from '../../components/elements/about/About'

export default {
  name: 'Consulting',
  components: {
    Layout,
    SectionTitle,
    Timeline,
    About
  },
  data() {
    return {
      timelineData: [
        {
          id: '1',
          title: 'Knowledge',
          description: 'Present all available features in Essentials.'
        },
        {
          id: '2',
          title: 'Working',
          description: 'All Feature available features in Essentials.'
        },
        {
          id: '3',
          title: 'Solution',
          description: 'Popular Feature available features in Essentials.'
        },
        {
          id: '4',
          title: 'Process',
          description: 'Latest Feature available features in Essentials.'
        }
      ]
    }
  },
  computed: {
    userLogin() {
      return this.$store.state.userLogin
    }
  }
}
</script>
<style>
.background-about-sec2 {
  background-image: url(../../assets/images/Newlandindpage/lightmode/bg-about-sec2.png);
  background-size: cover;
  background-position: -0% 0%;
  /* background-repeat: no-repeat; */
  background-position: center;
  z-index: 2;
  width: 100%;
  height: 100%;
}
.background-about-sec1 {
  background-image: url(../../assets/images/Newlandindpage/lightmode/bg-about-sec1.png);
  background-size: cover;
  background-position: -0% 0%;
  /* background-repeat: no-repeat; */
  background-position: center;
  z-index: 2;
  width: 100%;
  height: 100%;
}
.fix-hg {
  height: 1120px;
  display: flex;
  align-items: center;
}
</style>
