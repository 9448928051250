<template>
  <ul class="mainmenu">
    <li class="has-droupdown has-menu-child-item">
      <a class="link-with-hover">
        Home
        <Icon name="plus" class="collapse-icon plus" />
        <Icon name="minus" class="collapse-icon minus" />
        <img
          class="img-flip"
          name="plus"
          src="../../../assets/images/Newlandindpage/path/chevron-down-outline@2x.png"
          alt=""
        />
      </a>
      <ul class="submenu">
        <li>
          <a @click="scrollToSection('section1')"> What is HubThings? </a>
        </li>
        <li>
          <a @click="scrollToSection('section2')"> What are the benefits of EV Roaming? </a>
        </li>
        <li>
          <a @click="scrollToSection('section3')"> Our services </a>
        </li>
        <li>
          <a @click="scrollToSection('section4')"> Concerned Parties </a>
        </li>
        <li>
          <a @click="scrollToSection('section5')"> Implementation / Features </a>
        </li>
        <li>
          <a @click="scrollToSection('section6')"> Covering in ASEAN countries and beyond </a>
        </li>
        <li>
          <a @click="scrollToSection('section7')"> Meet our customers </a>
        </li>
        <li>
          <a @click="scrollToSection('section8')"> Get the right job in 0 easy steps </a>
        </li>
        <li>
          <a @click="scrollToSection('section9')"> How can we help? </a>
        </li>
      </ul>
    </li>
    <!-- <li><router-link to="/Tripplaner">Map Station</router-link></li> -->
    <li class="with-megamenu has-menu-child-item">
      <a @click="scrollToComponent('sec3', 0)">
        Service
        <!-- <Icon name="plus" class="collapse-icon plus" />
        <Icon name="minus" class="collapse-icon minus" /> -->
      </a>
    </li>
    <li class="has-droupdown has-menu-child-item">
      <a href="/Blog"> Blog </a>
    </li>
    <li class="has-droupdown has-menu-child-item">
      <a href="/Aboutnew"> About </a>
    </li>
    <li><router-link to="/contact">Contact</router-link></li>
  </ul>
</template>

<script>
import Icon from '../../icon/Icon'
export default {
  isScrolling: false,
  name: 'Nav',
  components: { Icon },
  methods: {
    scrollToSection(sectionId) {
      const element = document.getElementById(sectionId)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }
}
</script>
<style>
.link-with-bg {
  position: relative;
  display: inline-block;
  padding: 10px 20px; /* ปรับขนาดให้เหมาะกับข้อความ */
  color: #000; /* สีข้อความเริ่มต้น */
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}

.link-with-bg::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(../../../assets/images/Newlandindpage/chevron-down-outline.svg);
  background-repeat: no-repeat;
  background-size: contain;
  transform-origin: center;
  transition: transform 0.7s ease-in-out;
}

.link-with-hover {
  display: inline-block;
  position: relative; /* เพื่อให้สามารถควบคุมตำแหน่งของรูปภาพ */
  text-decoration: none;
}

.link-with-hover .img-flip {
  transition: transform 0.2s ease-in-out;
  transform: rotate(0deg); /* กำหนดมุมเริ่มต้นของรูปภาพ */
}

.link-with-hover:hover .img-flip {
  transform: rotate(-180deg); /* หมุนรูปภาพเมื่อโฮเวอร์ */
}
</style>
