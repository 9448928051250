<template>
  <div class="logo">
    <router-link :to="url">
      <!-- <img class="logo-light" :src="lightLogo" alt="Corporate Logo" /> -->
      <img class="logo-light" :src="newlogo" alt="New Logo" />
      <img class="logo-dark" :src="darkLogo" alt="Corporate Logo" />
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'Logo',
  props: {
    url: {
      type: String,
      default: '/'
    },
    lightLogo: {
      type: String,
      default: function () {
        return require(`@/assets/images/logo/logo.png`)
      }
    },
    darkLogo: {
      type: String,
      default: function () {
        return require(`@/assets/images/Newlandindpage/newimage/Light Mode2_1@4x.png`)
      }
    },
    newlogo: {
      type: String,
      default: function () {
        return require(`@/assets/images/Newlandindpage/newimage/Dark Mode2_1@4x.png`)
      }
    }
  }
}
</script>
<style>
.size {
  width: 65%;
}
</style>
