<template>
  <div class="about-style-5 rn-section-gapBottom">
    <div class="container">
      <div class="row">
        <div class="col-lg-10 offset-lg-1">
          <div class="row row--0 about-wrapper align-items-center theme-shape">
            <div class="col-lg-6">
              <div class="thumbnail">
                <img src="../../../assets/images/about/about-5.png" alt="About Images" />
              </div>
            </div>
            <div class="col-lg-6 mt_md--30 mt_sm--30">
              <div class="content">
                <div class="inner">
                  <h4 class="title">I'm Web Dedeloper</h4>
                  <p>Web designer and developer working for envato.com in Paris, France.</p>
                  <ul class="contact-address">
                    <li>
                      <Icon name="file" />
                      Web designer & developer
                    </li>
                    <li>
                      <Icon name="phone" />
                      +01916395965
                    </li>
                    <li>
                      <Icon name="map-pin" />
                      Dhaka, Bangladesh
                    </li>
                  </ul>
                  <div class="download-button mt--20">
                    <a class="btn-read-more" href="#"><span>Download My CV</span></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '../../icon/Icon'

export default {
  name: 'AboutFive',
  components: { Icon }
}
</script>
