<template>
  <div class="row row--15 service-wrapper">
    <div
      class="col-lg-4 col-md-6 col-sm-6 col-12 mt--10"
      v-for="(service, index) in serviceList"
      :key="index"
    >
      <div
        :class="`service ${serviceStyle} ${checkTextAlign}`"
        data-aos="fade-up"
        data-aos-delay="70"
      >
        <div class="image" v-if="userLogin === false">
          <img style="width: 100%" :src="service.image" alt="Service Images" />
        </div>
        <!-- :class="{ 'background-proscon_dark': userLogin === false, '': userLogin === true }" -->
        <div class="image" v-if="userLogin === true">
          <img
            style="width: 98%"
            :src="service.image2"
            alt="Service Images"
            v-if="index != 1 && index != 3 && index != 11"
          />
          <img style="width: 100%" :src="service.image2" alt="Service Images" v-if="index == 1" />
          <img style="width: 77%" :src="service.image2" alt="Service Images" v-if="index == 3" />
          <img
            style="width: 88%; padding-top: 20%"
            :src="service.image2"
            alt="Service Images"
            v-if="index == 11"
          />
        </div>
        <div class="content">
          <h4 class="title w-600 mt--10" style="width: 100%">
            <router-link to="/service-details" v-text="service.title" />
          </h4>
          <p class="description b1 color-gray mb--0" v-text="service.description" />
        </div>
      </div>
    </div>
    <!-- <div class="text-center mt-5">
      <router-link class="btn-default btn-icon mt-5" to="#"> Read More </router-link>
    </div> -->
  </div>
</template>

<script>
import Icon from '../../icon/Icon'

export default {
  name: 'ServiceOne',
  components: { Icon },
  props: {
    serviceStyle: {
      type: String
    },
    iconSize: {
      type: String | Number,
      default: 62
    },
    textAlign: {
      type: String,
      default: 'left'
    }
  },
  data() {
    return {
      serviceList: [
        {
          image: require('@/assets/images/Newlandindpage/Mask Group 2913@2x.png'),
          image2: require('../../../assets/images/Newlandindpage/lightmode/1.png'),

          title: 'Configuration of service connection',
          description:
            'Less tedious work is needed when either a single (CPO/eMSP) or dual (CPO & eMSP) connection is integrated into the hub otherwise, the workload multiplies based on the number of parties being integrated.'
        },
        {
          image: require('@/assets/images/Newlandindpage/Mask Group 2914@2x.png'),
          image2: require('../../../assets/images/Newlandindpage/lightmode/2.png'),

          title: 'Provision of physical servers',
          description:
            'Adding more parties doesn necessarily make the IT staff happier, as connecting a greater number of parties often means more processes that demand multiple VMs or containers, resulting in higher investment costs.'
        },
        {
          image: require('@/assets/images/Newlandindpage/Mask Group 2915@2x.png'),
          image2: require('../../../assets/images/Newlandindpage/lightmode/3.png'),

          title: 'Data integration to existing EVSMS',
          description:
            'Multiple connections from various parties necessitate repetitive data integration into your Electric Vehicle Charging Station Management System (EVCSMS), which includes elevated maintenance tasks as well.'
        },
        {
          image: require('@/assets/images/Newlandindpage/Mask Group 2916@2x.png'),
          image2: require('../../../assets/images/Newlandindpage/lightmode/g1.png'),

          title: 'Create / Read / Update connection',
          description:
            'Once connected to the hub, these internal services only need to be implemented once, without any concerns about future parties that require integration. Newcomers can easily make use of the existing service. '
        },
        {
          image: require('@/assets/images/Newlandindpage/Mask Group 2917@2x.png'),
          image2: require('../../../assets/images/Newlandindpage/lightmode/555.png'),

          title: 'Data format validation servers  ',
          description:
            "The platform always validates the messages back and forth, so the sender will be notified if there's an issue, and the receiver will receive the validated message without any concerns."
        },
        {
          image: require('@/assets/images/Newlandindpage/Mask Group 2918@2x.png'),
          image2: require('../../../assets/images/Newlandindpage/lightmode/666.png'),

          title: 'Consultancy service from experts available',
          description:
            'We offer comprehensive consultancy as a dedicated bundled service for our customers, covering both technical and business logic aspects. Our implementation experts are also just a phone call away, ready to assist you.'
        },
        {
          image: require('@/assets/images/Newlandindpage/Mask Group 2920@2x.png'),
          image2: require('../../../assets/images/Newlandindpage/lightmode/7.png'),

          title: 'Only one legal contract to be reviewed',
          description:
            'Rather than dealing with numerous separate and two-way contracts with each party, connecting to the hub only necessitates a single legal agreement encompassing all. This approach reduces the need for extensive legal interpretation and review, leading to a quicker settlement process.'
        },
        {
          image: require('@/assets/images/Newlandindpage/Mask Group 2919@2x.png'),
          image2: require('../../../assets/images/Newlandindpage/lightmode/8.png'),

          title: 'Extra service provided* (e.g. real-time database)',
          description:
            'We also provide valuable services to our customers, enabling them to accelerate implementation without the need to handle everything on their own. This allows them to concentrate solely on refining their business model for a swift and efficient process.'
        },
        {
          image: require('@/assets/images/Newlandindpage/Mask Group 2915@2x.png'),
          image2: require('../../../assets/images/Newlandindpage/lightmode/3.png'),

          title: 'Maintenance cost',
          description:
            'Having fewer processes currently active means you won have to incur excessive maintenance costs or hire as much personnel, resulting in a significantly lower annual operating cost.'
        },
        {
          image: require('@/assets/images/Newlandindpage/Mask Group 2923@2x.png'),
          image2: require('../../../assets/images/Newlandindpage/lightmode/10.png'),

          title: 'Required times to go LIVE',
          description:
            'In conjunction with the readily available services we offer, your software implementation team can speed up the time it takes to launch your platform in the market, leading to a quicker financial return on investment. '
        },
        {
          image: require('@/assets/images/Newlandindpage/Mask Group 2922@2x.png'),
          image2: require('../../../assets/images/Newlandindpage/lightmode/11.png'),

          title: 'Ready for international cost',
          description:
            'These are the distinct advantages of integrating with the hub, as opposed to connecting on a P2P basis. Information from CPOs/eMSPs in other countries seamlessly flows in and out of your platform without any extra effort mandated.'
        },
        {
          image: require('@/assets/images/Newlandindpage/Mask Group 2924@2x.png'),
          image2: require('../../../assets/images/Newlandindpage/lightmode/12.png'),

          title: 'Total development cost',
          description:
            'In summary, there nothing more noteworthy than the overall development cost for your project when you integrate with the hub. As a rule of thumb, reduced development costs directly translate to a quicker return. '
        },
        {
          image: require('@/assets/images/Newlandindpage/Mask Group 2925@2x.png'),
          image2: require('../../../assets/images/Newlandindpage/lightmode/13.png'),

          title: 'Single point of settlement',
          description:
            'When you link up with the hub, you can streamline your settlement process, making business operations smoother. You experience less hassle compared to dealing with multi-level communication in a standard P2P setup.'
        }
      ]
    }
  },
  computed: {
    checkTextAlign() {
      if (this.textAlign === 'left') return 'text-start'
      else if (this.textAlign === 'center') return 'text-center'
      else if (this.textAlign === 'end') return 'text-end'
      else return ''
    },
    userLogin() {
      return this.$store.state.userLogin
    }
  }
}
</script>

<style>
.background-proscon_dark {
  background-image: url(../../../assets/images/Newlandindpage/lightmode/bg_proscon_dark.png);
  background-size: cover;
  background-position: -0% 0%;
  /* background-repeat: no-repeat; */
  background-position: center;
  position: absolute;
  background-attachment: fixed;
  z-index: 1;
  width: 100%;
  height: auto;
}
</style>
