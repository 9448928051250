<template>
  <Layout header-class="header-left-align " nav-alignment="left">
    <Separator />

    <div
      class="rwt-pricing-area rn-section-gap"
      :class="{
        'background-planprice-sec1': userLogin === false,
        'background-planprice-sec1-light': userLogin === true
      }"
    >
      <div class="container">
        <div class="row mb--35">
          <div class="col-lg-12">
            <SectionTitle
              text-align="center"
              subtitle="EV Roaming Hub plans, Pricing and Membership"
              title="Plans and Pricing (Development Service)"
              data-aos="fade-up"
            />
          </div>
        </div>
        <Pricing :pricing-data="pricingData" />
      </div>
    </div>
    <div class="rwt-pricing-area rn-section-gap">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <SectionTitle
              text-align="center"
              subtitle="EV Roaming Hub plans, Pricing and Membership"
              title="Plans and Pricing (Business)"
              data-aos="fade-up"
            />
          </div>
        </div>
        <Pricing :pricing-data="pricingData" :pricing-style="5" />
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from '../../components/common/Layout'
import Icon from '../../components/icon/Icon'
import SectionTitle from '../../components/elements/sectionTitle/SectionTitle'
import ServiceThree from '../../components/elements/service/ServiceThree'
import Separator from '../../components/elements/separator/Separator'
import Counter from '../../components/elements/counterUp/Counter'
import Team from '../../components/elements/team/Team'
import Pricing from '../../components/elements/pricing/Pricing'
import CallToAction from '../../components/elements/callToAction/CallToAction'
import AboutTwo from '../../components/elements/about/AboutTwo'
import ServiceTwo from '../../components/elements/service/ServiceTwo'

export default {
  name: 'Corporate',
  components: {
    ServiceTwo,
    AboutTwo,
    CallToAction,
    Pricing,
    Team,
    Counter,
    Separator,
    ServiceThree,
    SectionTitle,
    Icon,
    Layout
  },
  data() {
    return {
      pricingData: [
        {
          title: 'Free',
          currency: '',
          price: '0',
          subtitle: 'USD Per Month',
          features: [
            { title: ' Lorem ipsum' },
            { title: ' Lorem ipsum' },
            { title: ' Lorem ipsum' },
            { title: ' Lorem ipsum' },
            { title: ' Lorem ipsum' }
          ],
          btn: {
            text: 'Buy Now',
            link: '/Implementation'
          }
        },
        {
          title: ' Lorem ipsum',
          currency: '',
          price: '1,143.83',
          subtitle: 'USD Per Month',
          features: [
            { title: ' 7-day sandbox access' },
            { title: ' No technical support' },
            { title: 'Lorem ipsum' },
            { title: 'Lorem ipsum' },
            { title: 'Lorem ipsum' }
          ],
          btn: {
            text: 'Buy Now',
            link: '/Implementation'
          },
          isActive: true
        },
        {
          title: 'Lorem ipsum',
          currency: '',
          price: '0',
          subtitle: 'USD Per Month',
          features: [
            { title: 'Lorem ipsum' },
            { title: 'Lorem ipsum' },
            { title: 'Lorem ipsum' },
            { title: 'Lorem ipsum' },
            { title: 'Lorem ipsum' }
          ],
          btn: {
            text: 'Buy Now',
            link: '/Implementation'
          }
        }
      ]
    }
  },
  computed: {
    userLogin() {
      return this.$store.state.userLogin
    }
  }
}
</script>
<style>
.background-planprice-sec1 {
  background-image: url(../../assets/images/Newlandindpage/lightmode/bg-planprice-sec1.png);
  background-size: cover;
  background-position: -0% 0%;
  /* background-repeat: no-repeat; */
  background-position: center;
  z-index: 2;
  width: 100%;
  height: 100%;
}
.background-planprice-sec1-light {
  background-image: url(../../assets/images/Newlandindpage/lightmode/bg-planprice-light.png);
  background-size: cover;
  background-position: -0% 0%;
  /* background-repeat: no-repeat; */
  background-position: center;
  z-index: 2;
  width: 100%;
  height: 100%;
}
</style>
