<template>
  <Layout>
    <div class="rn-blog-details-area" :class="{
      'background-bg-post1-light': userLogin === true,
      'background-bg-post1': userLogin === false
    }">
      <div class="post-page-banner rn-section-gapTop">
        <div class="container">
          <div class="row">
            <div class="col-lg-8 offset-lg-2">
              <div class="content text-center">
                <div class="page-title">
                  <h1 class="theme-gradient" v-text="post.title" />
                </div>
                <ul class="rn-meta-list">
                  <li>
                    <!-- <Icon name="user" class="size-20" /> -->
                    <router-link to="#">Lorem ipsum</router-link>
                  </li>
                  <!-- <li>
                    <Icon name="calendar" class="size-20" />
                    {{ post.publishedAt }}
                  </li> -->
                </ul>
                <div class="thumbnail alignwide mt--60">
                  <img class="w-100 radius" :src="post.imgdetail" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="blog-details-content pt--60 rn-section-gapBottom">
        <div class="container">
          <div class="row">
            <div class="col-lg-8 offset-lg-2">
              <div class="content">
                <p v-text="post.body" />

                <div class="category-meta">
                  <span class="text">Tags:</span>
                  <div class="tagcloud">
                    <a href="#">Corporate</a>
                    <a href="#">Agency</a>
                    <a href="#">Creative</a>
                    <a href="#">Design</a>
                    <a href="#">Minimal</a>
                    <a href="#">Company</a>
                    <a href="#">Development</a>
                    <a href="#">App Landing</a>
                    <a href="#">Startup</a>
                    <a href="#">App</a>
                    <a href="#">Business</a>
                    <a href="#">Software</a>
                    <a href="#">Landing</a>
                    <a href="#">Art</a>
                  </div>
                </div>

                <!-- Start Contact Form Area  -->
                <div class="rn-comment-form pt--60">
                  <div class="inner">
                    <div class="section-title">
                      <span class="subtitle">Have a Comment?</span>
                      <h2 class="title">Leave a Reply</h2>
                    </div>
                    <form class="mt--40" action="" @submit="sendEmail">
                      <div class="row">
                        <div class="col-lg-6 col-md-12 col-12">
                          <div class="rnform-group">
                            <input type="text" v-model="dataname" placeholder="Name" required />
                          </div>
                          <div class="rnform-group">
                            <input v-model="dataemail" type="email" placeholder="Email" required />
                          </div>
                          <div class="rnform-group">
                            <input v-model="dataweb" type="text" placeholder="Website" required />
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-12">
                          <div class="rnform-group">
                            <textarea v-model="datacomment" placeholder="Comment" required></textarea>
                          </div>
                        </div>

                        <!-- <button @click="sendEmail">aa</button> -->
                        <div class="form-group" v-text="showResult ? result : null" />

                        <div class="col-lg-12">
                          <div class="blog-btn">
                            <button @click="sentdata" class="btn-default">
                              <span>SEND MESSAGE</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <!-- End Contact Form Area  -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '../components/common/Layout'
import Breadcrumb from '../components/elements/breadcrumb/Breadcrumb'
import Icon from '../components/icon/Icon'
import BlogPostMixin from '../mixins/BlogPostMixin'

export default {
  name: 'BlogDetailsPage',
  components: { Icon, Layout, Breadcrumb },
  mixins: [BlogPostMixin],
  data() {
    return {
      blog: {},
      id: this.$route.params.id,
      post: {},
      dataemail: '',
      datacomment: '',
      dataweb: '',
      dataname: '',
      showResult: false,
      result: `<p class="text-danger">**Your Message has been successfully sent. I will contact you soon.</p>`
    }
  },
  methods: {
    getPost(postId) {
      this.post = this.posts.find((item) => item.id == postId)
    },
    sendEmail() {
      this.showResult = true
      console.log(this.showResult)
    },
    senddata() {
      const requiredFields = [this.dataemail, this.datacomment, this.dataweb, this.dataname]

      if (requiredFields.every((field) => !!field)) {
        const binddata = `Name: ${this.dataname}, Email: ${this.dataemail}, Phone: ${this.datacomment}, Subject: ${this.dataweb}}`
        console.log('ค่าข้อมูล input:', binddata)

        // ส่งข้อมูลไปยัง backend หรือทำสิ่งอื่นตามที่คุณต้องการ
        // ...
      } else {
        alert('กรุณากรอกข้อมูลที่จำเป็นให้ครบถ้วน')
        this.showResult = true
      }
    }
  },
  computed: {
    userLogin() {
      return this.$store.state.userLogin
    }
  },
  mounted() {
    this.getPost(this.id)
  }
}
</script>
<style>
.background-bg-post1 {
  background-image: url(../assets/images/Newlandindpage/lightmode/bg-light-detail.png);
  background-size: cover;
  background-position: -0% 0%;
  /* background-repeat: no-repeat; */
  z-index: 10;
  width: 100%;
  height: 10%;
}

.background-bg-post1-light {
  background-image: url(../assets/images/Newlandindpage/lightmode/bg-brandetail.png);
  background-size: 110%;
  background-position: -0% 0%;
  /* background-repeat: no-repeat; */
  z-index: 10;
  width: 100%;
  height: 10%;
}
</style>
