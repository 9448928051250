<template>
  <Layout>
    <Breadcrumb current-url="Accordion" />
  </Layout>
</template>

<script>
export default {
  name: 'BrandCarouselPage'
}
</script>
