<template>
  <div class="row row--15 service-wrapper">
    <div
      class="col-lg-4 col-md-6 col-sm-12 col-12"
      v-for="(service, index) in serviceList"
      :key="index"
    >
      <div v-if="userLogin === false">
        <div
          :class="`service ${serviceStyle} ${checkTextAlign}`"
          style="height: 51vh"
          class="shadowed-border"
          data-aos="fade-up"
          data-aos-delay="70"
        >
          <div>
            <img v-if="userLogin === false" :src="service.image" alt="Service Images" />
            <img
              v-if="userLogin === true"
              style="width: 13%"
              :src="service.image2"
              alt="Service Images"
            />
          </div>
          <div class="inner mt-3">
            <div class="content">
              <h4 class="title LexenMedium">
                <router-link to="/service-details" v-text="service.title" />
              </h4>
              <p class="description LexenExtraLight" v-text="service.description" />
            </div>
          </div>
        </div>
      </div>
      <div v-if="userLogin === true">
        <div
          :class="`service ${serviceStyle} ${checkTextAlign}`"
          style="height: 51vh"
          class="shadowed-border-light"
          data-aos="fade-up"
          data-aos-delay="70"
        >
          <div>
            <img v-if="userLogin === false" :src="service.image" alt="Service Images" />
            <img
              v-if="userLogin === true"
              style="width: 13%"
              :src="service.image2"
              alt="Service Images"
            />
          </div>
          <div class="inner mt-3">
            <div class="content">
              <h4 class="title LexenMedium">
                <router-link to="/service-details" v-text="service.title" />
              </h4>
              <p class="description LexenExtraLight" v-text="service.description" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ServiceFive',
  props: {
    serviceStyle: {
      type: String
    },
    receivedData: {
      type: Boolean
    },
    textAlign: {
      type: String,
      default: 'left'
    }
  },
  data() {
    return {
      serviceList: [
        {
          image: require('@/assets/images/Newlandindpage/noun-house-1076883.svg'),
          image2: require('../../../assets/images/Newlandindpage/lightmode/Group 28013@2x.png'),
          title: 'Accessibility and Convenience',
          description:
            'EV roaming grants electric vehicle drivers seamless access to charging stations operated by multiple providers. This enables them to charge their vehicles conveniently wherever they travel without the need for multiple subscriptions to charging services.'
        },
        {
          image: require('@/assets/images/Newlandindpage/noun-global-insurance-4831257.svg'),
          image2: require('../../../assets/images/Newlandindpage/lightmode/Group 28014@2x.png'),
          title: 'Expanded Charging Coverage',
          description:
            'Various charging operators may have distinct coverage areas, and some locations might have exclusive arrangements with specific operators. EV roaming facilitates access to a more extensive charging network, even in regions where a single operator might have a limited presence.'
        },
        {
          image: require('@/assets/images/Newlandindpage/noun-anxiety-4660868.svg'),
          image2: require('../../../assets/images/Newlandindpage/lightmode/Group 28015@2x.png'),
          title: 'Reduced Range Anxiety',
          description:
            'Range anxiety, the concern of running out of battery power during travel, is alleviated by EV roaming. Drivers can rely on a broader network of charging stations, mitigating fears of being stranded without a suitable place to charge during long journeys.'
        },
        {
          image: require('@/assets/images/Newlandindpage/noun-pricing-1127520.svg'),
          image2: require('../../../assets/images/Newlandindpage/lightmode/Group 28036@2x.png'),

          title: 'Competition and Pricing',
          description:
            'The presence of multiple charging operators stimulates healthy competition, resulting in competitive pricing and improved service quality. EV roaming encourages charging operators to offer attractive pricing packages and enhance their services to attract more users.'
        },
        {
          image: require('@/assets/images/Newlandindpage/noun-advancement-5750290.svg'),
          image2: require('../../../assets/images/Newlandindpage/lightmode/Group 28037@2x.png'),

          title: 'Innovation and Technology  Advancement ',
          description:
            'The involvement of different charging operators in EV roaming fosters innovation and the adoption of new technologies. Operators may invest in faster-charging solutions, intelligent charging options, and improved user experiences to differentiate themselves in the competitive market.'
        },
        {
          image: require('@/assets/images/Newlandindpage/noun-resilience-5370812.svg'),
          image2: require('../../../assets/images/Newlandindpage/lightmode/Group 28038@2x.png'),

          title: 'Resilience and  Redundancy',
          description:
            'Relying solely on a single charging operator creates a single point of failure. In the event of technical issues or downtime, charging services for all users within that network may be disrupted. EV roaming with multiple operators provides redundancy and greater resilience to maintain charging services during such occurrences.'
        },
        {
          image: require('@/assets/images/Newlandindpage/noun-standardization-5136493.svg'),
          image2: require('../../../assets/images/Newlandindpage/lightmode/Group 28051@2x.png'),

          title: 'Interoperability and Standardization',
          description:
            'EV roaming often necessitates using standardized protocols and communication systems among different operators. This drive for interoperability can lead to the establishment of industry-wide standards, simplifying access to charging infrastructure for all electric vehicle drivers.'
        },
        {
          image: require('@/assets/images/Newlandindpage/noun-global-care-4219366.svg'),
          image2: require('../../../assets/images/Newlandindpage/lightmode/Group 28053@2x.png'),

          title: 'Global Travel  Support',
          description:
            'EV roaming is especially advantageous for travelers and tourists, as it ensures that their electric vehicles can be charged in different regions and countries, provided the charging stations are part of the roaming network. This fosters international electric vehicle adoption.'
        },
        {
          image: require('@/assets/images/Newlandindpage/noun-government-286709.svg'),
          image2: require('../../../assets/images/Newlandindpage/lightmode/Group 28138@2x.png'),

          title: 'Government  Support',
          description:
            'Policymakers and governments often promote EV roaming, aligning with their goals of encouraging electric vehicle adoption and sustainable transportation. Offering incentives or mandates for charging operators to participate in roaming agreements can expedite the growth of electric vehicle infrastructure.'
        }
      ]
    }
  },

  computed: {
    checkTextAlign() {
      if (this.textAlign === 'left') return 'text-start'
      else if (this.textAlign === 'center') return 'text-center'
      else if (this.textAlign === 'end') return 'text-end'
      else return ''
    },
    userLogin() {
      return this.$store.state.userLogin
    }
  }
}
</script>

<style>
.gradient-border {
  border-image-slice: 1;
  border: 1px solid rgb(93, 28, 123);
  border-radius: 50px 20px;
}

.shadowed-border::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid rgb(93, 28, 123);
  /* สีขอบสำหรับเงาขาว */
  border-radius: 20px;
  /* ความโค้งขอบสำหรับเงาขาว */
  box-shadow: 0px 0px 10px 5px rgba(64, 64, 64, 0.7);
}

.shadowed-border-light::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 2px solid rgba(229, 229, 229, 0.7);
  /* กำหนดสีและความโปร่งแสงของขอบ */
  border-radius: 22px;
  /* ความโค้งขอบสำหรับเงาขาว */
}
</style>
