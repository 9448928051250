<template>
  <i :class="['size-' + size, iconClass]" v-html="iconSvg(name)">
  </i>
</template>

<script>
import feather from 'feather-icons'

export default {
  name: 'Icon',
  props: {
    name: {
      type: String,
      required: true
    },
    size: {
      type: String | Number,
      default: 16
    },
    iconClass: {
      type: String,
      default: ''
    }
  },
  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg()
    }
  }
}
</script>