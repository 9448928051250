<template>
  <div class="">
    <div class="row">
      <div class="col-lg-6 col-md-12" v-if="userLogin === false">
        <img
          class=""
          style="height: 95%"
          src="../../../assets/images/Newlandindpage/New1.png"
          alt=""
        />
      </div>
      <div class="col-lg-6 col-md-12" v-if="userLogin === true">
        <img
          class=""
          style="height: 95%"
          src="../../../assets/images/Newlandindpage/Group_28491.png"
          alt=""
        />
      </div>
      <div class="col-lg-6 col-md-12">
        <div v-for="(service, index) in serviceList" :key="index">
          <div
            :class="` ${cardStyle} ${checkTextAlign}`"
            data-aos="fade-up"
            data-aos-delay="70"
            class=""
          >
            <div class="inner">
              <div class="content">
                <h4 class="title LexenExtraLight pt--140" style="font-size: 20px; width: 80%">
                  <router-link to="/service-details" v-text="service.title" />
                </h4>
                <div class="hrr"></div>
                <!-- <p class="description b1 color-gray mb--0" v-text="service.description" /> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ServiceTwo',
  props: {
    cardStyle: {
      type: String
    },
    textAlign: {
      type: String,
      default: 'left'
    }
  },
  data() {
    return {
      serviceList: [
        {
          image: require('@/assets/images/service/service-01.jpg'),
          title:
            ' P2P connections are direct communication links between devices without intermediaries,  promoting decentralization and often enhancing resilience.'
          // description: 'There are many variations variats  of passages of Lorem Ipsum available.'
        },
        {
          image: require('@/assets/images/service/service-02.jpg'),
          title:
            'Centralized hub connections involve a central  point of control that manages and facilitates communication between devices, offering  efficient management and control over data  flow.'
          // description: 'Passages there are many variatin Lorem Ipsum available majority suffered.'
        },
        {
          image: require('@/assets/images/service/service-03.jpg'),
          title:
            ' Both P2P and centralized hub connections have their advantages and use cases, and the choice between them depends on factors like network architecture, scalability, security requirements, and the specific goals  of the IT  infrastructure being            developed.'
          // description:
          //   'Variations There are many of pass ages of Lorem Ipsum available the majority.'
        }
      ]
    }
  },
  computed: {
    checkTextAlign() {
      if (this.textAlign === 'left') return 'text-start'
      else if (this.textAlign === 'center') return 'text-center'
      else if (this.textAlign === 'end') return 'text-end'
      else return ''
    },
    userLogin() {
      return this.$store.state.userLogin
    }
  }
}
</script>

<style>
.shadowed-border2::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 0.9px solid rgb(51, 14, 69);
  /* สีขอบสำหรับเงาขาว */
  border-radius: 6px;
  /* ความโค้งขอบสำหรับเงาขาว */
  box-shadow: 0px 0px 10px 5px rgba(45, 45, 45, 0.7);
}

.hrr {
  top: 864px;
  left: 664px;
  width: 550px;
  height: 0px;
  /* UI Properties */
  border: 1px solid var(--unnamed-color-707070);
  border: 1px solid #707070;
  opacity: 0.51;
  margin-top: 5%;
}
</style>
