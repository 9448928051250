<template>
  <Layout header-class="header-left-align " nav-alignment="left">
    <div class="path-layout text-center">
      <img src="../../assets/images/Newlandindpage/Path_66842.svg" alt="" />
    </div>
    <!-- <img class="" src="../../assets/images/Newlandindpage/Rectangle 9944.svg" /> -->
    <!-- Start Slider Area -->
    <!-- #section1 -->
    <div class="slider-area background-sec-1 slider-style-1 bg-transparent height-850">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="inner text-center">
              <h4 class="fw-lighter" data-aos="fade-up" data-aos-delay="100">What is EVThing</h4>
              <img
                class="light-icon mt-4"
                src="../../assets/images/Newlandindpage/Group_27905.svg"
                alt="Sun images"
              />
              <!-- <h1
                  class="title theme-gradient display-two"
                  data-aos="fade-up"
                  data-aos-delay="150"
                  >
                  EVthing <br />
                  <vue-typed-js class="justify-content-center"
                  :strings="['Finance.', 'Consulting.', 'Agency.']"
                  :loop="true"
                  :type-speed="80"
                  :start-delay="1000"
                  :back-speed="50">
                  <b class="is-visible theme-gradient typing">Finance.</b>
                </vue-typed-js>
              </h1> -->

              <h2 class="mt-5" data-aos="fade-up" data-aos-delay="200">
                Ev Roaming Hub & Clearing House<br />
                Platform & Software as a Service
              </h2>
              <br />
              <h4 class="fw-light" data-aos="fade-up" data-aos-delay="200">Lorem Ipsum</h4>
              <br />
              <br />

              <div class="button-group" data-aos="fade-up" data-aos-delay="250">
                <router-link class="btn-default btn-medium round btn-icon" to="#">
                  PACKAGE
                  <!-- <Icon
                            name="arrow-right"
                            size="20"
                            icon-class="icon"
                            /> -->
                </router-link>

                <router-link class="btn-default btn-medium btn-border round btn-icon" to="/contact">
                  Contact Us
                  <!-- <Icon
                                        name="arrow-right"
                                        size="20"
                                        icon-class="icon"
                                    /> -->
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Slider Area -->
    <div class="container p-5 bg-black bg-darken-lg text-white mw-100">
      <div class="row pt-5 mt-5">
        <div class="col text-end">
          <h4>
            We are creative digital agency <br />
            working for our company brands
          </h4>
        </div>
        <div class="col-2"></div>
        <div class="col text-start">
          <div>
            <span
              >Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum <br />Lorem
              Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum <br />Lorem Ipsum
              Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum <br />Lorem Ipsum Lorem
              Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum <br />
            </span>
            <br />
            <a class="text-decoration-underline">View more</a>
          </div>
        </div>
      </div>
    </div>

    <Separator />

    <!-- Start About Area -->

    <!-- End About Area -->

    <Separator />
    <!-- #section2 -->
    <!-- Start Service Area -->
    <div class="rn-service-area">
      <div class="background-sec-2">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <SectionTitle
                text-align="center"
                subtitle="Lorem Ipsum"
                title="What are the benefits of EV Roaming?"
                description="Electric Vehicle (EV) roaming offers a multitde of significant advantage over reliance on a single charging operator These benefit extend to eletric vehicle users, the charging infrastructrure network, and society, and society at large. Here are the key adventages of EV roaming"
                data-aos="fade-up"
                class="mt-5"
              />
            </div>
          </div>
          <div>
            <ServiceFive service-style="gallery-style" text-align="left" />
          </div>

          <br />
          <div class="text-center mt-5">
            <p>
              In summary, EV roaming provides numerous benefits, including enhanced convenience,
              expanded charging coverage, increased competition and innovation, improved resilience,
              and enhanced interoperability. These advantages contribute to the overall growth and
              adoption of electric vehicles, advancing the transition to a more sustainable and
              environmentally friendly transportation system.
            </p>
          </div>
          <!-- #section3 -->
          <br />
        </div>
      </div>
      <SectionTitle
        class="mt-5"
        text-align="center"
        subtitle="Lorem Ipsum"
        title="Our services."
        description=""
        data-aos="fade-up"
      />

      <br />
      <div class="rwt-split-area no-radius">
        <div class="wrapper">
          <Split :split-data="splitOneData" />
        </div>
        <div class="rwt-split-area">
          <div class="wrapper">
            <Split :split-data="splitTwoData" :split-style="4" />
          </div>
        </div>
      </div>
      <div class="rwt-split-area no-radius">
        <div class="wrapper">
          <Split :split-data="splitthreeData" />
        </div>
        <div class="rwt-split-area">
          <div class="wrapper">
            <Split :split-data="splitTfourData" :split-style="4" />
          </div>
        </div>
      </div>
    </div>

    <div class="rwt-about-area rn-section-gap">
      <div class="container">
        <div class="row row--30 align-items-center">
          <div class="col-lg-5">
            <div class="thumbnail" data-aos="fade-up" data-aos-delay="250">
              <img
                class="w-100"
                src="../../assets/images/Newlandindpage/Group_27926.png"
                alt="About Images"
              />
            </div>
          </div>
          <div class="col-lg-1"></div>
          <div class="col-lg-6 mt_md--40 mt_sm--40">
            <div class="content">
              <div class="section-title">
                <SectionTitle
                  text-align="left"
                  subtitle="Lorem Ipsum"
                  title="Concerned Parties"
                  description=""
                  data-aos="fade-up"
                />
                <span class="">
                  <p class="d-flex">
                    <img
                      class="p-2"
                      src="../../assets/images/Newlandindpage/business-outline.svg"
                      alt="Charge Point Operators"
                    />
                    <span class="pt-2"> Charge Point Operators </span>
                  </p>
                  <p class="d-flex">
                    <img
                      class="p-2"
                      src="../../assets/images/Newlandindpage/business-outline.svg"
                      alt="e-Mobility Service Providers"
                    />
                    <span class="pt-2"> e-Mobility Service Providers </span>
                  </p>
                  <p class="d-flex">
                    <img
                      class="p-2"
                      src="../../assets/images/Newlandindpage/business-outline.svg"
                      alt="Car Manufacturers"
                    />
                    <span class="pt-2"> Car Manufacturers </span>
                  </p>
                  <p class="d-flex">
                    <img
                      class="p-2"
                      src="../../assets/images/Newlandindpage/business-outline.svg"
                      alt="General EV Users"
                    />
                    <span class="pt-2"> General EV Users </span>
                  </p>
                </span>

                <!-- <h2 class="ti/tle " data-aos="fade-up" data-aos-delay="280">
                                    Concerned Parties<br>
                                    <vue-typed-js :strings="['Finance.', 'Consulting.', 'Agency.']"
                                                  :loop="true"
                                                  :type-speed="80"
                                                  :start-delay="1000"
                                                  :back-speed="50">
                                        <b class="is-visible theme-gradient typing">Finance.</b>
                                    </vue-typed-js>
                                </h2> -->
                <!-- <p data-aos="fade-up" data-aos-delay="310">
                                    Far far away, behind the word mountains, far from the countries Vokalia and
                                    Consonantia, there live the blind texts. Separated they live in Bookmarksgrove right
                                    at the coast of the Semantics, a large language ocean. They live in Bookmarksgrove
                                    right at the coast of the Semantics, a large language ocean.
                                </p> -->
                <!-- <p data-aos="fade-up" data-aos-delay="340">
                                    A small river named Duden flows by their place and supplies it with the necessary
                                    regelialia. It is a paradisematic country, in which roasted parts of sentences. A
                                    small river named Duden flows by their place and supplies it with the necessary
                                    regelialia. It is a paradisematic country, in which roasted parts of sentences
                                </p> -->
                <!-- <div class="read-more-btn mt--40" data-aos="fade-up" data-aos-delay="370">
                                    <router-link class="btn-default" to="/about">
                                        <span>More About Us</span>
                                    </router-link>
                                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center path-layout-1">
        <img src="../../assets/images/Newlandindpage/Path_66842.svg" alt="" />
      </div>
    </div>
    <!-- #section4 -->
    <div class="rn-service-area rn-section-gap background-sec-3">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <p class="text-center">Benefits of Connecting to the EV Roaming Hub Service</p>
            <SectionTitle
              text-align="center"
              title="Implementation / Features"
              description="Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum  Lorem ipsum Lorem ipsum Lorem ipsum "
              data-aos="fade-up"
            />
          </div>
        </div>
        <ServiceOne
          service-style="service__style--1 icon-circle-style"
          text-align="center"
          icon-size="39"
        />
      </div>
      <div class="read-more-btn text-center mt--40" data-aos="fade-up" data-aos-delay="370">
        <!-- <router-link class="btn-default" to="/about">
                    <span>Read more</span>
                </router-link> -->
      </div>
    </div>
    <!-- #section5 -->
    <div class="rwt-split-area">
      <div class="wrapper">
        <Split :split-data="splitsection5Data" :split-style="4" />
      </div>
    </div>
    <!--  -->
    <!-- #section6 -->
    <AboutFour :image="require(`../../assets/images/Newlandindpage/shutterstock_323846798.png`)" />
    <!--  -->
    <!-- #section7 -->
    <div class="rwt-brand-area rn-section-gapBottom sizesection7">
      <div class="background-sec-4">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 mt-lg-5">
              <SectionTitle
                text-align="center"
                subtitle="Lorem ipsum "
                title="Meet our customers"
                data-aos="fade-up"
              />
            </div>
          </div>
          <div class="row mt-5">
            <!-- Brand!!!!! -->
            <div class="col-lg-4 mt-3"></div>
            <Brand class="mt-3" :brand-list="brandList" :brand-style="2" />
          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <!-- #section8 -->
    <div class="rwt-timeline-area rn-section-gap">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <SectionTitle
              text-align="center"
              subtitle="Working Process"
              title="Business Working Process."
              description="We provide company and finance service for <br /> startups and company business."
            />
          </div>
        </div>
        <div class="row mt_dec--20">
          <div class="col-lg-12">
            <Timeline :timeline-data="timelineData" />
          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <!-- #section9 -->
    <AboutSection9
      :image="require(`@/assets/images/Newlandindpage/phone-car-with-map-screen.png`)"
    />

    <!-- Start Team Area -->
    <!-- <div class="rwt-team-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle text-align="center" subtitle="Our Experts." title="Our Company Experts."
                            description="We provide company and finance service for <br /> startups and company business."
                            data-aos="fade-up" />
                    </div>
                </div>
                <div class="row row--15">
                    <div class="col-lg-4 col-md-6 col-12" v-for="(teamMember, index) in teamData" :key="index">
                        <Team :team-member="teamMember" :team-style="2" />
                    </div>

                </div>
            </div>
        </div> -->
    <!-- End Team Area -->

    <Separator />

    <!-- Start Mission Area -->
    <!-- sections10 -->
    <div class="rn-company-mission-are rn-section-gap background-sec-10">
      <div class="container">
        <div class="row row--30">
          <div class="col-lg-6">
            <div class="mission-title">
              <h2 class="title" data-aos="fade-up">
                How can we help? <br />
                <vue-typed-js
                  class="theme-gradient"
                  :strings="['Finance.', 'Consulting.', 'Agency.']"
                  :loop="true"
                  :type-speed="80"
                  :start-delay="1000"
                  :back-speed="50"
                >
                  <b class="is-visible typing"></b>
                </vue-typed-js>
              </h2>
              <p data-aos="fade-up">
                Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum -LoremLorem ipsum Lorem ipsum Lorem
                ipsum Lorem ipsum -LoremLorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum -LoremLorem
                ipsum Lorem ipsum Lorem ipsum Lorem ipsum -Lorem?
              </p>
              <p data-aos="fade-up">
                Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum -LoremLorem ipsum Lorem ipsum Lorem
                ipsum Lorem ipsum -LoremLorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum -Lorem
              </p>
              <div class="read-more-btn mt--50" data-aos="fade-up">
                <router-link class="btn-default btn-icon" to="#">
                  Read More
                  <!-- <Icon
                                        name="arrow-right"
                                        size="20"
                                        icon-class="icon"
                                    /> -->
                </router-link>
              </div>
            </div>
          </div>
          <div class="col-lg-6 mt_md--30 mt_sm--30">
            <Accordion id="accordionExampleOne" data-aos="fade-up" data-aos-delay="60" />
          </div>
        </div>
      </div>
    </div>
    <!--  -->

    <Separator />

    <!-- Start Testimonial Area -->
    <!-- <div class="rwt-testimonial-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle text-align="center" subtitle="Client Feedback" title="Our Awesome Client."
                            description="We provide company and finance service for <br /> startups and company business."
                            data-aos="fade-up" />
                    </div>
                </div>
                <Testimonial :testimonial="testimonialData5" :testimonial-style="5" data-aos="fade-up" />
            </div>
        </div> -->
    <!-- End Testimonial Area -->

    <Separator />

    <!-- Start Blog Area -->
    <!-- <div class="blog-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle text-align="center" subtitle="Latest News" title="Our Latest News."
                            description="We provide company and finance service for <br /> startups and company business."
                            data-aos="fade-up" />
                    </div>
                </div>
                <div class="row row--15">
                    <div class="col-lg-4 col-md-6 col-sm-12 col-12 mt--30" data-aos="slide-up" data-aos-duration="800"
                        :data-aos-delay="100 + index" v-for="(blog, index) in posts" :key="index" v-if="index < 3">
                        <BlogPost :blog="blog" />
                    </div>
                </div>
            </div>
        </div> -->
    <!-- End Blog Area -->
  </Layout>
</template>

<script>
import Layout from '../../components/common/Layout'
import Separator from '../../components/elements/separator/Separator'
import Icon from '../../components/icon/Icon'
import SectionTitle from '../../components/elements/sectionTitle/SectionTitle'
import ServiceFive from '../../components/elements/service/ServiceFive'
import Team from '../../components/elements/team/Team'
import BlogPost from '../../components/blog/BlogPost'
import BlogPostMixin from '../../mixins/BlogPostMixin'
import CallToAction from '../../components/elements/callToAction/CallToAction'
import Testimonial from '../../components/elements/testimonial/Testimonial'
import Accordion from '../../components/elements/accordion/Accordion'
import Split from '../../components/elements/split/Split'
import ServiceOne from '../../components/elements/service/ServiceOne'
import AboutFour from '../../components/elements/about/AboutFour'
import AboutSection9 from '../../components/elements/about/AboutSection9.vue'
import Brand from '../../components/elements/brand/Brand'
import Timeline from '../../components/elements/timeline/Timeline'

export default {
  name: 'BusinessConsulting2',
  components: {
    Accordion,
    AboutSection9,
    Timeline,
    Brand,
    ServiceOne,
    Testimonial,
    CallToAction,
    BlogPost,
    Team,
    Split,
    ServiceFive,
    SectionTitle,
    Icon,
    Separator,
    AboutFour,
    Layout
  },
  mixins: [BlogPostMixin],
  data() {
    return {
      teamData: [
        {
          image: 'team-01',
          name: 'Sr Janen Sara',
          designation: 'Sr Product Designer',
          location: 'CO Miego, AD, USA',
          description: 'Yes, I am a product designer. I have a passion for product design.',
          socialNetwork: [
            {
              icon: 'facebook',
              url: '#'
            },
            {
              icon: 'twitter',
              url: '#'
            },
            {
              icon: 'instagram',
              url: '#'
            }
          ]
        },
        {
          image: 'team-02',
          name: 'Afsana Nila',
          designation: 'App Developer',
          location: 'Bangladesh',
          description: 'Yes, I am a product designer. I have a passion for product design.',
          socialNetwork: [
            {
              icon: 'facebook',
              url: '#'
            },
            {
              icon: 'twitter',
              url: '#'
            },
            {
              icon: 'instagram',
              url: '#'
            }
          ]
        },
        {
          image: 'team-03',
          name: 'Afanan Sifa',
          designation: 'Accounts Manager',
          location: 'Poland',
          description: 'Yes, I am a product designer. I have a passion for product design.',
          socialNetwork: [
            {
              icon: 'facebook',
              url: '#'
            },
            {
              icon: 'twitter',
              url: '#'
            },
            {
              icon: 'instagram',
              url: '#'
            }
          ]
        }
      ],
      testimonialData5: [
        {
          form: 'Poland',
          description:
            'What I am primarily looking for with new projects is a fit on both a visual and aesthetic level as well as on a personal level with the client.',
          name: 'John Due',
          subtitle: 'App Developer',
          image: 'testimonial-dark-01'
        },
        {
          form: 'Germany',
          description:
            'What I am primarily looking for with new projects is a fit on both a visual and aesthetic level as well as on a personal level with the client.',
          name: 'John Due',
          subtitle: 'App Developer',
          image: 'testimonial-dark-02'
        },
        {
          form: 'USA',
          description:
            'What I am primarily looking for with new projects is a fit on both a visual and aesthetic level as well as on a personal level with the client.',
          name: 'Janen',
          subtitle: 'App Developer',
          image: 'testimonial-dark-03'
        }
      ],
      splitOneData: {
        image: 'Group_27921.png',
        title: 'Provide software',
        description:
          'Provide software/platform integration for  Charge Point Operators (CPOs) and e-Mobility Service Providers (eMSPs)',
        list: [],
        btn: {
          text: '!/',
          link: '/contact'
        }
      },
      splitTwoData: {
        image: 'Group_27919.png',
        title: 'Provide platform design',
        description: `Provide platform design and consultancy services for all concerned parties`
        // counterData: [
        //     {
        //         number: 199,
        //         title: 'Happy Clients',
        //     },
        //     {
        //         number: 575,
        //         title: 'Employees',
        //     },
        //     {
        //         number: 69,
        //         title: 'Useful Programs',
        //     },
        //     {
        //         number: 500,
        //         title: 'Useful Programs',
        //     }
        // ]
      },
      splitthreeData: {
        image: 'Group_27920.png',
        title: 'EV trip planner platform',
        description: 'Provide an EV trip planner platform for general EV users',
        list: [],
        btn: {
          text: '!/',
          link: '/contact'
        }
      },
      splitTfourData: {
        image: 'Group_27921.png',
        title: 'EV trip planner API',
        description: `Provide an EV trip planner API for all concerned parties`
        // counterData: [
        //     {
        //         number: 199,
        //         title: 'Happy Clients',
        //     },
        //     {
        //         number: 575,
        //         title: 'Employees',
        //     },
        //     {
        //         number: 69,
        //         title: 'Useful Programs',
        //     },
        //     {
        //         number: 500,
        //         title: 'Useful Programs',
        //     }
        // ]
      },
      splitsection5Data: {
        image: 'Group_27923.svg',
        title: 'Covering in ASEAN countries and beyond',
        description: `Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsumLorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsumLorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsumLorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum`,
        counterData1: [
          {
            number: 406,
            title: ' EA ANYWHERE'
          },
          {
            number: 252,
            title: 'EV Station PluZ'
          }
        ],
        counterData2: [
          {
            number: 199,
            title: 'EVOLT'
          },
          {
            number: 184,
            title: 'SHARGE'
          }
        ]
      },
      brandList: [
        {
          image: require(`@/assets/images/Newlandindpage/Group_2888.svg`)
        },
        {
          image: require(`@/assets/images/Newlandindpage/Group_26970.svg`)
        },
        {
          image: require(`@/assets/images/Newlandindpage/Group_26974.svg`)
        },
        {
          image: require(`@/assets/images/Newlandindpage/Group_26978.svg`)
        },
        {
          image: require(`@/assets/images/Newlandindpage/Group_26986.svg`)
        },
        {
          image: require(`@/assets/images/Newlandindpage/Group_26993.svg`)
        },
        {
          image: require(`@/assets/images/Newlandindpage/Group_26999.svg`)
        },
        {
          image: require(`@/assets/images/Newlandindpage/MaskGroup 2881.svg`)
        },
        {
          image: require(`@/assets/images/Newlandindpage/MaskGroup 2880.svg`)
        },
        {
          image: require(`@/assets/images/Newlandindpage/MaskGroup 2883.svg`)
        },
        {
          image: require(`@/assets/images/Newlandindpage/MaskGroup 2886.svg`)
        },
        {
          image: require(`@/assets/images/Newlandindpage/MaskGroup 2887.svg`)
        }
      ],
      timelineData: [
        {
          id: '1',
          date: 'Step-1',
          title: 'Lorem ipsum',
          description:
            'Lorem ipsumLorem ipsumLorem ipsumLorem ipsumLorem ipsumLorem ipsumLorem ipsumLorem ipsumLorem ipsum',
          image: 'tobias-reich-sC5ZgFyGB4Y-unsplash.png',
          workingStep: [
            {
              stepTitle: 'Lorem ipsum',
              stepDescription: 'Lorem ipsumLorem ipsumLorem ipsumLorem ipsumLorem ipsum'
            },
            {
              stepTitle: 'Lorem ipsum',
              stepDescription: 'Lorem ipsumLorem ipsumLorem ipsumLorem ipsumLorem ipsum'
            }
          ]
        },
        {
          id: '2',
          date: 'Step-2',
          title: 'Our compnay working process to present.',
          description:
            'Lorem ipsum dolor sit amet at — consectetur adipisicing elit. Hic, expedita. Incidunt laboriosam.',
          image: 'milad-fakurian-PjG_SXDkpwQ-unsplash.png',
          workingStep: [
            {
              stepTitle: 'Follow this process',
              stepDescription: 'Lorem ipsum dolor sit amet at consectetur adipisicing'
            },
            {
              stepTitle: 'Working experience',
              stepDescription: 'Lorem ipsum dolor sit amet at consectetur adipisicing'
            }
          ]
        }
      ]
    }
  }
}
</script>
<!-- <style>
.path-layout {
  transform: translate(209px, -14px);
  position: absolute;
  width: 74vw;
  /* z-index: 1; */
}
.path-layout-1 {
  transform: translate(0px, 55px);
  /* position: absolute; */
  /* z-index: 1; */
}
.sizesection7 {
  height: 100vh;
  width: 100%;
}
.section7-layout {
  margin-top: 25%;
}
.background-sec-1 {
  background-image: url(../../assets/images/Newlandindpage/Rectangle\ 9944.svg);
  background-size: cover;
  background-position: -0% 0%;
  background-repeat: no-repeat;
  /* background-position: right; */
  z-index: 2;
  width: 100%;
  height: 100%;
}
.background-sec-2 {
  background-image: url(../../assets/images/Newlandindpage/Rectangle\ 15237.svg);
  background-size: cover;
  background-position: -0% 70%;
  background-repeat: no-repeat;
  /* background-position: right; */
  z-index: 2;
  width: 100%;
  height: 100%;
}
.background-sec-3 {
  background-image: url(../../assets/images/Newlandindpage/Rectangle\ 10301.svg);
  background-size: cover;
  background-position: -0% 70%;
  background-repeat: no-repeat;
  /* background-position: right; */
  z-index: 2;
  width: 100%;
  height: 100%;
}
.background-sec-4 {
  background-image: url(../../assets/images/Newlandindpage/Rectangle\ 10301.svg);
  background-size: cover;
  background-position: -0% 50%;
  background-repeat: no-repeat;
  /* background-position: right; */
  z-index: 2;
  width: 100%;
  height: 100%;
}
.background-sec-10 {
  background-image: url(../../assets/images/Newlandindpage/Rectangle\ 10119.svg);
  background-size: cover;
  background-position: -0% 50%;
  background-repeat: no-repeat;
  /* background-position: right; */
  z-index: 2;
  width: 100%;
  height: 100%;
}
</style> -->
