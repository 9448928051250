<template>
  <div class="row row--15 service-wrapper">
    <div
      class="col-lg-4 col-md-6 col-sm-6 col-12"
      v-for="(service, index) in serviceList"
      :key="index"
    >
      <div
        :class="`service ${serviceStyle} ${checkTextAlign}`"
        data-aos="fade-up"
        data-aos-delay="70"
      >
        <div class="image">
          <img :src="service.image" alt="Service Images" />
        </div>
        <div class="content">
          <h4 class="title w-600">
            <router-link to="/service-details " class="LexenSemiBold" v-text="service.title" />
          </h4>
          <div>
            <p class="description b1 mb--0 LexenExtraLight" v-text="service.description" />
          </div>
        </div>
      </div>
    </div>
    <div class="text-center mt-5">
      <router-link class="btn-default btn-icon mt-5 LexenRegular" to="/Implementation">
        Read More
      </router-link>
    </div>
  </div>
</template>

<script>
import Icon from '../../icon/Icon'

export default {
  name: 'ServiceOne',
  components: { Icon },
  props: {
    serviceStyle: {
      type: String
    },
    iconSize: {
      type: String | Number,
      default: 62
    },
    textAlign: {
      type: String,
      default: 'left'
    }
  },
  data() {
    return {
      serviceList: [
        {
          image: require('@/assets/images/Newlandindpage/Pic1@2x.png'),
          title: 'Configuration of service connection',
          description:
            'Less tedious work is needed when either a single (CPO/eMSP) or dual (CPO & eMSP) connection is integrated into the hub; otherwise, the workload multiplies based on the number of parties being integrated.'
        },
        {
          image: require('@/assets/images/Newlandindpage/Group 26847@2x.png'),
          title: 'Provision of physical servers',
          description:
            'Adding more parties does necessarily make the IT staff happier, as connecting a greater number of parties often means more processes that demand multiple VMs or containers, resulting in higher investment costs.'
        },
        {
          image: require('@/assets/images/Newlandindpage/Group 26848@2x.png'),
          title: 'Data integration to  existing EVSMS',
          description:
            'Multiple connections from various parties necessitate repetitive data integration into your Electric Vehicle Charging Station Management System (EVCSMS), which includes elevated maintenance tasks as well.'
        }
      ]
    }
  },
  computed: {
    checkTextAlign() {
      if (this.textAlign === 'left') return 'text-start'
      else if (this.textAlign === 'center') return 'text-center'
      else if (this.textAlign === 'end') return 'text-end'
      else return ''
    },
    userLogin() {
      return this.$store.state.userLogin
    }
  }
}
</script>
