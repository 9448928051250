import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const messages = {
  en: require('../i18n/store/en.json'),
  th: require('../i18n/store/th.json')
  // Add more languages as needed
}

const i18n = new VueI18n({
  locale: 'en', // Default locale
  messages
})

export default i18n
