<template>
  <div class="about-area about-style-4 rn-section-gap">
    <div class="container">
      <div class="row">
        <div class="p-5">
          <img size="" class="img-fluid" src="../../../assets/images/Newlandindpage/Group 27919@2x.png" alt="" />
          <div class="row m-5 LexenSemiBold" style="font-size: 40px">
            <h3 style="font-size: 40px" class="title LexenSemiBold text-secondary">
              Before entering
              <strong :class="{ 'text-black': userLogin, 'text-white': !userLogin }">
                the charging station,
              </strong>
              <br />
              <strong :class="{ 'text-black': userLogin, 'text-white': !userLogin }">look for</strong>
              the roaming signage.
            </h3>
          </div>
          <div class="row">
            <div class="col flx-1">
              <img class="protact-logoo" src="../../../assets//images/Newlandindpage/Group_27922.svg" alt="" />
              <div class="title-wrapper">
                <h4 class="title">The signage displayed at the charging station</h4>
                <p class="text">
                  This ubiquitous signage should easily be seen when users enter the station's
                  proximity.
                </p>
              </div>
            </div>
            <div class="col flx-1">
              <img class="protact-logoo" src="../../../assets//images/Newlandindpage/Group_27922.svg" alt="" />
              <div class="title-wrapper">
                <h4 class="title">The signage displayed on a mobile application</h4>
                <p class="text">
                  Users can search for a roaming charging station with full interoperability on a
                  preferred application.
                </p>
              </div>
            </div>
          </div>
          <div class="about-btn mt--30 d-flex justify-content-center gap-5">
            <router-link class="btn-default round btn-icon LexenMedium" to="#">About the signage</router-link>
            <router-link class="btn-default btn-border round btn-icon LexenMedium" to="#">
              Check in
              <Icon name="arrow-right" size="20" icon-class="icon" />
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '../../icon/Icon'
import Video from '../video/Video'

export default {
  name: 'AboutFour',
  components: { Video, Icon },
  props: {
    image: {}
  },
  data() {
    return {
      videoData: [
        {
          thumb: this.image,
          src: require(`../../../assets/images/Newlandindpage/lightmode/certificate.png`)
        }
      ]
    }
  },
  computed: {
    userLogin() {
      return this.$store.state.userLogin
    }
  }
}
</script>
<style>
.protact-logoo {
  margin-top: -19%;
  margin-right: 2%;
}

.flx-1 {
  display: flex;
}
</style>
