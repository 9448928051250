<template>
  <!-- Start Google MAp -->
  <div class="google-map-style-1">
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3874.123456789012!2d100.5738685153985!3d13.773412608145923!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMTPCsDQ2JzI0LjMiTiAxMDDCsDM0JzI1LjkiRQ!5e0!3m2!1sth!2sth!4v1630777307491!5m2!1sth!2sth"
      width="600"
      height="550"
      style="border: 0"
      allowfullscreen=""
      loading="lazy"
      title="Google Map"
    ></iframe>
  </div>
  <!-- End Google MAp -->
</template>

<script>
export default {
  name: 'GoogleMap'
}
</script>
