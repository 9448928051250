<template>
  <div v-if="splitStyle === 12" class="rn-splite-style my-lg-5">
    <!-- <div class="container">
      <div class="row" style="background-color: #0f0f0f">
        <div class="centered-text text-left p-5">
          <h5>Our services</h5>
          <h4 class="title mb-0" style="font-size: 40px">{{ splitData.title }}</h4>
          <h4 class="title" style="font-size: 40px">{{ splitData.title2 }}</h4>
          <div class="hrclone"></div>
          <p class="description LexenExtraLight" style="font-size: 20px">
            {{ splitData.description }}
          </p>
        </div>
        <div class="thumbnail image-left-content">
          <img
            v-if="receivedData === false"
            :src="require(`@/assets/images/Newlandindpage/${splitData.image}`)"
            alt="Split Image"
          />
          <img
            v-if="receivedData === true"
            :src="require(`@/assets/images/Newlandindpage/lightmode/${splitData.image1}`)"
            alt="Split Image"
          />
        </div>
      </div>

      <div
        class="text-center"
        :class="{ ' d-none': userLogin === false, 'd-block': userLogin === true }"
      >
        <img class="w-75" src="../../../assets/images/Newlandindpage/path/line_path.png" />
      </div>
    </div> -->
    <div class="container">
      <div class="row poitiontopclone2">
        <div class="centered-text position-absolute z-3 p-5">
          <h4 class="title mb-0" style="color: #86868b; font-size: 40px">{{ splitData.title }}</h4>
          <h4 class="title" style="color: white; font-size: 60px">{{ splitData.title2 }}</h4>
          <div class="hrclone2"></div>
          <h5 class="text-white">Our services</h5>
        </div>
        <div class="thumbnail image-left-content position-relative">
          <img
            v-if="receivedData === false"
            :src="require(`@/assets/images/Newlandindpage/${splitData.image}`)"
            alt="Split Image"
          />
          <img
            v-if="receivedData === true"
            :src="require(`@/assets/images/Newlandindpage/lightmode/${splitData.image1}`)"
            alt="Split Image"
          />
        </div>
        <div class="d-flex text-left">
          <p
            class="description LexenExtraLight p-5"
            style="font-size: 20px"
            :class="{
              'bg-descripstion-light  ': userLogin,
              'bg-descripstion-dark ': !userLogin
            }"
          >
            {{ splitData.description }}
          </p>
        </div>
      </div>

      <!-- <div
        class="text-center"
        :class="{ ' d-none': userLogin === false, 'd-block': userLogin === true }"
      >
        <img class="w-75" src="../../../assets/images/Newlandindpage/path/line_path.png" />
      </div> -->
    </div>
  </div>
</template>

<script>
import Button from '../button/Button'
import Counter from '../counterUp/Counter'

export default {
  name: 'Split',
  components: { Counter, Button },
  props: {
    splitStyle: {
      type: Number,
      default: 1
    },
    splitData: {},
    receivedData: {
      type: Boolean
    }
  },
  computed: {
    userLogin() {
      return this.$store.state.userLogin
    }
  }
}
</script>
<style scoped>
.background-split {
  background-image: url(../../../assets/images/Newlandindpage/lightmode/bg-split.png);
  background-size: cover;
  background-position: -0% 0%;
  /* background-repeat: no-repeat; */
  background-position: center;
  z-index: 2;
  width: 100%;
  height: 100%;
}
.hrclone {
  top: 8648px;
  left: 664px;
  height: 0px;
  /* UI Properties */
  border: 1px solid var(--unnamed-color-707070);
  border: 1px solid #3a3a3a;
  opacity: 0.51;
  margin-top: 4%;
  margin-bottom: 4%;
}
.image-container {
  position: relative;
  width: 100%; /* ปรับขนาดตามที่ต้องการ */
}

.image {
  width: 50%;
  height: auto;
}

.text-over-image {
  position: absolute;
  transform: translate(0%, 0%);
  z-index: 1; /* ปรับตามลำดับที่คุณต้องการให้ข้อความอยู่ข้างบนรูปภาพ */
  background-color: rgba(255, 255, 255, 0.7); /* ปรับสีพื้นหลังข้อความตามที่ต้องการ */
  padding: 10px;
}
.hrclone2 {
  top: 8648px;
  left: 664px;
  height: 0px;
  width: 10%;
  /* UI Properties */
  border: 1px solid var(--unnamed-color-707070);
  border: 1px solid #3a3a3a;
  opacity: 0.51;
  margin-top: 2%;
  margin-bottom: 2%;
}

.poitiontopclone2 {
  padding-top: 7%;
}
</style>
