<template>
  <Layout header-class="header-left-align " nav-alignment="left">
    <!-- <div class="path-layout text-center">
      <img src="../../assets/images/Newlandindpage/Path_66842.svg" />
    </div> -->
    <!-- <img class="" src="../../assets/images/Newlandindpage/Rectangle 9944.svg" /> -->
    <!-- Start Slider Area -->
    <!-- #section1 -->
    <!-- {{ store.state }} -->
    <!-- <div @click="$emit('test',1234)">
      aa
    </div> -->
    <!-- <p>Count from Pinia Store: {{ count }} {{ test2 }}</p>
    <button @click="increment">Increment</button> -->
    <!-- <a
      class="hidebutton"
      :class="{ 'text-black': hasNewClass === false, 'text-white': hasNewClass === true }"
   x"
    >
      .
    </a> -->

    <div
      v-if="userLogin === false"
      class="slider-area slider-style-1 background-sec-1 bg-transparent height-850"
      id="section1"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="inner text-center">
              <h4 class="fw-lighter" data-aos="fade-up" data-aos-delay="100">
                {{ $t('Home.section1.title1') }}
              </h4>
              <div class="text-white">
                <img
                  class="light-icon mt-4"
                  data-aos="fade-up"
                  style="width: 674px"
                  data-aos-delay="100"
                  src="../../assets/images/Newlandindpage/logo_utopia.png"
                  alt="Sun images"
                />
              </div>

              <h2 class="mt-5" data-aos="fade-up" data-aos-delay="200">
                EV Roaming Hub & Clearing House<br />
                Platform & Software as a Service
              </h2>
              <br />
              <h5 class="Lexenthin" style="font-size: 22px" data-aos="fade-up" data-aos-delay="200">
                {{ $t('Home.section1.title3') }}
              </h5>
              <br />
              <br />

              <div class="button-group" data-aos="fade-up" data-aos-delay="250">
                <router-link class="btn-default round btn-icon LexenMedium" to="/PlanandPricing">
                  PACKAGE
                </router-link>

                <router-link
                  class="btn-default btn-border round btn-icon LexenMedium"
                  to="/contact"
                >
                  Contact Us
                  <!-- <Icon
                                        name="arrow-right"
                                        size="20"
                                        icon-class="icon"
                                    /> -->
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="userLogin === true"
      class="slider-area slider-style-1 background-sec-1-lightmode bg-transparent height-850"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="inner text-center">
              <h4
                class="fw-lighter"
                style="font-size: 23px"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                We are delighted to welcome you to
              </h4>
              <div class="text-white">
                <img
                  class="light-icon mt-4"
                  data-aos="fade-up"
                  style="width: 674px"
                  data-aos-delay="100"
                  src="../../assets/images/Newlandindpage/newimage/Group 28416@2x.png"
                  alt="Sun images"
                />
              </div>

              <h2 class="mt-5" data-aos="fade-up" data-aos-delay="200" style="font-size: 40px">
                EV Roaming Hub & Clearing House<br />
                Platform & Software as a Service
              </h2>
              <br />
              <h5 class="Lexenthin" style="font-size: 22px" data-aos="fade-up" data-aos-delay="200">
                {{ $t('Home.section2.title1') }}
              </h5>
              <br />
              <br />

              <div class="button-group" data-aos="fade-up" data-aos-delay="250">
                <router-link class="btn-default round btn-icon" to="/PlanandPricing">
                  PACKAGE
                  <!-- <Icon
                            name="arrow-right"
                            size="20"
                            icon-class="icon"
                            /> -->
                </router-link>

                <router-link class="btn-default btn-border round btn-icon" to="/contact">
                  Contact Us
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Slider Area -->
    <div class="container bg-darken-lg text-white mw-100">
      <div class="service-area mb-5 pb-5 rn-section-gapTop">
        <div class="image-container">
          <!-- <img class="" src="../../assets/images/Newlandindpage/2566-10-03 11.12.00.jpg" /> -->

          <!-- sec2 -->
          <div class="image-container">
            <img
              src="../../assets/images/Newlandindpage/imagesec-2new.png"
              alt="Your Image"
              class="image2-1 d-none d-lg-block"
            />
            <img
              src="../../assets/images/Newlandindpage/imagesec-2newphone1.png"
              alt="Your Image"
              class="image2-1 d-lg-none"
            />
            <div
              class=""
              :class="{ 'text-over-image-light': userLogin, 'text-over-image': !userLogin }"
            >
              <div class="row d-none d-lg-flex">
                <div class="col-lg-6">
                  <div class="content" data-aos="fade-up">
                    <h3 class="title LexenExtraLight px-5 py-5 pr--105" style="font-size: 24px">
                      {{ $t('Home.section2.title1') }}
                    </h3>
                  </div>
                </div>
                <div class="col-lg-6">
                  <p
                    class="mb--10 LexenExtraLight p-5"
                    :class="{ ' text-black': userLogin, 'text-white': !userLogin }"
                    data-aos="fade-up"
                  >
                    {{ $t('Home.section2.title2') }}
                  </p>
                  <a
                    class="text-decoration-underline px-5 LexenExtraLight text-primary"
                    target="_blank"
                    rel="noopener"
                    data-aos="fade-up"
                    href="https://www.gridwhiz.io/"
                    >view more</a
                  >
                </div>
              </div>

              <div class="row d-lg-none">
                <div class="col-lg-6">
                  <div class="content" data-aos="fade-up">
                    <h3 class="title LexenExtraLight px-5 py-0 pr--105" style="font-size: 18px">
                      {{ $t('Home.section2.title1') }}
                    </h3>
                  </div>
                </div>
                <div class="col-lg-6">
                  <p
                    class="mb--5 LexenExtraLight px-5"
                    :class="{ ' text-black': userLogin, 'text-white': !userLogin }"
                    data-aos="fade-up"
                    style="font-size: 16px"
                  >
                    {{ $t('Home.section2.title2') }}
                  </p>
                  <a
                    class="text-decoration-underline px-5 LexenExtraLight text-primary"
                    target="_blank"
                    rel="noopener"
                    data-aos="fade-up"
                    href="https://www.gridwhiz.io/"
                    >view more</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="contaier">
          <img src="../../assets/images/Newlandindpage/2566-10-03 11.12.00.jpg" />
        </div> -->
      </div>
    </div>

    <Separator />

    <!-- Start About Area -->

    <!-- End About Area -->

    <Separator />
    <!-- #section2 -->
    <!-- Start Service Area -->
    <div class="rn-service-area" id="section2">
      <div
        :class="{
          'background-sec-2': userLogin === false,
          'background-sec-2-light': userLogin === true
        }"
      >
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <SectionTitle
                id="sec2"
                text-align="center"
                subtitle="Our Fact Sheets"
                title="What are the benefits of EV Roaming?"
                description="Electric Vehicle (EV) roaming offers a multitde of significant advantage over reliance on a single charging operator These benefit extend to eletric vehicle users, the charging infrastructrure network, and society, and society at large. Here are the key adventages of EV roaming"
                data-aos="fade-up"
                class="mt-5"
              />
            </div>
          </div>
          <div class="mb-5">
            <ServiceFive service-style="gallery-style" text-align="left" />
          </div>

          <br />
          <div class="text-center LexenExtraLight">
            <p :class="{ 'text-black': userLogin, 'text-white': !userLogin }">
              In summary, EV roaming provides numerous benefits, including enhanced convenience,
              expanded charging coverage, increased competition and innovation, improved resilience,
              and enhanced interoperability. These advantages contribute to the overall growth and
              adoption of electric vehicles, advancing the transition to a more sustainable and
              environmentally friendly transportation system.
            </p>
          </div>
          <!-- #section3 -->
          <br />
        </div>
      </div>
      <SectionTitle
        id="section3"
        class="mt-5 pt--100"
        text-align="center"
        subtitle="Contact Our Evangelist Now!"
        title="Our services"
        description=""
        data-aos="fade-up"
      />

      <br />
      <br />
      <br />
      <div class="rwt-split-area no-radius">
        <div class="wrapper">
          <Split :split-data="onedata" />
        </div>

        <div
          :class="{
            'bg-concern.png': userLogin === false,
            'background-sec-3-2-lightmode wrapper': userLogin === true
          }"
        >
          <!-- <Split :split-data="twodata" /> -->
          <SplitClone :split-data="threedata" :splitStyle="11" />
        </div>
      </div>
      <div class="rwt-split-area no-radius">
        <div class="wrapper">
          <SplitClone2 id="test1" :split-data="twodata" :splitStyle="12" />
        </div>
        <br />
        <br />
        <div class="rwt-split-area">
          <div class="wrapper">
            <Split :split-data="fourdata" :number="4" />
          </div>
        </div>
      </div>
    </div>

    <div
      class="rwt-about-area rn-section-gap"
      id="section4"
      :class="{ 'background-concern': !userLogin, 'background-concern-light': userLogin }"
    >
      <div class="container">
        <div class="row row--30 align-items-center">
          <div class="col-lg-1"></div>
          <div class="col-lg-5 mt_md--40 mt_sm--40">
            <div class="content">
              <div class="section-title">
                <SectionTitle
                  hr="yes"
                  id="sec4"
                  text-align="left"
                  subtitle="Our targeting customers"
                  title="Concerned Parties"
                  description=""
                  data-aos="fade-up"
                />
                <div>
                  <!-- {{ concern }} -->
                </div>
                <span class="">
                  <p class="d-flex" v-for="(item, index) in concern" :key="index">
                    <img
                      v-if="userLogin === false"
                      class="p-2"
                      :src="require(`@/assets/images/Newlandindpage/${item.image}`)"
                      alt="Charge Point Operators"
                    />
                    <img
                      v-if="userLogin === true"
                      class="p-2"
                      :src="require(`@/assets/images/Newlandindpage/${item.image2}`)"
                      alt="Charge Point Operators"
                    />
                    <span
                      class="pt-2 LexenExtraLight"
                      :class="{ 'text-black': userLogin, 'text-white': !userLogin }"
                    >
                      {{ item.title }}</span
                    >
                  </p>
                </span>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="thumbnail" data-aos="fade-up" data-aos-delay="250">
              <img
                v-if="!userLogin"
                class="w-80"
                src="../../assets/images/Newlandindpage/new-image-concern.png"
                alt="About Images"
              />
              <img
                v-if="userLogin"
                class="w-80"
                src="../../assets/images/Newlandindpage/concernimage.png"
                alt="About Images"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="rn-service-area rn-section-gap"
      :class="{
        '': userLogin === false,
        'background-sec-5-1-lightmode': userLogin === true
      }"
    >
      <div class="container" id="section5">
        <div class="row">
          <div class="col-lg-12">
            <SectionTitle2
              id="sec5"
              subtitle="Let's Summarize What The Advantages Are"
              text-align="center"
              title="Pros & Cons Comparison"
              data-aos="fade-up"
              description=""
            />
          </div>
        </div>
      </div>
    </div>
    <div class="container" :class="{ '': userLogin, '': !userLogin }">
      <div class="rn-service-area">
        <Servicepros card-style="card-style-1" text-align="left" style="padding-bottom: 10%" />
      </div>
    </div>

    <div
      class="rn-service-area rn-section-gap"
      :class="{
        'background-sec-3-light': userLogin,
        'background-sec-3': !userLogin
      }"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-12 d-none d-lg-block">
            <carousel
              v-if="!userLogin"
              class=""
              :per-page="3"
              :centerMode="true"
              :speed="500"
              :navigate-to="someLocalProperty"
              :mouse-drag="true"
              :navigationEnabled="true"
              navigationNextLabel="<div class='arrow-right'></div>"
              navigationPrevLabel="<div class='arrow-left'></div>"
            >
              <slide class="p-5 pl--25" v-for="(item, index) in Imprmentcarousel" :key="index">
                <div class="flip-card" tabIndex="0">
                  <div class="flip-card-inner">
                    <div
                      class="flip-card-front"
                      :class="{ 'cardcar-dark': !userLogin, 'cardcar-light': userLogin }"
                    >
                      <div
                        style="height: 98%; width: 100%"
                        data-aos="fade-up"
                        data-aos-delay="70"
                        :class="{ 'text-white': !userLogin, 'text-black': userLogin }"
                      >
                        <div class="image" v-if="userLogin === false">
                          <img
                            class="p-5"
                            style="width: 90%; margin-top: -5%"
                            :src="item.image2"
                            alt="Service Images"
                            v-if="index !== 3 && index !== 11 && index !== 9 && index !== 8"
                          />
                          <img
                            class="p-5"
                            style="width: 95%; margin-top: -6%"
                            :src="item.image2"
                            alt="Service Images"
                            v-if="index == 9 || index == 8"
                          />
                          <img
                            class="p-5"
                            style="width: 80%; margin-top: -12.5%"
                            :src="item.image2"
                            alt="Service Images"
                            v-if="index == 3"
                          />
                          <img
                            class="p-5"
                            style="width: 80%; margin-top: 7.5%"
                            :src="item.image2"
                            alt="Service Images"
                            v-if="index == 11"
                          />
                        </div>
                        <div class="image p-5" v-if="userLogin === true">
                          <img style="width: 60%" :src="item.image2" alt="Service Images" />
                        </div>
                        <div class="content text-center px-4">
                          <h4 class="title" style="margin-top: -4%" v-if="index == 9 || index == 8">
                            <router-link
                              class="p-2"
                              to="/service-details"
                              style="font-size: 24px"
                              v-text="item.title"
                            >
                            </router-link>
                          </h4>
                          <h4
                            class="title"
                            style="margin-top: -0.5%"
                            v-else-if="index !== 9 && index !== 8 && index !== 3 && index !== 11"
                          >
                            <router-link
                              class="p-2"
                              to="/service-details"
                              style="font-size: 24px"
                              v-text="item.title"
                            >
                            </router-link>
                          </h4>
                          <h4 class="title mt--10" v-else>
                            <router-link
                              class="p-2"
                              to="/service-details"
                              style="font-size: 24px"
                              v-text="item.title"
                            >
                            </router-link>
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div
                      class="flip-card-back p-3"
                      :class="{ 'cardcar-dark': !userLogin, 'cardcar-light': userLogin }"
                      style="height: 100%"
                    >
                      <div class="description b1 m-lg-4">
                        <span
                          :class="{ 'card-title-dark': !userLogin, 'card-title-light': userLogin }"
                        >
                          {{ item.title }}
                        </span>
                        <p
                          :class="{
                            'card-description-dark': !userLogin,
                            'card-description-light': userLogin
                          }"
                          style="margin-top: 20%"
                          v-if="
                            index == 1 ||
                            index == 4 ||
                            index == 8 ||
                            index == 9 ||
                            index == 10 ||
                            index == 11
                          "
                        >
                          {{ item.description }}
                        </p>
                        <p
                          :class="{
                            'card-description-dark': !userLogin,
                            'card-description-light': userLogin
                          }"
                          style="margin-top: 10%"
                          v-else
                        >
                          {{ item.description }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </slide>
            </carousel>
            <carousel
              v-if="userLogin"
              class=""
              :per-page="3"
              :centerMode="true"
              :speed="500"
              :navigate-to="someLocalProperty"
              :mouse-drag="true"
              :navigationEnabled="true"
              navigationNextLabel="<div class='arrow-right-light'></div>"
              navigationPrevLabel="<div class='arrow-left-light'></div>"
            >
              <slide class="p-5 pl--25" v-for="(item, index) in Imprmentcarousel" :key="index">
                <div class="flip-card" tabIndex="0">
                  <div class="flip-card-inner">
                    <div
                      class="flip-card-front"
                      :class="{ 'cardcar-dark': !userLogin, 'cardcar-light': userLogin }"
                    >
                      <div
                        style="height: 98%; width: 100%"
                        data-aos="fade-up"
                        data-aos-delay="70"
                        :class="{ 'cardcar2-dark': !userLogin, 'cardcar2-light': userLogin }"
                      >
                        <div class="image" v-if="userLogin === true">
                          <img
                            class="p-5"
                            style="width: 90%; margin-top: -5%"
                            :src="item.image2"
                            alt="Service Images"
                            v-if="index !== 3 && index !== 11 && index !== 9 && index !== 8"
                          />
                          <img
                            class="p-5"
                            style="width: 95%; margin-top: -6%"
                            :src="item.image2"
                            alt="Service Images"
                            v-if="index == 9 || index == 8"
                          />
                          <img
                            class="p-5"
                            style="width: 80%; margin-top: -12.5%"
                            :src="item.image2"
                            alt="Service Images"
                            v-if="index == 3"
                          />
                          <img
                            class="p-5"
                            style="width: 80%; margin-top: 7.5%"
                            :src="item.image2"
                            alt="Service Images"
                            v-if="index == 11"
                          />
                        </div>
                        <div class="image p-5" v-if="userLogin === false">
                          <img style="width: 60%" :src="item.image2" alt="Service Images" />
                        </div>
                        <div class="content text-center px-4">
                          <h4 class="title" style="margin-top: -4%" v-if="index == 9 || index == 8">
                            <router-link
                              class="p-2"
                              to="/service-details"
                              style="font-size: 24px"
                              v-text="item.title"
                            >
                            </router-link>
                          </h4>
                          <h4
                            class="title"
                            style="margin-top: -0.5%"
                            v-else-if="index !== 9 && index !== 8 && index !== 3 && index !== 11"
                          >
                            <router-link
                              class="p-2"
                              to="/service-details"
                              style="font-size: 24px"
                              v-text="item.title"
                            >
                            </router-link>
                          </h4>
                          <h4 class="title mt--10" v-else>
                            <router-link
                              class="p-2"
                              to="/service-details"
                              style="font-size: 24px"
                              v-text="item.title"
                            >
                            </router-link>
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div
                      class="flip-card-back p-3"
                      :class="{ 'cardcar-dark': !userLogin, 'cardcar-light': userLogin }"
                      style="height: 100%"
                    >
                      <div class="description b1 m-lg-4">
                        <span
                          :class="{ 'card-title-dark': !userLogin, 'card-title-light': userLogin }"
                        >
                          {{ item.title }}
                        </span>
                        <p
                          :class="{
                            'card-description-dark': !userLogin,
                            'card-description-light': userLogin
                          }"
                          style="margin-top: 20%"
                          v-if="
                            index == 1 ||
                            index == 4 ||
                            index == 8 ||
                            index == 9 ||
                            index == 10 ||
                            index == 11
                          "
                        >
                          {{ item.description }}
                        </p>
                        <p
                          :class="{
                            'card-description-dark': !userLogin,
                            'card-description-light': userLogin
                          }"
                          style="margin-top: 10%"
                          v-else
                        >
                          {{ item.description }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </slide>
            </carousel>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 d-lg-none">
            <carousel
              v-if="!userLogin"
              class=""
              :per-page="1"
              :centerMode="true"
              :speed="500"
              :navigate-to="someLocalProperty"
              :mouse-drag="true"
              :navigationEnabled="true"
              navigationNextLabel="<div class='arrow-right'></div>"
              navigationPrevLabel="<div class='arrow-left'></div>"
            >
              <slide class="p-2" v-for="(item, index) in Imprmentcarousel" :key="index">
                <div class="flip-card" tabIndex="0">
                  <div class="flip-card-inner">
                    <div
                      class="flip-card-front"
                      :class="{ 'cardcar-dark': !userLogin, 'cardcar-light': userLogin }"
                    >
                      <div
                        style="height: 98%; width: 100%"
                        data-aos="fade-up"
                        data-aos-delay="70"
                        :class="{ 'text-white': !userLogin, 'text-black': userLogin }"
                      >
                        <div class="image" v-if="userLogin === false">
                          <img
                            class="p-5"
                            style="width: 90%; margin-top: -5%"
                            :src="item.image2"
                            alt="Service Images"
                            v-if="index !== 3 && index !== 11 && index !== 9 && index !== 8"
                          />
                          <img
                            class="p-5"
                            style="width: 95%; margin-top: -6%"
                            :src="item.image2"
                            alt="Service Images"
                            v-if="index == 9 || index == 8"
                          />
                          <img
                            class="p-5"
                            style="width: 80%; margin-top: -12.5%"
                            :src="item.image2"
                            alt="Service Images"
                            v-if="index == 3"
                          />
                          <img
                            class="p-5"
                            style="width: 80%; margin-top: 7.5%"
                            :src="item.image2"
                            alt="Service Images"
                            v-if="index == 11"
                          />
                        </div>
                        <div class="image p-5" v-if="userLogin === true">
                          <img style="width: 60%" :src="item.image2" alt="Service Images" />
                        </div>
                        <div class="content text-center px-4">
                          <h4 class="title" style="margin-top: -4%" v-if="index == 9 || index == 8">
                            <router-link
                              class="p-2"
                              to="/service-details"
                              style="font-size: 24px"
                              v-text="item.title"
                            >
                            </router-link>
                          </h4>
                          <h4
                            class="title"
                            style="margin-top: -0.5%"
                            v-else-if="index !== 9 && index !== 8 && index !== 3 && index !== 11"
                          >
                            <router-link
                              class="p-2"
                              to="/service-details"
                              style="font-size: 24px"
                              v-text="item.title"
                            >
                            </router-link>
                          </h4>
                          <h4 class="title mt--10" v-else>
                            <router-link
                              class="p-2"
                              to="/service-details"
                              style="font-size: 24px"
                              v-text="item.title"
                            >
                            </router-link>
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div
                      class="flip-card-back p-3"
                      :class="{ 'cardcar-dark': !userLogin, 'cardcar-light': userLogin }"
                      style="height: 100%"
                    >
                      <div class="description b1 m-lg-4">
                        <span
                          :class="{ 'card-title-dark': !userLogin, 'card-title-light': userLogin }"
                        >
                          {{ item.title }}
                        </span>
                        <p
                          :class="{
                            'card-description-dark': !userLogin,
                            'card-description-light': userLogin
                          }"
                          style="margin-top: 20%"
                          v-if="
                            index == 1 ||
                            index == 4 ||
                            index == 8 ||
                            index == 9 ||
                            index == 10 ||
                            index == 11
                          "
                        >
                          {{ item.description }}
                        </p>
                        <p
                          :class="{
                            'card-description-dark': !userLogin,
                            'card-description-light': userLogin
                          }"
                          style="margin-top: 10%"
                          v-else
                        >
                          {{ item.description }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </slide>
            </carousel>
            <carousel
              v-if="userLogin"
              class=""
              :per-page="3"
              :centerMode="true"
              :speed="500"
              :navigate-to="someLocalProperty"
              :mouse-drag="true"
              :navigationEnabled="true"
              navigationNextLabel="<div class='arrow-right-light'></div>"
              navigationPrevLabel="<div class='arrow-left-light'></div>"
            >
              <slide class="p-5 pl--25" v-for="(item, index) in Imprmentcarousel" :key="index">
                <div class="flip-card" tabIndex="0">
                  <div class="flip-card-inner">
                    <div
                      class="flip-card-front"
                      :class="{ 'cardcar-dark': !userLogin, 'cardcar-light': userLogin }"
                    >
                      <div
                        style="height: 98%; width: 100%"
                        data-aos="fade-up"
                        data-aos-delay="70"
                        :class="{ 'cardcar2-dark': !userLogin, 'cardcar2-light': userLogin }"
                      >
                        <div class="image" v-if="userLogin === true">
                          <img
                            class="p-5"
                            style="width: 90%; margin-top: -5%"
                            :src="item.image2"
                            alt="Service Images"
                            v-if="index !== 3 && index !== 11 && index !== 9 && index !== 8"
                          />
                          <img
                            class="p-5"
                            style="width: 95%; margin-top: -6%"
                            :src="item.image2"
                            alt="Service Images"
                            v-if="index == 9 || index == 8"
                          />
                          <img
                            class="p-5"
                            style="width: 80%; margin-top: -12.5%"
                            :src="item.image2"
                            alt="Service Images"
                            v-if="index == 3"
                          />
                          <img
                            class="p-5"
                            style="width: 80%; margin-top: 7.5%"
                            :src="item.image2"
                            alt="Service Images"
                            v-if="index == 11"
                          />
                        </div>
                        <div class="image p-5" v-if="userLogin === false">
                          <img style="width: 60%" :src="item.image2" alt="Service Images" />
                        </div>
                        <div class="content text-center px-4">
                          <h4 class="title" style="margin-top: -4%" v-if="index == 9 || index == 8">
                            <router-link
                              class="p-2"
                              to="/service-details"
                              style="font-size: 24px"
                              v-text="item.title"
                            >
                            </router-link>
                          </h4>
                          <h4
                            class="title"
                            style="margin-top: -0.5%"
                            v-else-if="index !== 9 && index !== 8 && index !== 3 && index !== 11"
                          >
                            <router-link
                              class="p-2"
                              to="/service-details"
                              style="font-size: 24px"
                              v-text="item.title"
                            >
                            </router-link>
                          </h4>
                          <h4 class="title mt--10" v-else>
                            <router-link
                              class="p-2"
                              to="/service-details"
                              style="font-size: 24px"
                              v-text="item.title"
                            >
                            </router-link>
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div
                      class="flip-card-back p-3"
                      :class="{ 'cardcar-dark': !userLogin, 'cardcar-light': userLogin }"
                      style="height: 100%"
                    >
                      <div class="description b1 m-lg-4">
                        <span
                          :class="{ 'card-title-dark': !userLogin, 'card-title-light': userLogin }"
                        >
                          {{ item.title }}
                        </span>
                        <p
                          :class="{
                            'card-description-dark': !userLogin,
                            'card-description-light': userLogin
                          }"
                          style="margin-top: 20%"
                          v-if="
                            index == 1 ||
                            index == 4 ||
                            index == 8 ||
                            index == 9 ||
                            index == 10 ||
                            index == 11
                          "
                        >
                          {{ item.description }}
                        </p>
                        <p
                          :class="{
                            'card-description-dark': !userLogin,
                            'card-description-light': userLogin
                          }"
                          style="margin-top: 10%"
                          v-else
                        >
                          {{ item.description }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </slide>
            </carousel>
          </div>
        </div>
      </div>
    </div>

    <!-- #section5 -->
    <div
      :class="{
        'background-sec-6-lightmode': userLogin,
        'background-sec-6-lightmode': !userLogin
      }"
    >
      <div class="rwt-split-area" id="section6">
        <div class="wrapper">
          <Split
            class=""
            style="padding-top: 2%"
            id="sec6"
            :received-data="hasNewClass"
            :split-data="splitsection5Data"
            :split-style="4"
          />
        </div>
      </div>
    </div>
    <!--  -->
    <!-- #section6 -->
    <AboutFour :image="require(`../../assets/images/Newlandindpage/shutterstock_323846798.png`)" />
    <!--  -->

    <!-- #section7 -->

    <div class="rwt-brand-area rn-section-gapBottom">
      <div
        class=""
        :class="{ 'background-concern': !userLogin, 'background-concern-light': userLogin }"
        style="padding-bottom: 10%"
      >
        <div class="container pt-lg-5">
          <div class="row">
            <div class="col-lg-12 mt-lg-5">
              <SectionTitle
                id="section7"
                text-align="center"
                subtitle="Just few steps ahead"
                title="Meet our customers"
                data-aos="fade-up"
              />
            </div>
          </div>
          <div class="row mt-5">
            <Brand
              class="mt-3 justify-content-center"
              :received-data="hasNewClass"
              :brand-list="brandList"
              :brandlistlight="brandListlight"
              :brand-style="4"
            />
          </div>
        </div>
      </div>
    </div>

    <!--  -->
    <!-- #section8 -->
    <div class="rwt-timeline-area" id="section8">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <SectionTitle
              id="sec8"
              text-align="center"
              subtitle="Just few steps ahead"
              title="How to get connected to the HubThings?"
              description="Our support teams are more than happy to help you."
            />
          </div>
        </div>
        <div class="row mt_dec--20">
          <div class="col-lg-12">
            <Timeline :timeline-data="timelineData" />
          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <!-- #section9 -->
    <div></div>

    <!-- comment lorem -->
    <!-- <AboutSection9
      :image="require(`@/assets/images/Newlandindpage/phone-car-with-map-screen.png`)"
    /> -->

    <!-- Start Team Area -->
    <!-- <div class="rwt-team-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle text-align="center" subtitle="Our Experts." title="Our Company Experts."
                            description="We provide company and finance service for <br /> startups and company business."
                            data-aos="fade-up" />
                    </div>
                </div>
                <div class="row row--15">
                    <div class="col-lg-4 col-md-6 col-12" v-for="(teamMember, index) in teamData" :key="index">
                        <Team :team-member="teamMember" :team-style="2" />
                    </div>

                </div>
            </div>
        </div> -->
    <!-- End Team Area -->

    <Separator />

    <!-- Start Mission Area -->
    <!-- sections10 -->
    <div
      class="mt--200"
      id="section9"
      :class="{
        'background-sec-10': userLogin === false,
        'background-sec-10-light': userLogin === true
      }"
    >
      <div class="rn-company-mission-are rn-section-gap">
        <div class="container">
          <div class="row row--30">
            <div class="col-lg-6" id="sec9">
              <div class="mission-title">
                <h2 class="title" data-aos="fade-up">
                  How can we help? <br />
                  <vue-typed-js
                    class="theme-gradient"
                    :strings="['Frequently', 'Asked ', 'Questions', 'Finance']"
                    :loop="true"
                    :type-speed="80"
                    :start-delay="1000"
                    :back-speed="50"
                  >
                    <b class="is-visible typing"></b>
                  </vue-typed-js>
                </h2>
                <p
                  data-aos="fade-up LexenRegular"
                  :class="{ 'text-white': !userLogin, 'text-black': userLogin }"
                >
                  In addition to the information provided above, we have compiled a list of
                  frequently asked questions along with concise, user-friendly answers regarding our
                  services. While you may find some of your queries addressed here, if you still
                  have any lingering concerns or questions, please don't hesitate to reach out to
                  us. We are here to assist you.
                </p>
                <!-- <p data-aos="fade-up">
                  Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum -LoremLorem ipsum Lorem ipsum
                  Lorem ipsum Lorem ipsum -LoremLorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum
                  -Lorem
                </p> -->
                <div class="read-more-btn mt--50" data-aos="fade-up">
                  <router-link class="btn-default round btn-icon" to="#">
                    Read More
                    <!-- <Icon
                                        name="arrow-right"
                                        size="20"
                                        icon-class="icon"
                                    /> -->
                  </router-link>
                </div>
              </div>
            </div>
            <div class="col-lg-6 mt_md--30 mt_sm--30">
              <Accordion id="accordionExampleOne" data-aos="fade-up" data-aos-delay="60" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--  -->

    <Separator />

    <!-- Start Testimonial Area -->
    <!-- <div class="rwt-testimonial-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle text-align="center" subtitle="Client Feedback" title="Our Awesome Client."
                            description="We provide company and finance service for <br /> startups and company business."
                            data-aos="fade-up" />
                    </div>
                </div>
                <Testimonial :testimonial="testimonialData5" :testimonial-style="5" data-aos="fade-up" />
            </div>
        </div> -->
    <!-- End Testimonial Area -->

    <Separator />

    <!-- <div class="blog-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle text-align="center" subtitle="Latest News" title="Our Latest News."
                            description="We provide company and finance service for <br /> startups and company business."
                            data-aos="fade-up" />
                    </div>
                </div>
                <div class="row row--15">
                    <div class="col-lg-4 col-md-6 col-sm-12 col-12 mt--30" data-aos="slide-up" data-aos-duration="800"
                        :data-aos-delay="100 + index" v-for="(blog, index) in posts" :key="index" v-if="index < 3">
                        <BlogPost :blog="blog" />
                    </div>
                </div>
            </div>
        </div> --
    <!-End Blog Area -->

    <!-- {{ Imprmentcarousel }} -->
  </Layout>
</template>

<script>
import Layout from '../../components/common/Layout'
import Separator from '../../components/elements/separator/Separator'
import Icon from '../../components/icon/Icon'
import SectionTitle from '../../components/elements/sectionTitle/SectionTitle'
import ServiceFive from '../../components/elements/service/ServiceFive'
import Team from '../../components/elements/team/Team'
import BlogPost from '../../components/blog/BlogPost'
import BlogPostMixin from '../../mixins/BlogPostMixin'
import CallToAction from '../../components/elements/callToAction/CallToAction'
import Testimonial from '../../components/elements/testimonial/Testimonial'
import Accordion from '../../components/elements/accordion/Accordion'
import Split from '../../components/elements/split/Split'
import ServiceOne from '../../components/elements/service/ServiceOne'
import AboutFour from '../../components/elements/about/AboutFour'
import AboutSection9 from '../../components/elements/about/AboutSection9.vue'
import Brand from '../../components/elements/brand/Brand'
import Timeline from '../../components/elements/timeline/Timeline'
import SectionTitle2 from '../../components/elements/sectionTitle/SectionTitle2.vue'
import '@/assets/global.css'
import { usePiniaStore } from '../../store/index'
import axios from 'axios'
import i18n from '../../../i18n/i18n'
import Servicepros from '../../components/elements/service/Servicepros'
import SplitClone from '../../components/elements/split/Splitclone.vue'
import SplitClone2 from '../../components/elements/split/Splitclone2.vue'
import { Carousel, Slide } from 'vue-carousel'
import { bottom } from '@popperjs/core'

export default {
  name: 'BusinessConsulting2',
  i18n,
  props: ['dataFromParent'],
  components: {
    Carousel,
    Slide,
    Accordion,
    AboutSection9,
    Timeline,
    Brand,
    ServiceOne,
    Testimonial,
    CallToAction,
    BlogPost,
    Team,
    Split,
    ServiceFive,
    SectionTitle,
    Icon,
    Separator,
    AboutFour,
    Layout,
    SectionTitle2,
    Servicepros,
    SplitClone,
    SplitClone2
  },
  mixins: [BlogPostMixin],
  data() {
    return {
      hasNewClass: false,
      // parentData: 'hasNewClass',
      datatest: null,
      url: 'https://api.nuxtjs.dev/mountains',
      teamData: [
        {
          image: 'team-01',
          name: 'Sr Janen Sara',
          designation: 'Sr Product Designer',
          location: 'CO Miego, AD, USA',
          description: 'Yes, I am a product designer. I have a passion for product design.',
          socialNetwork: [
            {
              icon: 'facebook',
              url: '#'
            },
            {
              icon: 'twitter',
              url: '#'
            },
            {
              icon: 'instagram',
              url: '#'
            }
          ]
        },
        {
          image: 'team-02',
          name: 'Afsana Nila',
          designation: 'App Developer',
          location: 'Bangladesh',
          description: 'Yes, I am a product designer. I have a passion for product design.',
          socialNetwork: [
            {
              icon: 'facebook',
              url: '#'
            },
            {
              icon: 'twitter',
              url: '#'
            },
            {
              icon: 'instagram',
              url: '#'
            }
          ]
        },
        {
          image: 'team-03',
          name: 'Afanan Sifa',
          designation: 'Accounts Manager',
          location: 'Poland',
          description: 'Yes, I am a product designer. I have a passion for product design.',
          socialNetwork: [
            {
              icon: 'facebook',
              url: '#'
            },
            {
              icon: 'twitter',
              url: '#'
            },
            {
              icon: 'instagram',
              url: '#'
            }
          ]
        }
      ],
      concern: [
        {
          title: 'Charge Point Operators (CPOs)',
          image: 'business-outline.svg',
          image2: 'business-outline.png'
        },
        {
          title: 'e-Mobility Service Provider (eMSPs)',
          image: 'business-outline.svg',
          image2: 'business-outline.png'
        },
        {
          title: 'Car Manufacturers',
          image: 'business-outline.svg',
          image2: 'business-outline.png'
        },
        {
          title: 'General EV Users',
          image: 'business-outline.svg',
          image2: 'business-outline.png'
        },
        {
          title: 'Authorities or Research Institutes',
          image: 'business-outline.svg',
          image2: 'business-outline.png'
        }
      ],
      testimonialData5: [
        {
          form: 'Poland',
          description:
            'What I am primarily looking for with new projects is a fit on both a visual and aesthetic level as well as on a personal level with the client.',
          name: 'John Due',
          subtitle: 'App Developer',
          image: 'testimonial-dark-01'
        },
        {
          form: 'Germany',
          description:
            'What I am primarily looking for with new projects is a fit on both a visual and aesthetic level as well as on a personal level with the client.',
          name: 'John Due',
          subtitle: 'App Developer',
          image: 'testimonial-dark-02'
        },
        {
          form: 'USA',
          description:
            'What I am primarily looking for with new projects is a fit on both a visual and aesthetic level as well as on a personal level with the client.',
          name: 'Janen',
          subtitle: 'App Developer',
          image: 'testimonial-dark-03'
        }
      ],
      fourdata: {
        image: 'Group 27918@2x.png',
        title: 'Software/Platform ',
        title2: 'Development',
        description:
          'We offer comprehensive software development services tailored to your needs. From concept to deployment, our team creates cutting-edge solutions that drive efficiency, enhance user experiences, and bring your ideas to life. Whether it`s web, mobile, or custom applications, We are committed to delivering top-notch quality and innovation that transforms your vision into reality.',
        list: [],
        btn: {
          text: '!/',
          link: '/contact'
        }
      },
      threedata: {
        image: 'Group_27919.png',
        title: 'Professional Consultancy  ',
        title2: 'on Smart Grids ',
        description: `As a leading expert in smart grids technology, our consultancy service provides invaluable insights and guidance for navigating the complex landscape of modern energy distribution. With a deep understanding of grid optimization, renewable integration, and advanced monitoring systems, we offer strategic solutions that empower utilities and organizations to achieve enhanced efficiency, sustainability, and reliability. Together, we pave the way for a brighter energy future.`
      },
      twodata: {
        image: 'Group_27920.png',
        image2: 'line_path.png',
        title: 'EV Roaming  ',
        title2: '  API Integration',
        description:
          'We are a recognized authority in EV roaming integration with official patented products. Our expertise lies in seamlessly connecting electric vehicle charging networks, ensuring smooth and convenient experiences for EV users wherever they go. Through our API integration service, we facilitate interoperability, streamline operations, and contribute to the widespread adoption of electric vehicles, paving the way for a greener transportation future.',
        list: [],
        btn: {
          text: '!/',
          link: '/contact'
        }
      },
      onedata: {
        image: 'group-new.png',
        path1: 'line_path.png',
        title: 'Domestic and International ',
        title2: 'Clearing House',
        description: `
As a seasoned professional in this distinct field, we excel at facilitating transparent transactions and seamless communication between diverse electric vehicle charging networks. Our expertise ensures efficient billing, settlement, and data exchange, contributing significantly to the growth and accessibility of electric mobility on a global scale.`
      },
      splitsection5Data: {
        image: 'Group_27923.svg',
        image1: 'Group 28074@2x.png',
        title: 'Starting by covering ASEAN countries and moving beyond',
        description: `HubThings provide comprehensive solutions for all stakeholders involved. With the advantages of Platform-as-a-Service and Software-as-a-Service offerings, establishing an EV roaming hub for a country can happen in no time. Additionally, platform customization is unrestricted for different business logic and better user experiences in each specific zone. Rapid EV roaming at a continental level is easily achievable.`,
        counterData1: [
          {
            number: 252,
            title: ' Connected CPOs'
          },
          {
            number: 189,
            title: 'Connected eMSPs'
          }
        ],
        counterData2: [
          {
            number: 184,
            title: 'Charging Stations'
          },
          {
            number: 184,
            title: 'Charging Connectors'
          }
        ]
      },
      brandList: [
        // {
        //   image: require(`@/assets/images/Newlandindpage/MaskGroup 2880.svg`)
        // },
        // {
        //   image: require(`@/assets/images/Newlandindpage/MaskGroup 2881.svg`)
        // },
        // {
        //   image: require(`@/assets/images/Newlandindpage/MaskGroup 2883.svg`)
        // },
        // {
        //   image: require(`@/assets/images/Newlandindpage/MaskGroup 2882.svg`)
        // },
        // {
        //   image: require(`@/assets/images/Newlandindpage/MaskGroup 2886.svg`)
        // },
        // {
        //   image: require(`@/assets/images/Newlandindpage/Group_26986.svg`)
        // },
        // {
        //   image: require(`@/assets/images/Newlandindpage/MaskGroup 2887.svg`)
        // },
        // {
        //   image: require(`@/assets/images/Newlandindpage/Group_2888.svg`)
        // },
        // {
        //   image: require(`@/assets/images/Newlandindpage/Group_26970.svg`)
        // },
        // {
        //   image: require(`@/assets/images/Newlandindpage/Group_26974.svg`)
        // },
        // {
        //   image: require(`@/assets/images/Newlandindpage/Group_26978.svg`)
        // },
        // {
        //   image: require(`@/assets/images/Newlandindpage/Group_26999.svg`)
        // },
        {
          image: require(`@/assets/images/Newlandindpage/pumpcharge.png`)
        }
      ],
      brandListlight: [
        // {
        //   image: require(`../../assets/images/Newlandindpage/lightmode/Mask Group 2880@2x.png`)
        // },
        // {
        //   image: require(`../../assets/images/Newlandindpage/lightmode/Mask Group 2881@2x.png`)
        // },
        // {
        //   image: require(`../../assets/images/Newlandindpage/lightmode/Mask Group 2883@2x.png`)
        // },
        // {
        //   image: require(`../../assets/images/Newlandindpage/lightmode/Mask Group 2882@2x.png`)
        // },
        // {
        //   image: require(`../../assets/images/Newlandindpage/lightmode/Mask Group 2886@2x.png`)
        // },
        // {
        //   image: require(`../../assets/images/Newlandindpage/lightmode/Group 26986@2x.png`)
        // },
        // {
        //   image: require(`../../assets/images/Newlandindpage/lightmode/Mask Group 2887@2x.png`)
        // },
        // {
        //   image: require(`../../assets/images/Newlandindpage/lightmode/Group 2888@2x.png`)
        // },
        // {
        //   image: require(`../../assets/images/Newlandindpage/lightmode/Group 26970@2x.png`)
        // },
        // {
        //   image: require(`../../assets/images/Newlandindpage/lightmode/Group 26974@2x.png`)
        // },
        // {
        //   image: require(`../../assets/images/Newlandindpage/lightmode/Group 26978@2x.png`)
        // },
        // {
        //   image: require(`../../assets/images/Newlandindpage/lightmode/Group 26999@2x.png`)
        // },
        {
          image: require(`../../assets/images/Newlandindpage/lightmode/pumpcharge light.png`)
        }
      ],
      timelineData: [
        {
          id: '1',
          date: 'Step 1',
          title: 'Learning Phase',
          description:
            'In preparation for responding to internal directives from both a business and technical standpoint, it is important to consider the following:',
          image: 'tobias-reich-sC5ZgFyGB4Y-unsplash.png',
          workingStep: [
            {
              stepTitle: 'Business Aspect',
              stepDescription:
                'Assess whether your services share commonalities with those of other operators.'
            },
            {
              stepTitle: 'Technical Aspect',
              stepDescription:
                'Evaluate whether your current platform and infrastructure possess the necessary flexibility to accommodate entry into Electric Vehicle (EV) Roaming.'
            }
          ]
        },
        {
          id: '2',
          date: 'Step 2',
          title: 'Discussion Phase',
          description:
            'Please feel free to contact us, and we will be delighted to engage in a conversation about how our experts can most effectively support your team.',
          image: 'milad-fakurian-PjG_SXDkpwQ-unsplash.png',
          workingStep: [
            {
              stepTitle: 'Business Aspect',
              stepDescription:
                'We offer consulting services, software implementation expertise, and state-of-the-art solutions tailored to meet the specific needs of our clientele.'
            },
            {
              stepTitle: 'Technical Aspect',
              stepDescription:
                'Typically, the entire process, encompassing design, implementation, and finalization, requires approximately 2-3 months to become fully prepared for a successful launch.'
            }
          ]
        },
        {
          id: '3',
          date: 'Step 3',
          title: 'Implementation Phase',
          description:
            'Please take a moment to unwind and await the prompt deployment of our platform as expeditiously as possible.',
          image: 'milad-fakurian-PjG_SXDkpwQ-unsplash.png',
          workingStep: [
            {
              stepTitle: 'Business Aspect',
              stepDescription:
                'Our team is dedicated to conducting a thorough analysis of your existing platform and determining the optimal integration method, regardless of its complexity.'
            },
            {
              stepTitle: 'Technical Aspect',
              stepDescription:
                'Alternatively, should you find it better suited to your requirements, you have the option to carry out the implementation of some parts using your in-house team.'
            }
          ]
        }
      ],
      Imprmentcarousel: [
        {
          image: require('@/assets/images/Newlandindpage/Mask Group 2913@2x.png'),
          image2: require('../../assets/images/Newlandindpage/lightmode/1.png'),

          title: 'Configuration of service connection',
          description:
            'Less tedious work is needed when either a single (CPO/eMSP) or dual (CPO & eMSP) connection is integrated into the hub otherwise, the workload multiplies based on the number of parties being integrated.'
        },
        {
          image: require('@/assets/images/Newlandindpage/Mask Group 2914@2x.png'),
          image2: require('../../assets/images/Newlandindpage/lightmode/2.png'),

          title: 'Provision of physical servers',
          description:
            'Adding more parties doesn necessarily make the IT staff happier, as connecting a greater number of parties often means more processes that demand multiple VMs or containers, resulting in higher investment costs.'
        },
        {
          image: require('@/assets/images/Newlandindpage/Mask Group 2915@2x.png'),
          image2: require('../../assets/images/Newlandindpage/lightmode/3.png'),

          title: 'Data integration to existing EVSMS',
          description:
            'Multiple connections from various parties necessitate repetitive data integration into your Electric Vehicle Charging Station Management System (EVCSMS), which includes elevated maintenance tasks as well.'
        },
        {
          image: require('@/assets/images/Newlandindpage/Mask Group 2916@2x.png'),
          image2: require('../../assets/images/Newlandindpage/lightmode/g1.png'),

          title: 'Create / Read / Update connection',
          description:
            'Once connected to the hub, these internal services only need to be implemented once, without any concerns about future parties that require integration. Newcomers can easily make use of the existing service. '
        },
        {
          image: require('@/assets/images/Newlandindpage/Mask Group 2917@2x.png'),
          image2: require('../../assets/images/Newlandindpage/lightmode/555.png'),

          title: 'Data format validation servers',
          description:
            "The platform always validates the messages back and forth, so the sender will be notified if there's an issue, and the receiver will receive the validated message without any concerns."
        },
        {
          image: require('@/assets/images/Newlandindpage/Mask Group 2918@2x.png'),
          image2: require('../../assets/images/Newlandindpage/lightmode/666.png'),

          title: 'Consultancy service from experts available',
          description:
            'We offer comprehensive consultancy as a dedicated bundled service for our customers, covering both technical and business logic aspects. Our implementation experts are also just a phone call away, ready to assist you.'
        },
        {
          image: require('@/assets/images/Newlandindpage/Mask Group 2920@2x.png'),
          image2: require('../../assets/images/Newlandindpage/lightmode/7.png'),

          title: 'Only one legal contract to be reviewed',
          description:
            'Rather than dealing with numerous separate and two-way contracts with each party, connecting to the hub only necessitates a single legal agreement encompassing all. This approach reduces the need for extensive legal interpretation and review, leading to a quicker settlement process.'
        },
        {
          image: require('@/assets/images/Newlandindpage/Mask Group 2919@2x.png'),
          image2: require('../../assets/images/Newlandindpage/lightmode/8.png'),

          title: 'Extra service provided* (e.g. real-time database)',
          description:
            'We also provide valuable services to our customers, enabling them to accelerate implementation without the need to handle everything on their own. This allows them to concentrate solely on refining their business model for a swift and efficient process.'
        },
        {
          image: require('@/assets/images/Newlandindpage/Mask Group 2915@2x.png'),
          image2: require('../../assets/images/Newlandindpage/lightmode/3.png'),

          title: 'Maintenance cost',
          description:
            'Having fewer processes currently active means you won have to incur excessive maintenance costs or hire as much personnel, resulting in a significantly lower annual operating cost.'
        },
        {
          image: require('@/assets/images/Newlandindpage/Mask Group 2923@2x.png'),
          image2: require('../../assets/images/Newlandindpage/lightmode/10.png'),

          title: 'Required times to go LIVE',
          description:
            'In conjunction with the readily available services we offer, your software implementation team can speed up the time it takes to launch your platform in the market, leading to a quicker financial return on investment. '
        },
        {
          image: require('@/assets/images/Newlandindpage/Mask Group 2922@2x.png'),
          image2: require('../../assets/images/Newlandindpage/lightmode/11.png'),

          title: 'Ready for international cost',
          description:
            'These are the distinct advantages of integrating with the hub, as opposed to connecting on a P2P basis. Information from CPOs/eMSPs in other countries seamlessly flows in and out of your platform without any extra effort mandated.'
        },
        {
          image: require('@/assets/images/Newlandindpage/Mask Group 2924@2x.png'),
          image2: require('../../assets/images/Newlandindpage/lightmode/12.png'),

          title: 'Total development cost',
          description:
            'In summary, there nothing more noteworthy than the overall development cost for your project when you integrate with the hub. As a rule of thumb, reduced development costs directly translate to a quicker return. '
        },
        {
          image: require('@/assets/images/Newlandindpage/Mask Group 2925@2x.png'),
          image2: require('../../assets/images/Newlandindpage/lightmode/13.png'),

          title: 'Single point of settlement',
          description:
            'When you link up with the hub, you can streamline your settlement process, making business operations smoother. You experience less hassle compared to dealing with multi-level communication in a standard P2P setup.'
        }
      ]
    }
  },
  computed: {
    buttonValue() {
      const store = usePiniaStore()
      return store.buttonValue
    },
    userLogin() {
      return this.$store.state.userLogin
    }
  },
  methods: {
    fetchData() {
      // ใช้ Axios เพื่อทำ HTTP requests ที่นี่
      axios
        .get(this.url)
        .then((response) => {
          // จัดการข้อมูลที่ได้รับจากการตอบกลับที่นี่
          this.datatest = response.data
          console.log(this.datatest)
        })
        .catch((error) => {
          // จัดการข้อผิดพลาดที่นี่
          console.error(error)
        })
    },
    scrollToService() {
      let view = this.$route.query.view
      if (view == 'service' && view == 'Concerned') {
        let refService = document.getElementById('service-compoments')
        let refService1 = document.getElementById('Concerned-Parties')
        if ((!refService, !refService1)) return this.scrollToService()
        refService.scrollIntoView({
          behavior: 'smooth' // You can use 'auto' or 'instant' as well
        })
      }
    },
    testclick(e) {
      this.$store.commit('dataLogin', !this.userLogin)
    },

    checkBodyClass() {
      this.hasNewClass = document.body.classList.contains('active-light-mode')
    },
    sendDataToParent() {
      this.$emit('childEvent', 'Data from child')
    },
    sendMessage() {
      EventBus.$emit('message-sent', 'สวัสดีจากคอมโพเนนต์ที่ส่งออก!')
    },
    scrollToComponent(componentId) {
      const element = document.getElementById(componentId)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }

    //
  },

  mounted() {
    this.scrollToService()
  }
}
</script>

<style>
.path-layout {
  transform: translate(209px, -14px);
  position: absolute;
  width: 74vw;
  /* z-index: 1; */
}

.path-layout-1 {
  transform: translate(0px, 55px);
  /* position: absolute; */
  /* z-index: 1; */
}

.sizesection7 {
  height: 100vh;
  width: 100%;
}

.section7-layout {
  margin-top: 25%;
}

.background-sec-2 {
  background-image: url(../../assets/images/Newlandindpage/Rectangle\ 15237.svg);
  background-size: cover;
  background-position: -0% 70%;
  background-repeat: no-repeat;
  /* background-position: right; */
  z-index: 2;
  width: 100%;
  height: 100%;
}

.background-sec-2-light {
  background-image: url(../../assets/images/Newlandindpage/lightmode/Group_27996@2x.png);
  background-size: cover;
  background-position: -0% 70%;
  background-repeat: no-repeat;
  /* background-position: right; */
  z-index: 2;
  width: 100%;
  height: 100%;
}

.background-sec-3 {
  background-image: url(../../assets/images/Newlandindpage/bg_pros.png);
  background-size: cover;
  background-position: -0% 70%;
  background-repeat: no-repeat;
  /* background-position: right; */
  z-index: 2;
  width: 100%;
  height: 100%;
}

.background-sec-3-light {
  background-image: url(../../assets/images/Newlandindpage/lightmode/bg-contact.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 2;
  width: 100%;
  height: 100%;
}

.background-sec-5-1-lightmode {
  background-image: url(../../assets/images/Newlandindpage/lightmode/Group_sec-5.png);
  background-size: cover;
  background-position: -0% 70%;
  background-repeat: no-repeat;
  /* background-position: right; */
  z-index: 2;
  width: 100%;
  height: 100%;
}

.background-sec-6-lightmode {
  background-image: url(../../assets/images/Newlandindpage/lightmode/Group_6.png);
  background-size: cover;
  background-position: -0% 70%;
  background-repeat: no-repeat;
  /* background-position: right; */
  z-index: 2;
  width: 100%;
  height: 100%;
}

.background-sec-9-lightmode {
  background-image: url(../../assets/images/Newlandindpage/lightmode/Group_9.png);
  background-size: cover;
  background-position: -0% 70%;
  background-repeat: no-repeat;
  /* background-position: right; */
  z-index: 2;
  width: 100%;
  height: 100%;
}

.background-sec-4 {
  background-image: url(../../assets/images/Newlandindpage/Rectangle\ 10301.svg);
  background-size: cover;
  background-position: -0% 50%;
  background-repeat: no-repeat;
  /* background-position: right; */
  z-index: 2;
  width: 100%;
  height: 100%;
}

.background-sec-4-light {
  background-image: url(../../assets/images/Newlandindpage/lightmode/Group_28005@2x.png);
  background-size: cover;
  background-position: -0% 50%;
  background-repeat: no-repeat;
  /* background-position: right; */
  z-index: 2;
  width: 100%;
  height: 100%;
}

.background-sec-10 {
  background-image: url(../../assets/images/Newlandindpage/Rectangle\ 10119.svg);
  background-size: cover;
  background-position: -0% 50%;
  background-repeat: no-repeat;
  /* background-position: right; */
  z-index: 2;
  width: 100%;
  height: 100%;
}

.background-sec-10-light {
  background-image: url(../../assets/images/Newlandindpage/lightmode/sec-10.png);
  background-size: cover;
  background-position: -0% 100%;
  background-repeat: no-repeat;
  /* background-position: right; */
  z-index: 2;
  width: 100%;
  height: 100%;
}

.sizeimage {
  width: 80%;
  padding: 10%;
}

.background-concern {
  background-image: url(../../assets/images/Newlandindpage/bg-concern.png);
  background-size: cover;
  background-position: -0% 0%;
  background-repeat: no-repeat;
  /* background-position: right; */
  z-index: 2;
  width: 100%;
  height: 100%;
}

.background-concern-light {
  background-image: url(../../assets/images/Newlandindpage/lightmode/Group_28005@2x.png);
  background-size: cover;
  background-position: -0% 0%;
  background-repeat: no-repeat;
  /* background-position: right; */
  z-index: 2;
  width: 100%;
  height: 100%;
}

.background-sec-1 {
  background-image: url(../../assets/images/Newlandindpage/newimage/Group-bg-sec1.png);
  background-size: cover;
  background-position: -0% 90%;
  background-repeat: no-repeat;
  /* background-position: right; */
  z-index: 2;
  width: 100%;
  height: 100%;
}

.background-sec-1-lightmode {
  background-image: url(../../assets/images/Newlandindpage/newimage/Group\ 33591.png);
  background-size: cover;
  background-position: -0% 0%;
  background-repeat: no-repeat;
  /* background-position: right; */
  z-index: 2;
  width: 100%;
  height: 100%;
}

.background-sec-3-1-lightmode {
  background-image: url(../../assets/images/Newlandindpage/lightmode/Group_sec3-1.png);
  background-size: cover;
  background-position: -0% 0%;
  /* background-repeat: no-repeat; */
  background-position: center;
  z-index: 10;
  width: 100%;
  height: 100%;
}

.backgroundpros {
  background-image: url(../../assets/images/Newlandindpage/testbg.png);
  background-size: cover;
  background-position: -0% 0%;
  /* background-repeat: no-repeat; */
  background-position: center;
  z-index: 2;
  width: 100%;
  height: 100%;
}

.background-sec-3-2-lightmode {
  background-image: url(../../assets/images/Newlandindpage/lightmode/Group_3_2.png);
  background-size: cover;
  background-position: -0% 0%;
  /* background-repeat: no-repeat; */
  background-position: center;
  z-index: 2;
  width: 100%;
  height: 100%;
}

.hidebutton {
  transform: translate(81.8%, -2950%);
  position: fixed;
  bottom: 10%;
  right: 0%;
  z-index: 9999;
  width: 100%;
}

.ggth {
  background: -moz-linear-gradient(45deg, #8a32a5 0%, #afb3b5 29%, #d0a2d4 66%, #ffa079 100%);
  background: -webkit-linear-gradient(45deg, #8a32a5 0%, #afb3b5 29%, #d0a2d4 66%, #ffa079 100%);
  background: linear-gradient(45deg, #8a32a5 0%, #afb3b5 29%, #d0a2d4 66%, #ffa079 100%);
  background-size: 400% 400%;
  -webkit-animation: Gradient 15s ease infinite;
  -moz-animation: Gradient 15s ease infinite;
  animation: Gradient 15s ease infinite;
  /*min-height: calc(100vh - 2rem);*/
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-evenly;
  overflow: hidden;
  position: relative;
}

.ggth::before,
.ggth::after {
  content: '';
  width: 70vmax;
  height: 70vmax;
  position: absolute;
  background: rgba(255, 255, 255, 0.07);
  left: -20vmin;
  top: -20vmin;
  animation: morph 15s linear infinite alternate, spin 20s linear infinite;
  z-index: 1;
  will-change: border-radius, transform;
  transform-origin: 55% 55%;
  pointer-events: none;
}

.ggth::after {
  width: 70vmin;
  height: 70vmin;
  left: auto;
  right: -10vmin;
  top: auto;
  bottom: 0;
  animation: morph 10s linear infinite alternate, spin 26s linear infinite reverse;
  transform-origin: 20% 20%;
}

@-webkit-keyframes Gradient {
  0% {
    background-position: 0 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}

@-moz-keyframes Gradient {
  0% {
    background-position: 0 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}

@keyframes Gradient {
  0% {
    background-position: 0 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}

@keyframes morph {
  0% {
    border-radius: 40% 60% 60% 40% / 70% 30% 70% 30%;
  }

  100% {
    border-radius: 40% 60%;
  }
}

@keyframes spin {
  to {
    transform: rotate(1turn);
  }
}

.st0 {
  display: none;
}

.st1 {
  display: inline;
}

.st2 {
  opacity: 0.29;
}

.st3 {
  fill: #ffffff;
}

.st4 {
  clip-path: url(#SVGID_2_);
  fill: #ffffff;
}

.st5 {
  clip-path: url(#SVGID_4_);
}

.st6 {
  clip-path: url(#SVGID_6_);
}

.st7 {
  clip-path: url(#SVGID_8_);
}

.st8 {
  clip-path: url(#SVGID_10_);
}

.st9 {
  fill: none;
}

.st10 {
  clip-path: url(#SVGID_12_);
}

.st11 {
  opacity: 0.7;
}

.st12 {
  clip-path: url(#SVGID_14_);
}

.st13 {
  opacity: 0.2;
}

.st14 {
  clip-path: url(#SVGID_16_);
}

.st15 {
  opacity: 0.3;
  fill: #ffffff;
  enable-background: new;
}

.image-container {
  position: relative;
  width: 100%;
  /* ปรับขนาดตามที่ต้องการ */
}

.image2-1 {
  width: 100%;
  height: auto;
}

.text-over-image {
  position: absolute;
  /* top: 50%; */
  /* left: 50%; */
  bottom: 0;
  transform: translate(-0%, -0%);
  z-index: 1;
  background-color: rgba(11, 87, 156, 0.7);
  /* ใช้ RGBA เพื่อให้เป็นสีที่มีความโปร่งแสงลดลง */
  padding: 10px;
  backdrop-filter: blur(10px);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  /* ปรับค่าตามที่ต้องการเบลอ */
}

.text-over-image-light {
  /* top: 50%; */
  /* left: 50%; */
  position: absolute;
  bottom: 0;
  transform: translate(-0%, -0%);
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.7);
  /* เปลี่ยนสีเป็นขาว */
  padding: 10px;
  backdrop-filter: blur(10px) brightness(1.2);
  /* ปรับค่าตามที่ต้องการ */
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.cardcar-light {
  /* background: linear-gradient(to bottom, #ffffff, #ffffff, #ffffff, #ffffff, #d5a4f8); */
  border-radius: 10%;
  box-shadow: 0px 0px 6px 1px rgb(198, 93, 247);
  z-index: 1;
  color: #000000;
}

.cardcar-dark {
  /* background: linear-gradient(to bottom, #000000, #000000, #000000, #3b105f); */
  border-radius: 10%;
  box-shadow: 0px 0px 7px 1px rgb(101, 13, 141);
  z-index: 1;
  color: #ffffff;
}

/* .cardcar2-light::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid rgb(212, 119, 255);
  border-radius: 10%;
  box-shadow: 0px 0px 10px 5px rgba(221, 127, 207, 0.7);
  z-index: 1;
} */
/* .cardcar2-dark::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 2px solid rgb(99, 11, 139);
  border-radius: 10%;
  box-shadow: 0px 0px 10px 5px rgba(77, 36, 70, 0.7);
  z-index: 1;
} */

.flip-card {
  background-color: transparent;
  width: 380px;
  height: 380px;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  /* text-align: center; */
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flip-card:focus {
  outline: 0;
}

.flip-card:hover .flip-card-inner,
.flip-card:focus .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  /* backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-backface-visibility: hidden; เพิ่มสำหรับ Safari */
}

.flip-card-front.cardcar-light {
  text-align: center;
  background: linear-gradient(to bottom, #ffffff, #ffffff, #ffffff, #ffffff, #d5a4f8);
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  /* ตั้งค่าความโปร่งใสเริ่มต้นเป็นเต็มที่ */
  transition: opacity 0.6s ease;
  /* เพิ่ม transition เพื่อควบคุมความเร็วในการเปลี่ยนแปลง */
}

.flip-card-front.cardcar-dark {
  text-align: center;
  background: linear-gradient(to bottom, #000000, #000000, #000000, #3b105f);
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  /* ตั้งค่าความโปร่งใสเริ่มต้นเป็นเต็มที่ */
  transition: opacity 0.6s ease;
  /* เพิ่ม transition เพื่อควบคุมความเร็วในการเปลี่ยนแปลง */
}

.flip-card:hover .flip-card-front.cardcar-light,
.flip-card:focus .flip-card-front.cardcar-light {
  background: linear-gradient(to bottom, #ffffff, #ffffff, #ffffff, #ffffff, #d5a4f8);
  box-shadow: 0px 0px 6px 1px rgb(198, 93, 247);
  z-index: 1;
}

.flip-card:hover .flip-card-front.cardcar-dark,
.flip-card:focus .flip-card-front.cardcar-dark {
  background: linear-gradient(to bottom, #000000, #000000, #000000, #3b105f);
  box-shadow: 0px 0px 7px 1px rgb(101, 13, 141);
  z-index: 1;
}

.flip-card-back.cardcar-dark {
  /* background: linear-gradient(to right, #4364f7, #6fb1fc); */
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(10px);
  transform: rotateY(180deg);
  z-index: 2;
  display: block;
  justify-content: center;
  align-items: center;
}

.flip-card-back.cardcar-light {
  /* background: linear-gradient(to right, #4364f7, #6fb1fc); */
  background-color: rgba(198, 93, 247, 0.6);
  backdrop-filter: blur(10px);
  transform: rotateY(180deg);
  z-index: 2;
  display: block;
  justify-content: center;
  align-items: center;
}

.card-title-dark {
  text-align: left !important;
  font-size: larger !important;
  font-weight: bold;
  color: #ffffff !important;
}

.card-title-light {
  text-align: left !important;
  font-size: larger !important;
  font-weight: bold;
  color: #ffffff !important;
}

.card-description-dark {
  text-align: left;
  font-size: 18px !important;
  color: #ffffff !important;
}

.card-description-light {
  text-align: left;
  font-size: 18px !important;
  color: #ffffff !important;
}

h3 {
  font-size: 20px;
  font-family: Verdana, sans-serif;
  font-weight: bold;
  color: #fff;
}

.arrow-right,
.arrow-left {
  display: block;
  margin: 30px auto;
  width: 15px;
  height: 15px;
  border-top: 2px solid #ffffff;
  border-left: 2px solid #ffffff;
}

.arrow-right-light,
.arrow-left-light {
  display: block;
  margin: 30px auto;
  width: 15px;
  height: 15px;
  border-top: 2px solid #000000;
  border-left: 2px solid #000000;
}

.arrow-right,
.arrow-right-light {
  transform: rotate(135deg);
}

.arrow-left,
.arrow-left-light {
  transform: rotate(-45deg);
}
</style>
