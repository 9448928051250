<template>
  <Layout header-class="header-transparent" :footer-style="3">
    <!-- Start Slider Area -->
    <div
      class="slider-area slider-style-1 variation-default height-850 bg_image"
      data-black-overlay="7"
      :style="{
        'background-image': `url(${require(`@/assets/images/bg/bg-image-3.jpg`)})`
      }"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="inner text-center">
              <span class="subtitle" data-aos="fade-up" data-aos-delay="100">
                DIGITAL CONSULTING AGENC
              </span>
              <h1 class="title display-one" data-aos="fade-up" data-aos-delay="150">
                Unique Business <span>Consulting</span>.
              </h1>
              <p class="description" data-aos="fade-up" data-aos-delay="200">
                We help our clients succeed by creating brand identities, digital experiences, and
                print materials.
              </p>
              <div class="button-group" data-aos="fade-up" data-aos-delay="250">
                <a class="btn-default btn-medium round btn-icon" href="#">
                  Purchase Now
                  <Icon name="arrow-right" size="20" icon-class="icon" />
                </a>
                <router-link class="btn-default btn-medium btn-border round btn-icon" to="/contact">
                  Contact Us
                  <Icon name="arrow-right" size="20" icon-class="icon" />
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Slider Area -->

    <!-- Start Service Area -->
    <div class="rn-service-area rn-section-gapBottom pt--100">
      <div class="container">
        <ServiceOne
          service-style="service__style--1 icon-circle-style"
          text-align="center"
          icon-size="39"
        />
      </div>
    </div>
    <!-- End Service Area -->

    <Separator />

    <!-- Start Portfolio Area  -->
    <div class="rwt-portfolio-area rn-section-gap">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <SectionTitle
              text-align="center"
              subtitle="Business Projects"
              title="Business Case Studies.!"
              description="We provide company and finance service for <br /> startups and company business."
              data-aos="fade-up"
            />
          </div>
        </div>
        <Portfolio column="col-lg-4 col-md-6 col-12 mt--30" :portfolio-data="portfolioData" />
      </div>
    </div>
    <!-- End Portfolio Area  -->

    <Separator />

    <!-- Start Timeline Area  -->
    <div class="rwt-timeline-area rn-section-gap">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <SectionTitle
              text-align="center"
              subtitle="Working Process"
              title="Business Working Process."
              description="We provide company and finance service for <br /> startups and company business."
            />
          </div>
        </div>
        <div class="row mt_dec--20">
          <div class="col-lg-12">
            <Timeline :timeline-data="timelineData" />
          </div>
        </div>
      </div>
    </div>
    <!-- End Timeline Area -->

    <!-- Start Video Area -->
    <div class="rwt-video-area rn-section-gapBottom">
      <div class="container">
        <Video :video-data="videoData" :video-style="2" column="col-lg-12" data-aos="fade-up" />
      </div>
    </div>
    <!-- End Video Area -->

    <Separator />

    <!-- Start Elements Area -->
    <div class="rwt-testimonial-area rn-section-gap">
      <div class="container">
        <div class="row mb--20">
          <div class="col-lg-12">
            <SectionTitle
              text-align="center"
              subtitle="Client Feedback"
              title="Our Clients Feedback."
              description="We provide company and finance service for <br /> startups and company business."
              data-aos="fade-up"
            />
          </div>
        </div>
        <Testimonial :testimonial="testimonialData5" :testimonial-style="5" data-aos="fade-up" />
      </div>
    </div>
    <!-- End Elements Area -->

    <Separator />

    <!-- Start Blog Area -->
    <div class="blog-area rn-section-gap">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <SectionTitle
              text-align="center"
              subtitle="Latest News"
              title="Our Latest News."
              description="We provide company and finance service for <br /> startups and company business."
              data-aos="fade-up"
            />
          </div>
        </div>
        <div class="row row--15">
          <div
            class="col-lg-4 col-md-6 col-sm-12 col-12 mt--30"
            data-aos="slide-up"
            data-aos-duration="800"
            :data-aos-delay="100 + index"
            v-for="(blog, index) in posts"
            :key="index"
            v-if="index < 3"
          >
            <BlogPost :blog="blog" />
          </div>
        </div>
      </div>
    </div>
    <!-- Start End Area -->

    <!-- Start Elements Area -->
    <div class="rwt-callto-action-area rn-section-gapBottom">
      <div class="wrapper">
        <CallToAction
          :style-type="5"
          title="Ready to start creating a standard website?"
          subtitle="Finest choice for your home & office"
          btn-text="Purchase Doob"
        />
      </div>
    </div>
    <!-- End Elements Area -->
  </Layout>
</template>

<script>
import Layout from '../../components/common/Layout'
import Icon from '../../components/icon/Icon'
import Separator from '../../components/elements/separator/Separator'
import SectionTitle from '../../components/elements/sectionTitle/SectionTitle'
import ServiceOne from '../../components/elements/service/ServiceOne'
import Portfolio from '../../components/elements/portfolio/Portfolio'
import Timeline from '../../components/elements/timeline/Timeline'
import Video from '../../components/elements/video/Video'
import Testimonial from '../../components/elements/testimonial/Testimonial'
import BlogPostMixin from '../../mixins/BlogPostMixin'
import BlogPost from '../../components/blog/BlogPost'
import CallToAction from '../../components/elements/callToAction/CallToAction'

export default {
  name: 'BusinessConsulting',
  components: {
    ServiceOne,
    CallToAction,
    BlogPost,
    Testimonial,
    Video,
    Timeline,
    Portfolio,
    SectionTitle,
    Separator,
    Icon,
    Layout
  },
  mixins: [BlogPostMixin],
  data() {
    return {
      timelineData: [
        {
          id: '1',
          date: 'Step-1',
          title: 'Your working process you can present.',
          description:
            'Lorem ipsum dolor sit amet at — consectetur adipisicing elit. Hic, expedita. Incidunt laboriosam.',
          image: 'timeline-01',
          workingStep: [
            {
              stepTitle: 'Gain your works',
              stepDescription: 'Lorem ipsum dolor sit amet at consectetur adipisicing'
            },
            {
              stepTitle: 'Working process',
              stepDescription: 'Lorem ipsum dolor sit amet at consectetur adipisicing'
            }
          ]
        },
        {
          id: '2',
          date: 'Step-2',
          title: 'Our compnay working process to present.',
          description:
            'Lorem ipsum dolor sit amet at — consectetur adipisicing elit. Hic, expedita. Incidunt laboriosam.',
          image: 'timeline-02',
          workingStep: [
            {
              stepTitle: 'Follow this process',
              stepDescription: 'Lorem ipsum dolor sit amet at consectetur adipisicing'
            },
            {
              stepTitle: 'Working experience',
              stepDescription: 'Lorem ipsum dolor sit amet at consectetur adipisicing'
            }
          ]
        }
      ],
      videoData: [
        {
          thumb: require(`@/assets/images/bg/bg-image-4.jpg`),
          src: 'https://www.youtube.com/watch?v=d0tU18Ybcvk',
          title: `Watch the creative process behind the digital marketing.`
        }
      ],
      testimonialData5: [
        {
          form: 'Poland',
          description:
            'What I am primarily looking for with new projects is a fit on both a visual and aesthetic level as well as on a personal level with the client.',
          name: 'John Due',
          subtitle: 'App Developer',
          image: 'testimonial-dark-01'
        },
        {
          form: 'Germany',
          description:
            'What I am primarily looking for with new projects is a fit on both a visual and aesthetic level as well as on a personal level with the client.',
          name: 'John Due',
          subtitle: 'App Developer',
          image: 'testimonial-dark-02'
        },
        {
          form: 'USA',
          description:
            'What I am primarily looking for with new projects is a fit on both a visual and aesthetic level as well as on a personal level with the client.',
          name: 'Janen',
          subtitle: 'App Developer',
          image: 'testimonial-dark-03'
        }
      ],
      portfolioData: [
        {
          id: 1,
          portfolioImage: require(`@/assets/images/portfolio/portfolio-01.jpg`),
          image: require(`@/assets/images/portfolio/portfolio-01.jpg`),
          title: 'App Development',
          subtitle: 'Branded client',
          date: '30 May 2021',
          client: 'Rainbow Themes',
          category: 'development',
          awards: ['2020 Design beautiful apps Finale Awards'],
          excerpt: 'Design beautiful apps.',
          body: `<p>In a typical Vue application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
          btn: {
            buttonText: 'View Projects',
            link: '/'
          },
          largeImage: require(`@/assets/images/portfolio/portfolio-01.jpg`),
          subContent: {
            title: 'Digital Marketing.',
            subtext:
              'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
          },
          subContent2: {
            title: 'App Development.',
            subtext:
              'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
          },
          subContent3: {
            title: 'Solution.',
            subtext:
              'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
          },
          gallery: {
            imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
            imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
            imageThree: require(`@/assets/images/portfolio/portfolio-03.jpg`)
          }
        },
        {
          id: 2,
          portfolioImage: require(`@/assets/images/portfolio/portfolio-02.jpg`),
          image: require(`@/assets/images/portfolio/portfolio-02.jpg`),
          title: 'Business Development',
          subtitle: 'Awesome portfolio',
          date: '01 March 2021',
          client: 'Rainbow Themes',
          category: 'design',
          awards: ['2020 Design beautiful apps Finale Awards'],
          excerpt: 'Design beautiful apps.',
          body: `<p>In a typical Vue application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
          btn: {
            buttonText: 'View Projects',
            link: '/'
          },
          largeImage: require(`@/assets/images/portfolio/portfolio-02.jpg`),
          subContent: {
            title: 'Digital Marketing.',
            subtext:
              'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
          },
          subContent2: {
            title: 'App Development.',
            subtext:
              'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
          },
          subContent3: {
            title: 'Solution.',
            subtext:
              'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
          },
          gallery: {
            imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
            imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
            imageThree: require(`@/assets/images/portfolio/portfolio-03.jpg`)
          }
        },
        {
          id: 3,
          portfolioImage: require(`@/assets/images/portfolio/portfolio-03.jpg`),
          image: require(`@/assets/images/portfolio/portfolio-03.jpg`),
          title: 'Photoshop Design',
          subtitle: 'Awesome portfolio',
          date: '01 March 2021',
          client: 'Rainbow Themes',
          category: 'art',
          awards: ['2020 Design beautiful apps Finale Awards'],
          excerpt: 'Design beautiful apps.',
          body: `<p>In a typical Vue application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
          btn: {
            buttonText: 'View Projects',
            link: '/'
          },
          largeImage: require(`@/assets/images/portfolio/portfolio-03.jpg`),
          subContent: {
            title: 'Digital Marketing.',
            subtext:
              'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
          },
          subContent2: {
            title: 'App Development.',
            subtext:
              'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
          },
          subContent3: {
            title: 'Solution.',
            subtext:
              'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
          },
          gallery: {
            imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
            imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
            imageThree: require(`@/assets/images/portfolio/portfolio-03.jpg`)
          }
        },
        {
          id: 4,
          portfolioImage: require(`@/assets/images/portfolio/portfolio-04.jpg`),
          image: require(`@/assets/images/portfolio/portfolio-04.jpg`),
          title: 'Native Application',
          subtitle: 'Awesome portfolio',
          date: '01 March 2021',
          client: 'Rainbow Themes',
          category: 'development',
          awards: ['2020 Design beautiful apps Finale Awards'],
          excerpt: 'Design beautiful apps.',
          body: `<p>In a typical Vue application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
          btn: {
            buttonText: 'View Projects',
            link: '/'
          },
          largeImage: require(`@/assets/images/portfolio/portfolio-04.jpg`),
          subContent: {
            title: 'Digital Marketing.',
            subtext:
              'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
          },
          subContent2: {
            title: 'App Development.',
            subtext:
              'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
          },
          subContent3: {
            title: 'Solution.',
            subtext:
              'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
          },
          gallery: {
            imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
            imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
            imageThree: require(`@/assets/images/portfolio/portfolio-04.jpg`)
          }
        },
        {
          id: 5,
          portfolioImage: require(`@/assets/images/portfolio/portfolio-05.jpg`),
          image: require(`@/assets/images/portfolio/portfolio-05.jpg`),
          title: 'Vue Development',
          subtitle: 'Awesome portfolio',
          date: '01 March 2021',
          client: 'Rainbow Themes',
          category: 'design',
          awards: ['2020 Design beautiful apps Finale Awards'],
          excerpt: 'Design beautiful apps.',
          body: `<p>In a typical Vue application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
          btn: {
            buttonText: 'View Projects',
            link: '/'
          },
          largeImage: require(`@/assets/images/portfolio/portfolio-05.jpg`),
          subContent: {
            title: 'Digital Marketing.',
            subtext:
              'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
          },
          subContent2: {
            title: 'App Development.',
            subtext:
              'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
          },
          subContent3: {
            title: 'Solution.',
            subtext:
              'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
          },
          gallery: {
            imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
            imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
            imageThree: require(`@/assets/images/portfolio/portfolio-03.jpg`)
          }
        },
        {
          id: 6,
          portfolioImage: require(`@/assets/images/portfolio/portfolio-06.jpg`),
          image: require(`@/assets/images/portfolio/portfolio-06.jpg`),
          title: 'App Installment',
          subtitle: 'Awesome portfolio',
          date: '01 March 2021',
          client: 'Rainbow Themes',
          category: 'design',
          awards: ['2020 Design beautiful apps Finale Awards'],
          excerpt: 'Design beautiful apps.',
          body: `<p>In a typical Vue application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
          btn: {
            buttonText: 'View Projects',
            link: '/'
          },
          largeImage: require(`@/assets/images/portfolio/portfolio-06.jpg`),
          subContent: {
            title: 'Digital Marketing.',
            subtext:
              'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
          },
          subContent2: {
            title: 'App Development.',
            subtext:
              'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
          },
          subContent3: {
            title: 'Solution.',
            subtext:
              'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
          },
          gallery: {
            imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
            imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
            imageThree: require(`@/assets/images/portfolio/portfolio-06.jpg`)
          }
        }
      ]
    }
  }
}
</script>
