<template>
  <div
    class="rn-back-top shadowed-border-2"
    @click="scrollToTop"
    :class="{ 'shadowed-border-dark': !userLogin, 'shadowed-border-light-1': userLogin }"
  >
    <!-- :class="[visible ? 'd-inline' : 'd-none']" -->
    <Icon name="chevron-up" size="27" />
  </div>
</template>

<script>
import Icon from '../../icon/Icon'

export default {
  name: 'ScrollTop',
  components: { Icon },
  data() {
    return {
      visible: false
    }
  },
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    },
    toggleVisible() {
      const scrolled = document.documentElement.scrollTop
      if (scrolled > 300) {
        this.visible = true
      } else if (scrolled <= 300) {
        this.visible = false
      }
    }
  },
  computed: {
    userLogin() {
      return this.$store.state.userLogin
    }
  },
  created() {
    window.addEventListener('scroll', this.toggleVisible)
  },
  mounted() {
    this.toggleVisible()
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.toggleVisible)
  }
}
</script>
<style>
.gradient-border2 {
  border-image-slice: 1;
  /* border: 1px solid rgb(93, 28, 123); */
  border-radius: 50px 20px;
}

.shadowed-border-dark::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* สีขอบสำหรับเงาขาว */
  border-radius: 24px; /* ความโค้งขอบสำหรับเงาขาว */
  box-shadow: 0px 0px 10px 5px rgb(74, 30, 93);
}
.shadowed-border-light-1::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* สีขอบสำหรับเงาขาว */
  border-radius: 24px; /* ความโค้งขอบสำหรับเงาขาว */
  box-shadow: 0px 0px 10px 5px rgb(211, 202, 202);
}
</style>
