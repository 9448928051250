<template>
  <Layout header-class="header-left-align " class="" nav-alignment="left">
    <div class="path-layout text-center">
      <img src="../../assets/images/Newlandindpage/Path_66842.svg" alt="" />
    </div>

    <!-- #section1 -->
    <div class="slider-area slider-style-1 height-850 bg_image" data-black-overlay="7" :style="{
      'background-image': `url(${require(`../../assets/images/Newlandindpage/lightmode/Rectangle_9944@2x.png`)})`
    }">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="inner pt--80 text-center">
              <div>
                <h3 class="rn-sub-badge" data-aos="fade-up" data-aos-delay="100">
                  <span class="theme-gradient">Lorrem ipsum {{ hasNewClass }}</span>
                  <!-- <p>{{ $t('hello') }}</p>
                  <p>{{ $t('welcome') }}</p> -->
                </h3>
              </div>
              <h1 class="title display-one" data-aos="fade-up" data-aos-delay="150">
                Trip planner <br />
                Lorrem ipsum<br />
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- #section2 -->
    <div class="container bg-darken-lg text-white mw-100">
      <div class="service-area mb-5 pb-5 rn-section-gapTop">
        <div class="container">
          <div class="row">
            <div class="col-lg-6">
              <div class="content" data-aos="fade-up">
                <h3 class="title">
                  We are creative digital agency working for our company brands.
                </h3>
              </div>
            </div>
            <div class="col-lg-6">
              <p class="mb--10" data-aos="fade-up">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod, quis. Ullam
                accusantium dignissimos repellendus nemo fugiat numquam, nisi odio adipisci. Veniam
                neque itaque expedita officiis rem ipsa! Ratione, rem reiciendis?
              </p>
              <div class="button-group" data-aos="fade-up" data-aos-delay="250">
                <router-link class="btn-default btn-icon" to="#"> Free trial </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Separator />
    <!-- #section3 -->

    <div class="rn-service-area rn-section-gap" :class="{
      'background-triplaner-dark': userLogin === false,
      'background-triplaner-light': userLogin === true
    }">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <p class="text-center Noto-medium">เติมเต็มประสบการณ์ใหม่ไปพร้อมๆกัน</p>
            <SectionTitle2 text-align="center" title="ชีวิตง่ายขึ้นด้วย Trip planner"
              description="Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsumLorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsumLorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum"
              data-aos="fade-up" class="Noto-medium" />
          </div>
        </div>
        <ServiceOnetrip service-style="service__style--1 icon-circle-style" text-align="center" icon-size="39" />
      </div>
      <!-- <div class="read-more-btn text-center mt--40" data-aos="fade-up" data-aos-delay="370">
        <router-link class="btn-default" to="/about">
                    <span>Read more</span>
                </router-link>
      </div> -->
    </div>
    <!-- #section4 -->
    <AboutFour :image="require(`../../assets/images/Newlandindpage/lightmode/certificate.png`)" />
  </Layout>
</template>

<script>
import Layout from '../../components/common/Layout'
import Separator from '../../components/elements/separator/Separator'
import Icon from '../../components/icon/Icon'
import SectionTitle from '../../components/elements/sectionTitle/SectionTitle'
import ServiceFive from '../../components/elements/service/ServiceFive'
import Team from '../../components/elements/team/Team'
import BlogPost from '../../components/blog/BlogPost'
import BlogPostMixin from '../../mixins/BlogPostMixin'
import CallToAction from '../../components/elements/callToAction/CallToAction'
import Testimonial from '../../components/elements/testimonial/Testimonial'
import Accordion from '../../components/elements/accordion/Accordion'
import Split from '../../components/elements/split/Split'
import ServiceOne from '../../components/elements/service/ServiceOne'
import ServiceOnetrip from '../../components/elements/service/ServiceOnetrip.vue'
import AboutFour from '../../components/elements/about/AboutFour'
import AboutSection9 from '../../components/elements/about/AboutSection9.vue'
import Brand from '../../components/elements/brand/Brand'
import Timeline from '../../components/elements/timeline/Timeline'
import SectionTitle2 from '../../components/elements/sectionTitle/SectionTitle2.vue'
import i18n from '../../../i18n/i18n'

import '@/assets/global.css'
import { onMounted } from 'vue'

export default {
  name: 'BusinessConsulting2',
  i18n,
  components: {
    Accordion,
    AboutSection9,
    Timeline,
    Brand,
    ServiceOne,
    Testimonial,
    CallToAction,
    BlogPost,
    Team,
    Split,
    ServiceFive,
    SectionTitle,
    Icon,
    Separator,
    AboutFour,
    Layout,
    SectionTitle2,
    ServiceOnetrip
  },
  mixins: [BlogPostMixin],
  data() {
    return {
      hasNewClass: false,
      teamData: [
        {
          image: 'team-01',
          name: 'Sr Janen Sara',
          designation: 'Sr Product Designer',
          location: 'CO Miego, AD, USA',
          description: 'Yes, I am a product designer. I have a passion for product design.',
          socialNetwork: [
            {
              icon: 'facebook',
              url: '#'
            },
            {
              icon: 'twitter',
              url: '#'
            },
            {
              icon: 'instagram',
              url: '#'
            }
          ]
        },
        {
          image: 'team-02',
          name: 'Afsana Nila',
          designation: 'App Developer',
          location: 'Bangladesh',
          description: 'Yes, I am a product designer. I have a passion for product design.',
          socialNetwork: [
            {
              icon: 'facebook',
              url: '#'
            },
            {
              icon: 'twitter',
              url: '#'
            },
            {
              icon: 'instagram',
              url: '#'
            }
          ]
        },
        {
          image: 'team-03',
          name: 'Afanan Sifa',
          designation: 'Accounts Manager',
          location: 'Poland',
          description: 'Yes, I am a product designer. I have a passion for product design.',
          socialNetwork: [
            {
              icon: 'facebook',
              url: '#'
            },
            {
              icon: 'twitter',
              url: '#'
            },
            {
              icon: 'instagram',
              url: '#'
            }
          ]
        }
      ],
      testimonialData5: [
        {
          form: 'Poland',
          description:
            'What I am primarily looking for with new projects is a fit on both a visual and aesthetic level as well as on a personal level with the client.',
          name: 'John Due',
          subtitle: 'App Developer',
          image: 'testimonial-dark-01'
        },
        {
          form: 'Germany',
          description:
            'What I am primarily looking for with new projects is a fit on both a visual and aesthetic level as well as on a personal level with the client.',
          name: 'John Due',
          subtitle: 'App Developer',
          image: 'testimonial-dark-02'
        },
        {
          form: 'USA',
          description:
            'What I am primarily looking for with new projects is a fit on both a visual and aesthetic level as well as on a personal level with the client.',
          name: 'Janen',
          subtitle: 'App Developer',
          image: 'testimonial-dark-03'
        }
      ],
      splitOneData: {
        image: 'Group_27921.png',
        title: 'Software/Platform Development',
        description:
          'We offer comprehensive software development services tailored to your needs. From concept to deployment, our team creates cutting-edge solutions that drive efficiency, enhance user experiences, and bring your ideas to life. Whether it`s web, mobile, or custom applications, We are committed to delivering top-notch quality and innovation that transforms your vision into reality.',
        list: [],
        btn: {
          text: '!/',
          link: '/contact'
        }
      },
      splitTwoData: {
        image: 'Group_27919.png',
        title: 'Professional Consultancy on Smart Grids ',
        description: `As a leading expert in smart grids technology, our consultancy service provides invaluable insights and guidance for navigating the complex landscape of modern energy distribution. With a deep understanding of grid optimization, renewable integration, and advanced monitoring systems, we offer strategic solutions that empower utilities and organizations to achieve enhanced efficiency, sustainability, and reliability. Together, we pave the way for a brighter energy future.`
        // counterData: [
        //     {
        //         number: 199,
        //         title: 'Happy Clients',
        //     },
        //     {
        //         number: 575,
        //         title: 'Employees',
        //     },
        //     {
        //         number: 69,
        //         title: 'Useful Programs',
        //     },
        //     {
        //         number: 500,
        //         title: 'Useful Programs',
        //     }
        // ]
      },
      splitthreeData: {
        image: 'Group_27920.png',
        title: 'EV Roaming API Integration',
        description:
          'We are a recognized authority in EV roaming integration with official patented products. Our expertise lies in seamlessly connecting electric vehicle charging networks, ensuring smooth and convenient experiences for EV users wherever they go. Through our API integration service, we facilitate interoperability, streamline operations, and contribute to the widespread adoption of electric vehicles, paving the way for a greener transportation future.',
        list: [],
        btn: {
          text: '!/',
          link: '/contact'
        }
      },
      splitTfourData: {
        image: 'Group_27921.png',
        title: 'Domestic and International Clearing House',
        description: `
       As a seasoned professional in this distinct field, we excel at facilitating transparent transactions and seamless communication between diverse electric vehicle charging networks. Our expertise ensures efficient billing, settlement, and data exchange, contributing significantly to the growth and accessibility of electric mobility on a global scale.`
        // counterData: [
        //     {
        //         number: 199,
        //         title: 'Happy Clients',
        //     },
        //     {
        //         number: 575,
        //         title: 'Employees',
        //     },
        //     {
        //         number: 69,
        //         title: 'Useful Programs',
        //     },
        //     {
        //         number: 500,
        //         title: 'Useful Programs',
        //     }
        // ]
      },
      splitsection5Data: {
        image: 'Group_27923.svg',
        title: 'Covering in ASEAN countries and beyond',
        description: `Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsumLorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsumLorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsumLorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum`,
        counterData1: [
          {
            number: 406,
            title: ' EA ANYWHERE'
          },
          {
            number: 252,
            title: 'EV Station PluZ'
          }
        ],
        counterData2: [
          {
            number: 199,
            title: 'EVOLT'
          },
          {
            number: 184,
            title: 'SHARGE'
          }
        ]
      },
      brandList: [
        {
          image: require(`@/assets/images/Newlandindpage/Group_2888.svg`)
        },
        {
          image: require(`@/assets/images/Newlandindpage/Group_26970.svg`)
        },
        {
          image: require(`@/assets/images/Newlandindpage/Group_26974.svg`)
        },
        {
          image: require(`@/assets/images/Newlandindpage/Group_26978.svg`)
        },
        {
          image: require(`@/assets/images/Newlandindpage/Group_26986.svg`)
        },
        {
          image: require(`@/assets/images/Newlandindpage/Group_26993.svg`)
        },
        {
          image: require(`@/assets/images/Newlandindpage/Group_26999.svg`)
        },
        {
          image: require(`@/assets/images/Newlandindpage/MaskGroup 2881.svg`)
        },
        {
          image: require(`@/assets/images/Newlandindpage/MaskGroup 2880.svg`)
        },
        {
          image: require(`@/assets/images/Newlandindpage/MaskGroup 2883.svg`)
        },
        {
          image: require(`@/assets/images/Newlandindpage/MaskGroup 2886.svg`)
        },
        {
          image: require(`@/assets/images/Newlandindpage/MaskGroup 2887.svg`)
        }
      ],
      timelineData: [
        {
          id: '1',
          date: 'Step-1',
          title: 'Lorem ipsum',
          description:
            'Lorem ipsumLorem ipsumLorem ipsumLorem ipsumLorem ipsumLorem ipsumLorem ipsumLorem ipsumLorem ipsum',
          image: 'tobias-reich-sC5ZgFyGB4Y-unsplash.png',
          workingStep: [
            {
              stepTitle: 'Lorem ipsum',
              stepDescription: 'Lorem ipsumLorem ipsumLorem ipsumLorem ipsumLorem ipsum'
            },
            {
              stepTitle: 'Lorem ipsum',
              stepDescription: 'Lorem ipsumLorem ipsumLorem ipsumLorem ipsumLorem ipsum'
            }
          ]
        },
        {
          id: '2',
          date: 'Step-2',
          title: 'Our compnay working process to present.',
          description:
            'Lorem ipsum dolor sit amet at — consectetur adipisicing elit. Hic, expedita. Incidunt laboriosam.',
          image: 'milad-fakurian-PjG_SXDkpwQ-unsplash.png',
          workingStep: [
            {
              stepTitle: 'Follow this process',
              stepDescription: 'Lorem ipsum dolor sit amet at consectetur adipisicing'
            },
            {
              stepTitle: 'Working experience',
              stepDescription: 'Lorem ipsum dolor sit amet at consectetur adipisicing'
            }
          ]
        }
      ]
    }
  },
  computed: {
    userLogin() {
      return this.$store.state.userLogin
    }
  },

  methods: {}
}
</script>
<style>
.path-layout {
  transform: translate(209px, -14px);
  position: absolute;
  width: 74vw;
}

.path-layout-1 {
  transform: translate(0px, 55px);
}

.sizesection7 {
  height: 100vh;
  width: 100%;
}

.section7-layout {
  margin-top: 25%;
}

.background-sec-2 {
  background-image: url(../../assets/images/Newlandindpage/Rectangle\ 15237.svg);
  background-size: cover;
  background-position: -0% 0%;
  background-repeat: no-repeat;
  /* background-position: right; */
  z-index: 2;
  width: 100%;
  height: 100%;
}

.background-triplaner-dark {
  background-image: url(../../assets/images/Newlandindpage/Rectangle\ 10301.svg);
  background-size: cover;
  background-position: -0% 70%;
  background-repeat: no-repeat;
  /* background-position: right; */
  z-index: 2;
  width: 100%;
  height: 100%;
}

.background-triplaner-light {
  background-image: url(../../assets/images/Newlandindpage/lightmode/Group_127997@2x.png);
  background-size: cover;
  background-position: -0% 40%;
  background-repeat: no-repeat;
  /* background-position: right; */
  z-index: 2;
  width: 100%;
  height: 100%;
}

.background-sec-4 {
  background-image: url(../../assets/images/Newlandindpage/Rectangle\ 10301.svg);
  background-size: cover;
  background-position: -0% 50%;
  background-repeat: no-repeat;
  /* background-position: right; */
  z-index: 2;
  width: 100%;
  height: 100%;
}

.background-sec-10 {
  background-image: url(../../assets/images/Newlandindpage/Rectangle\ 10119.svg);
  background-size: cover;
  background-position: -0% 50%;
  background-repeat: no-repeat;
  /* background-position: right; */
  z-index: 2;
  width: 100%;
  height: 100%;
}

.position-lay {
  transform: translate(90%, -2948%);
  position: fixed;
  bottom: 10%;
  right: 0%;
  z-index: 9999;
  width: 100%;
}
</style>
