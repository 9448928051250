<template>
  <div class="about-area about-style-4 rn-section-gap">
    <div class="container">
      <div class="row row--40 align-items-center">
        <div class="col-lg-5">
          <Video :video-data="videoData" column="col-12" />
        </div>
        <div class="col-lg-1"></div>
        <div class="col-lg-6 mt_md--40 mt_sm--40">
          <div class="content">
            <div class="inner">
              <h3 class="text-center LexenSemiBold">Vision</h3>
              <p :class="{ 'text-white ': !userLogin, 'text-black': userLogin }">
                Lorem ipsum dolor consectetur adipiscing Lorem ipsum dolor consectetur
                adipiscingLorem ipsum dolor consectetur adipiscing Lorem ipsum Lorem ipsum Lorem
                ipsum Lorem ipsum Lorem ipsum
              </p>
              <img v-if="userLogin === true" src="../../../assets/images/Newlandindpage/lightmode/line_aboute.png"
                alt="" />
              <h3 class="LexenSemiBold text-center">Mission</h3>
              <p :class="{ 'text-white ': !userLogin, 'text-black': userLogin }">
                Lorem ipsum dolor consectetur adipiscing Lorem ipsum dolor consectetur
                adipiscingLorem ipsum dolor consectetur adipiscing Lorem ipsum Lorem ipsum Lorem
                ipsum Lorem ipsum Lorem ipsum
              </p>

              <!-- <div class="about-btn mt--30">
                <router-link class="btn-default mx-5" to="#">About Our Doob</router-link>
                <router-link class="btn-default btn-border btn-icon" to="#">
                  Check in
                  <Icon name="arrow-right" size="20" icon-class="icon" />
                </router-link>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '../../icon/Icon'
import Video from '../video/Video'

export default {
  name: 'About',
  components: { Video, Icon },
  props: {
    image: {}
  },
  data() {
    return {
      videoData: [
        {
          thumb: this.image,
          src: 'https://www.youtube.com/watch?v=d0tU18Ybcvk'
        }
      ]
    }
  },
  computed: {
    userLogin() {
      return this.$store.state.userLogin
    }
  }
}
</script>
<style>
.protact-logo {
  margin-top: -14%;
}
</style>
