<template>
  <div
    class="rn-card box-card-style-default"
    :class="{ 'card-list-view': blogPostStyle === 'list' }"
  >
    <div class="inner">
      <div class="thumbnail">
        <!-- <router-link class="image" :to="`/blog-details/${blog.id}`"> -->
        <router-link class="image" :to="`/blog-details/${blog.id}`">
          <img class="" :src="blog.img" :alt="blog.title" />
        </router-link>
      </div>
      <div class="content">
        <ul v-if="blogPostStyle === 'card'" class="rn-meta-list">
          <li>
            <router-link :to="''">
              {{ blog.author }}
            </router-link>
          </li>
          <li class="separator"></li>
          <li>{{ blog.publishedAt }}</li>
        </ul>
        <h4 class="title">
          <router-link :to="`/blog-details/${blog.id}`">
            {{ blog.title }}
          </router-link>
        </h4>
        <ul v-if="blogPostStyle === 'list'" class="rn-meta-list">
          <li>
            <router-link :to="''">
              {{ blog.author }}
            </router-link>
          </li>
          <!-- <li class="separator">/</li> -->
          <li class="Noto-bold">{{ blog.publishedAt }}</li>
        </ul>
        <p v-if="blogPostStyle === 'list'" class="descriptiion">
          {{ blog.excerpt }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BlogPost',
  props: {
    blog: {},
    blogPostStyle: {
      type: String,
      default: 'card'
    }
  }
}
</script>
<style>
.background-bg-post1 {
  background-image: url(../../assets/images/Newlandindpage/lightmode/Group_27997@2x.png);
  background-size: cover;
  background-position: -0% 0%;
  /* background-repeat: no-repeat; */
  background-position: center;
  z-index: 2;
  width: 100%;
  height: 100%;
}
</style>
