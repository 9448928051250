<template>
  <Layout header-class="header-left-align " nav-alignment="left">
    <!-- Start Contact Area  -->
    <div
      class="rwt-contact-area rn-section-gap"
      :class="{ '': userLogin === false, 'background-contact': userLogin === true }"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-12 mb--40">
            <SectionTitle
              text-align="center"
              subtitle="Contact Form"
              title="Our Contact Address Here"
            />
          </div>
        </div>
        <div class="row row--15">
          <div class="col-lg-12">
            <div class="rn-contact-address mt_dec--30">
              <div class="row">
                <div class="col-lg-4 col-md-6 col-12">
                  <div class="rn-address" style="height: 90%">
                    <div class="icon">
                      <Icon name="headphones" :size="40" />
                    </div>
                    <div class="inner">
                      <h4 class="LexenBold">Contact Phone Number</h4>
                      <p class="LexenSemiBold">
                        <a href="tel:+444 555 666 777">Tel: Tel: +66 2602 9518</a>
                      </p>
                      <p class="LexenSemiBold"><a href="tel:+222 222 222 333">+66 8377 78822</a></p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 col-12">
                  <div class="rn-address" style="height: 90%">
                    <div class="icon">
                      <Icon name="mail" :size="40" />
                    </div>
                    <div class="inner">
                      <h4 class="LexenBold">Our Email Address</h4>
                      <p class="LexenSemiBold">
                        <a href="mailto:admin@gmail.com">gridwhiz@gmail.com</a>
                      </p>
                      <!-- <p>
                        <a href="mailto:example@gmail.com">example@gmail.com</a>
                      </p> -->
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 col-12">
                  <div class="rn-address" style="height: 90%">
                    <div class="icon">
                      <Icon name="map-pin" :size="40" />
                    </div>
                    <div class="inner">
                      <h4 class="LexenBold">Our Location</h4>
                      <p class="LexenSemiBold">
                        184/185 Forum Tower 28th FL. Rachadapisek Rd. Huaikhwang, Bangkok 10310
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt--40">
          <div class="col-lg-7">
            <ContactForm />
          </div>
          <div class="col-lg-5 mt_md--30 mt_sm--30">
            <GoogleMap />
          </div>
        </div>
      </div>
    </div>
    <!-- End Contact Area  -->
  </Layout>
</template>

<script>
import Layout from '../components/common/Layout'
import Breadcrumb from '../components/elements/breadcrumb/Breadcrumb'
import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
import Icon from '../components/icon/Icon'
import GoogleMap from '../components/elements/contact/GoogleMap'
import ContactForm from '../components/elements/contact/ContactForm'

export default {
  name: 'ContactPage',
  components: {
    ContactForm,
    GoogleMap,
    Icon,
    SectionTitle,
    Breadcrumb,
    Layout
  },
  computed: {
    userLogin() {
      return this.$store.state.userLogin
    }
  }
}
</script>
<style>
.background-contact {
  background-image: url(../assets/images/Newlandindpage/lightmode/bg-contact.png);
  background-size: cover;
  background-position: -0% 0%;
  /* background-repeat: no-repeat; */
  background-position: center;
  z-index: 2;
  width: 100%;
  height: 100%;
}
</style>
