<template>
  <Layout header-class="header-left-align" @hookdata="datatest" nav-alignment="left">
    <Separator />
    <div class="">
      <div
        class="rn-service-area rn-section-gap"
        :class="{
          '': userLogin === false,
          ' background-pros-lightmode': userLogin === true
        }"
      >
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <SectionTitle
                text-align="center"
                subtitle="Let's summarize what the advantages are"
                style="text-align: "
                title="Pros & Cons Comparison"
                description=""
                data-aos="fade-up"
              />
              <div data-aos="fade-up">
                <ul>
                  <li>
                    P2P connections are direct communication links between devices without
                    intermediaries, promoting decentralization and often enhancing resilience.
                  </li>
                  <li>
                    Centralized hub connections involve a central point of control that manages and
                    facilitates communication between devices, offering efficient management and
                    control over data flow.
                  </li>
                  <li>
                    Both P2P and centralized hub connections have their advantages and use cases,
                    and the choice between them depends on factors like network architecture,
                    scalability, security requirements, and the specific goals of the IT
                    infrastructure being developed.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div>
            <serviceimplementation
              service-style="service__style--1 icon-circle-style"
              text-align="center"
              icon-size="39"
            />
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from '../../components/common/Layout'
import Icon from '../../components/icon/Icon'
import SectionTitle from '../../components/elements/sectionTitle/SectionTitle'
import ServiceThree from '../../components/elements/service/ServiceThree'
import Separator from '../../components/elements/separator/Separator'
import Counter from '../../components/elements/counterUp/Counter'
import Team from '../../components/elements/team/Team'
import Pricing from '../../components/elements/pricing/Pricing'
import CallToAction from '../../components/elements/callToAction/CallToAction'
import AboutTwo from '../../components/elements/about/AboutTwo'
import ServiceTwo from '../../components/elements/service/ServiceTwo'
import serviceimplementation from '../../components/elements/service/serviceimplementation'

export default {
  name: 'Corporate',
  components: {
    ServiceTwo,
    AboutTwo,
    CallToAction,
    Pricing,
    Team,
    Counter,
    Separator,
    ServiceThree,
    SectionTitle,
    Icon,
    Layout,
    serviceimplementation
  },
  props: ['data'],
  data() {
    return {
      pricingData: [
        {
          title: 'Free',
          currency: '',
          price: '0',
          subtitle: 'USD Per Month',
          features: [
            { title: ' Lorem ipsum' },
            { title: ' Lorem ipsum' },
            { title: ' Lorem ipsum' },
            { title: ' Lorem ipsum' },
            { title: ' Lorem ipsum' }
          ],
          btn: {
            text: 'Buy Now',
            link: '#'
          }
        },
        {
          title: ' Lorem ipsum',
          currency: '',
          price: '1,143.83',
          subtitle: 'USD Per Month',
          features: [
            { title: ' 7-day sandbox access' },
            { title: ' No technical support' },
            { title: 'Lorem ipsum' },
            { title: 'Lorem ipsum' },
            { title: 'Lorem ipsum' }
          ],
          btn: {
            text: 'Buy Now',
            link: '#'
          },
          isActive: true
        },
        {
          title: 'Lorem ipsum',
          currency: '',
          price: '0',
          subtitle: 'USD Per Month',
          features: [
            { title: 'Lorem ipsum' },
            { title: 'Lorem ipsum' },
            { title: 'Lorem ipsum' },
            { title: 'Lorem ipsum' },
            { title: 'Lorem ipsum' }
          ],
          btn: {
            text: 'Buy Now',
            link: '#'
          }
        }
      ]
    }
  },
  methods: {},
  computed: {
    userLogin() {
      return this.$store.state.userLogin
    }
  }
}
</script>
<style>
.background-pros-lightmode {
  background-image: url(../../assets/images/Newlandindpage/lightmode/Group_27997@2x.png);
  background-size: cover;
  background-position: 15% 0%;
  /* background-repeat: no-repeat; */
  z-index: 10;
  width: 100%;
  height: 10%;
}

.background-impenmentation-light {
  background-image: url(../../assets/images/Newlandindpage/lightmode/Group_27997@2x.png);
  background-size: cover;
  background-position: -0% 0%;
  /* background-repeat: no-repeat; */
  background-position: center;
  position: absolute;
  background-attachment: fixed;
  z-index: 1;
  width: 100%;
  height: auto;
}
</style>
