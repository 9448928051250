<template>
  <div v-if="sectionTitleStyle === 1" class="section-title" :class="checkTextAlign">
    <h4 class="subtitle w-40">
      <span class="bggradiant" v-text="subtitle" />
    </h4>
    <h2 class="title w-600 LexenSemiBold" style="font-size: 40px" v-text="title" />
    <div v-if="hr === 'yes'" class="hrrtitle mb--50 mt--60"></div>
    <p v-if="description" class="description b1 LexenExtraLight" v-text="description" />
  </div>

  <div v-else-if="sectionTitleStyle === 2" :class="`section-title-2 ${checkTextAlign}`">
    <h2 class="title">{{ title }}</h2>
  </div>
</template>

<script>
export default {
  name: 'SectionTitle',
  props: {
    subtitle: {
      type: String,
      default: null
    },
    hr: {
      type: String,
      default: 'no'
    },
    title: {
      type: String,
      default: null
    },
    description: {
      type: String,
      default: null
    },
    textAlign: {
      type: String,
      default: 'left'
    },
    sectionTitleStyle: {
      type: Number,
      default: 1
    }
  },
  computed: {
    checkTextAlign() {
      if (this.textAlign === 'left') return 'text-start'
      else if (this.textAlign === 'center') return 'text-center'
      else if (this.textAlign === 'end') return 'text-end'
      else return ''
    }
  }
}
</script>
<style>
.hrrtitle {
  top: 8648px;
  left: 300px;
  width: 420px;
  height: 0px;
  /* UI Properties */
  border: 1px solid var(--unnamed-color-707070);
  border: 1px solid #707070;
  opacity: 0.41;
  margin-top: 5%;
}

.bggradiant {
  background: linear-gradient(to right, #9949d4, #ff4454);
  background-clip: text;
  /* ให้เท็กซ์ไปคลิปตามพื้นหลัง */
  color: transparent;
  /* ทำให้เท็กซ์สีขาวโปร่งแสง */
}
</style>
