<template>
  <form class="contact-form-1" action="" @submit="sendEmail">
    <div class="form-group">
      <input type="text" v-model="dataname" name="fullname" placeholder="Your Name" required />
    </div>

    <div class="form-group">
      <input type="email" v-model="dataemail" name="email" placeholder="Email Address" required />
    </div>

    <div class="form-group">
      <input type="text" v-model="dataphone" name="phone" placeholder="Phone Number" required />
    </div>

    <div class="form-group">
      <input type="text" v-model="datasubject" name="subject" placeholder="Subject" required />
    </div>

    <div class="form-group">
      <textarea name="message" v-model="datamessage" placeholder="Your Message" required>
      </textarea>
    </div>

    <div class="form-group">
      <button @click="senddata" class="btn-default btn-large">Submit Now</button>
    </div>
    <!-- <button @click="showResult = !showResult">แสดงผล</button> -->

    <div class="form-group" v-text="showResult ? result : null" />
  </form>
</template>

<script>
export default {
  name: 'ContactForm',
  data() {
    return {
      dataname: '',
      dataemail: '',
      dataphone: '',
      datasubject: '',
      datamessage: '',
      showResult: false,
      result: `<p class="text-danger">**Your Message has been successfully sent. I will contact you soon.</p>`
    }
  },
  methods: {
    sendEmail() {
      this.showResult = true
      console.log(this.showResult)
    },
    senddata() {
      const requiredFields = [
        this.dataname,
        this.dataemail,
        this.dataphone,
        this.datasubject,
        this.datamessage
      ]

      if (requiredFields.every((field) => !!field)) {
        const binddata = `Name: ${this.dataname}, Email: ${this.dataemail}, Phone: ${this.dataphone}, Subject: ${this.datasubject}, Message: ${this.datamessage}`
        console.log('ค่าข้อมูล input:', binddata)

        // ส่งข้อมูลไปยัง backend หรือทำสิ่งอื่นตามที่คุณต้องการ
        // ...
      } else {
        // alert('กรุณากรอกข้อมูลที่จำเป็นให้ครบถ้วน')
        this.showResult = true
      }
    }
  }
}
</script>
